import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Modal,
    Form,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Divider,
    Input, Button, Icon,
    Select, Row, Col
} from 'antd'
import Request, {API_URL} from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'

import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'
import ScheduleForm from '../../../components/schedulePickup'
import DhlTrackingDetails from '../../../components/Tracking/dhlTrackingDetails'
import AramexTrackingDetails from '../../../components/Tracking/aramexTrackingDetails'
import UpsTrackingDetails from '../../../components/Tracking/upsTrackingDetails'
import {hideLoader, showLoader} from "../../../modules/actions";

const {Option} = Select

@Form.create()
class PendingShipment extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            selectedRowKeys: [],
            visible: false,
            processVisible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver'],
            cancelLoader: []

        }


    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params['quoteStatus'] = ['Payment Pending', 'Payment Added']
            if (this.state.userId) {
                params.userId = this.state.userId
            }
            if (this.state.affiliateUserId) {
                params.affiliatedUser = this.state.affiliateUserId
            }
            let data = await Request.getAllQuote({
                ...params, regExFilters: ['receiverData.fullName', 'senderData.fullName']

            })

            
            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = (resp) => {
        this.setState({
            shipmentResponse: resp,
            visible: true
        })
    }
    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let {_id, netAmount, senderData, pickupLocation} = this.state.drawerData
                this.setState({paymentDetailsLoader: true})
                let x = null
                let payload = {
                    _id,
                    paymentResponse: {
                        ...values,
                        "ReturnOid": _id,
                        "BillToCompany": senderData.fullName,
                        "amount": netAmount,
                        "BillToName": senderData.fullName,
                        "email": senderData.email,
                        "tel": senderData.phone,
                        "BillToPostalCode": pickupLocation.zipCode,
                        "BillToCountry": pickupLocation.countryISO,
                        "BillToStateProv": pickupLocation.countryISO,
                        "oid": _id,
                        Response: 'Approved'

                    },
                    paymentNotes: values.notes,
                    paymentMode: values.paymentType,
                    paymentDate: moment(values.paymentDate).format(),
                    paidAmount: netAmount,
                    paymentTransId: values.TransId,
                    quoteStatus: 'Payment Added'
                }

                x = await Request.updateQuote(payload, _id)

                this.setState({paymentDetailsLoader: false})


                if (!x.error) {

                    notification.success({
                        message: 'Shipment Processed Successfully'
                    })

                    this.props.form.resetFields()
                    this.setState({
                        processVisible: false
                    })
                    this.table.current.reload()
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }
            }
        })
    }
    processShipmentFxn = async valData => {
        const {dispatch, form} = this.props
        let {_id, netAmount, senderData, pickupLocation} = valData
        dispatch(showLoader())
        let x = null
        let values = {
            "ReturnOid": _id,
            "BillToCompany": senderData.fullName,
            "amount": netAmount,
            "BillToName": senderData.fullName,
            "email": senderData.email,
            "tel": senderData.phone,
            "BillToPostalCode": pickupLocation.zipCode,
            "BillToCountry": pickupLocation.countryISO,
            "BillToStateProv": pickupLocation.countryISO,
            "oid": _id,
        }
        this.setState({processButtonLoading: true})
        x = await Request.processShipment(values)
        this.setState({processButtonLoading: false})

       
        if (!x.error) {

            notification.success({
                message: x.message
            })

            this.props.form.resetFields()

            this.table.current.reload()
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.message
            })
        }
    }

    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({})
        let {data: allAffiliateUsers} = await Request.loadUser({userType:'affiliate'})
        this.setState({allUsers,allAffiliateUsers})
    }


    cancelShipmentFxn = async (data, key) => {
        let {_id, shipmentSanitizeResponse} = data
        let payload = {
            _id,
        }

        this.setLoaderForCancel(key, true)

        let x = await Request.voidShipment(payload)
        this.setLoaderForCancel(key, false)

        if (!x.error) {

            notification.success({
                message: 'Shipment Canceled Successfully'
            })

            this.table.current.reload()
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.msg
            })
        }
    }
    setLoaderForCancel = (key, value) => {
        let {cancelLoader} = this.state
        let newLoading = _.clone(cancelLoader)
        newLoading[key] = value
        this.setState({cancelLoader: newLoading})
    }

    processShowDrawer = (data) => {
        this.setState({
            processVisible: true,
            drawerData: data
        }, () => {
            if (data.paymentMode) {
                this.props.form.setFieldsValue({
                    paymentType: data.paymentMode,
                    paymentDate: data.paymentDate ? moment(data.paymentDate) : '',
                    notes: data.paymentNotes,
                    TransId: data.paymentTransId,
                })
            }
        });
    };
    onClose = () => {
        this.setState({
            processVisible: false,
        });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };


    render() {
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            allAffiliateUsers, packageType, shipmentResponse, showTrackingModal, quoteDetails, drawerData, processButtonLoading, paymentDetailsLoader,
            packages, shipmentType, data, typeList, selectedRowKeys, serviceProviderName, showModal, allUsers
        } = this.state
        const inputTypes = {
            fields: [
                {
                    label: 'Payment Mode',
                    key: 'paymentType',
                    required: true,
                    type: 'select',
                    options: ['Virement / Versement Bancaire', 'Wafacash', 'Chèque', "Western Union", "Espèces"],
                    onChange: v => {
                        this.props.form.setFieldsValue({paymentType: v})
                    }
                },
                {
                    label: 'Payment Date',
                    key: 'paymentDate',
                    type: 'date',
                    required: true,
                    customProps: {
                        disabledDate: d => !d || d.isBefore(moment().add(-1, 'day'))
                    }
                },
                {
                    label: 'Notes',
                    key: 'notes',
                    type: 'textarea',
                    required: true
                },
                {
                    label: 'Reference Number',
                    key: 'TransId',
                    required: true,
                },


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        const columns = [
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                fixed: 'left',
                render: (text, record, key) => {
                    return <React.Fragment>
                        <Tooltip title="View Details">
                            <a href={`/shipmentDetails?id=${record._id}`} target={'_blank'}>

                                <Button icon={'eye'} shape={'circle'}/>

                            </a>
                        </Tooltip>
                        <Tooltip title="View Shipment Response">
                            <Button style={{marginLeft: '5px'}} icon={'read'} shape={'circle'} onClick={() => {
                                // this.setState({})
                                this.showDrawer(record.shipmentResponse)
                            }}/>


                        </Tooltip>
                        {record && <Tooltip title="Payment details">
                            <Button style={{marginTop: '5px'}} icon={'money-collect'}
                                    onClick={() => this.processShowDrawer(record)} shape={'circle'}/>

                        </Tooltip>}
                        <Tooltip title="Cancel Shipment">

                            <Popconfirm
                                title="Are you sure Cancel Shipment ?"
                                onConfirm={() => this.cancelShipmentFxn(record, key)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button loading={this.state.cancelLoader[key]}
                                        style={{marginTop: '5px', marginLeft: '5px'}}
                                        icon={'delete'}
                                        shape={'circle'}/>
                            </Popconfirm>


                        </Tooltip>

                        {record && record.paymentResponse && record.paymentNotes && <Tooltip title="Process Shipment">
                            <Popconfirm
                                title="Are you sure you want to Process"
                                onConfirm={() => this.processShipmentFxn(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button style={{marginTop: '5px'}} icon={'swap'} loading={processButtonLoading}
                                        shape={'circle'}/>
                            </Popconfirm>
                        </Tooltip>}
                    </React.Fragment>
                }
            },
            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },
            {
                title: 'Status',
                key: 'quoteStatus',
                render: (val, r) => {
                    return <Tag style={{marginRight: '10px'}}
                                color={val === 'Payment Pending' ? "#f50" : "#87d068"}>{val}</Tag>
                }
            },

            {
                title: 'Payment Response',
                dataIndex: 'paymentMode',
                key: 'paymentMode',
                render: (val, record) => {
                    return <div>
                        <div>{record && <>Payment Mode : {record.paymentMode}</>}</div>
                        {record && record.paymentResponse && record.paymentResponse.notes &&
                        <>
                            <small> Notes : {record.paymentResponse.notes}</small>
                            <br/>
                            <small> Reference No. : {record.paymentResponse.TransId}</small>
                            <br/>
                            <small> Payment Date
                                : {moment(record.paymentResponse.paymentDate).format('DD-MMM-YYYY')}</small>
                            <br/>
                            <small> Amount : {record.paymentResponse.amount}</small>

                        </>}
                    </div>
                }

            },

            {
                title: 'Pickup Date',
                key: 'pickupInformation.pickupDate',
                sorter: true,
                //width: 150,
                render: (val, record) => {
                    let {pickUpStatus}=record
                    let {pickupDate, pickup, openTime, closeTime} = record && record.pickupInformation
                    return pickUpStatus == 'Created'&&<div>
                        {pickupDate && moment(pickupDate).format('DD-MMM-YYYY')}
                        <div>{closeTime && openTime ? `(${openTime}- ${closeTime})` : ''}</div>
                        <div>{pickup && pickup === 'bookLater' ? 'Book Later' : ''}</div>
                    </div>
                }
            },
            {
                title: 'Provider Name',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),
            },
            {
                title: 'Sender Details',
                key: 'senderData.fullName',
                searchTextName: 'Sender Name',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.senderData && record.senderData.fullName}</div>
                        <div>{record && record.senderData && record.senderData.address}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.cityName}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.countryISO}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.zipCode}</div>

                    </div>)
                }
            },
            {
                title: 'Receiver Details',
                key: 'receiverData.fullName',
                searchTextName: 'Receiver Name',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.receiverData && record.receiverData.fullName}</div>
                        <div>{record && record.receiverData && record.receiverData.address}</div>
                        <div>{record && record.packageDestination && record.packageDestination.cityName}</div>
                        <div>{record && record.packageDestination && record.packageDestination.countryISO}</div>
                        <div>{record && record.packageDestination && record.packageDestination.zipCode}</div>

                    </div>)
                }
            },
            {
                title: 'User Details',
                key: 'userId',
                width: 200,
                dataIndex: 'userId',
                render: (val, record) => {

                    return <div>
                        <div>Name : {val && val.fullName}</div>
                        <div>Email : {val && val.email}</div>

                    </div>
                }
            },
            {
                title: 'Coupon Details',
                key: 'coupon',
                width: 200,
                render: (val, record) => {
                    return record.couponId && <div>
                        <div style={{ display: 'flex' }}> Code :  <Tag>{record.couponName}</Tag></div>
                        <div style={{ display: 'flex' }}>Discount : ({record.couponAmount})</div>
                    </div>

                }
            },
            {
                title: 'Affiliated Commission Amt (%)',
                dataIndex: 'affiliatedUserCommission',
                key: 'affiliatedUserCommission',
                searchTextName: 'affiliatedUserCommission',
                width: 100,
                render: (val, r) => {
                    return val && <div>{`${val} MAD`}({r.affiliatedUserCommissionPercentage} %)</div>
                
                }

            },
            {
                title: ' Affiliated User Details',
                 width: 200,
                dataIndex: 'affiliatedUser',
                render: (val, record) => {

                    return val&&<div>
                        <div>Name :{record && record.affiliatedUser && record.affiliatedUser.fullName}</div>
                       <div>Email :{record && record.affiliatedUser && record.affiliatedUser.email}</div>

                    </div>
                }
            },

            {
                title: 'Details',
                key: 'type',
                render: (val, record) => {
                    if (record && record.packages.length) {
                        if (record.packages.length > 1) {
                            return <div>{_.map(record.packages, (ii, key) => {
                                if (parseInt(key) != record.packages.length - 1) {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>
                                        <Divider/>
                                    </div>
                                } else {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>

                                    </div>
                                }
                            })}</div>
                        } else {
                            return <span> {_.map(record.packages, (ii, key) => {
                                return <div>
                                    <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                    </div>
                                    <div>
                                        <FaWeightHanging/> {ii.weight} {ii.unit}
                                    </div>
                                    <div>
                                        <GiCube/> {ii.quantity}
                                    </div>

                                </div>

                            })}</span>
                        }
                    }


                }

            },

            {
                title: 'Quoted Price',
                key: 'netAmount',
                searchTextName: 'netAmount',
                // width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                        <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },


            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },
            {
                title: 'Updated At',
                key: 'updatedAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.updatedAt ? moment(record.updatedAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },

        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `Expéditions En cours: ${this.state.count}` : `Expéditions En cours: 0`}>

                <Card bordered={true}>
                    <Row>

                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select User Name"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allUsers &&
                                allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col span={10}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Affiliate User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Affiliate User Name"
                                onChange={id => {
                                    this.setState({affiliateUserId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allAffiliateUsers &&
                                allAffiliateUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                       
                    </Row>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        // checkBox={this.onSelectChange}
                               extraProps={{
                                   scroll: {x: 1950}
                               }}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=" Shipment Response"
                    forceRender={true}
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20, marginTop: 20}}>
                        <JSONPretty data={JSON.stringify(shipmentResponse, undefined, 14)}
                                    theme={JSONPrettyMon}></JSONPretty>
                    </div>


                </Drawer>


                <Modal
                    title="PROCESS PAYMENT"
                    visible={this.state.processVisible}
                    key={"right"}
                    footer={null}
                    forceRender={true}
                    onCancel={() => this.onClose()}
                >
                    <Form onSubmit={this.handleSubmit} style={{marginTop: 8}}>
                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" {...submitFormLayout}
                                    loading={paymentDetailsLoader}
                            >
                                Save
                            </Button>
                        </Form.Item>

                    </Form>

                </Modal>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingShipment)
