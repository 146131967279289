import axios from 'axios'

import {apiUrl} from './settings'

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
})

let getToken = () => {
    return ({'headers': {'Authorization': 'Bearer ' + localStorage.getItem('token')}})
}

class Request {

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
            }
        } catch (e) {
        }
    }

    constructor() {

    }

    login(data) {
        return new Promise((next, error) => {
            authAxios.post('/login', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addCountry(data) {
        return new Promise(next => {
            authAxios
                .post('/country', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllCountry(data) {
        return new Promise((next) => {
            authAxios
                .get('/country', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getCountryById(data) {
        return new Promise(next => {
            authAxios
                .get('/country/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateCountry(data) {
        return new Promise(next => {
            authAxios
                .put('/country/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addZone(data) {
        return new Promise(next => {
            authAxios
                .post('/zone', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllZone(data) {
        return new Promise((next) => {
            authAxios
                .get('/zone', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getZoneById(data) {
        return new Promise(next => {
            authAxios
                .get('/zone/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateZone(data) {
        return new Promise(next => {
            authAxios
                .put('/zone/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteZone(data) {
        return new Promise(next => {
            authAxios
                .delete(`/zone/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addCity(data) {
        return new Promise(next => {
            authAxios
                .post('/city', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllCity(data) {
        return new Promise((next) => {
            authAxios
                .get('/city', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getCityById(data) {
        return new Promise(next => {
            authAxios
                .get('/city/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateCity(data) {
        return new Promise(next => {
            authAxios
                .put('/city/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteCity(data) {
        return new Promise(next => {
            authAxios
                .delete(`/city/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    loadCountries(data) {
        return new Promise((next) => {
            authAxios
                .get('/loadCountries', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addPrice(data) {
        return new Promise(next => {
            authAxios
                .post('/price', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllPrice(data) {
        return new Promise((next) => {
            authAxios
                .get('/price', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getPriceById(data) {
        return new Promise(next => {
            authAxios
                .get('/price/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updatePrice(data) {
        return new Promise(next => {
            authAxios
                .put('/price/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deletePrice(data) {
        return new Promise(next => {
            authAxios
                .delete(`/price/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllPriceView(data) {
        return new Promise((next) => {
            authAxios
                .post('/allPrices', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    calculatePrice(data) {
        return new Promise((next) => {
            authAxios
                .post('/calculatePrice', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllMargin(data) {
        return new Promise((next) => {
            authAxios
                .get('/margin', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    saveMargin(data) {
        return new Promise((next) => {
            console.log(data, "999999999999999999999999999999")
            authAxios
                .post('/margins', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllQuote(data) {
        return new Promise((next) => {
            authAxios
                .get('/quote', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getAllUser(data) {
        return new Promise((next) => {
            authAxios
                .get('/user', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getQuoteById(data) {
        return new Promise(next => {
            authAxios
                .get('/quote/' + data._id, {params: {tracking: 'true'}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getUserById(data) {
        return new Promise(next => {
            authAxios
                .get('/user/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    updateUser(data) {
        return new Promise(next => {
            authAxios
                .put(`/editUserAddress/${data._id}`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateUserForDisabled(data) {
        return new Promise(next => {
            authAxios
                .put(`/user/${data._id}`, data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllTransitTime(data) {
        return new Promise((next) => {
            authAxios
                .get('/transitTime', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getTransitById(data) {
        return new Promise(next => {
            authAxios
                .get('/transitTime/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateTransitTime(data) {
        return new Promise(next => {
            authAxios
                .put('/transitTime/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    addFuelCharge(data) {
        return new Promise(next => {
            authAxios
                .post('/fuel', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getAllFuelCharge(data) {
        return new Promise((next) => {
            authAxios
                .get('/fuel', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    getFuelChargeById(data) {
        return new Promise(next => {
            authAxios
                .get('/fuel/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateFuelCharge(data) {
        return new Promise(next => {
            authAxios
                .put('/fuel/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    deleteFuelCharge(data) {
        return new Promise(next => {
            authAxios
                .delete(`/fuel/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    customerSummaryReport(data) {
        return new Promise((next) => {
            authAxios
                .get('/customerSummaryReport', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }


    importCSV(data) {
        return new Promise((next) => {
            authAxios
                .post('/importForCustomerSummary', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    exportFile(data) {
        return new Promise((next) => {
            authAxios
                .post('/exportForCustomerSummary', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    serviceProviderChart(data) {
        return new Promise((next) => {
            authAxios
                .get('/serviceProviderChart', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    shipmentChart(data) {
        return new Promise((next) => {
            authAxios
                .get('/shipmentChart', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    userChart(data) {
        return new Promise((next) => {
            authAxios
                .get('/userChart', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    costAndMarginChart(data) {
        return new Promise((next) => {
            authAxios
                .get('/costAndMarginChart', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addUser(data) {
        return new Promise(next => {
            authAxios
                .post('/user', data, getToken())
                .then(d => {
                    console.log(d, ' this should be sietun')
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    processShipment(data) {
        return new Promise(next => {
            authAxios
                .get(`/pay/${data.ReturnOid}`, {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    updateQuote(data, id) {
        return new Promise(next => {
            authAxios
                .put('/quote/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    trackPackage(data) {
        return new Promise((next) => {
            authAxios
                .get('/trackPackage', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    createPickup(data) {
        return new Promise(next => {
            authAxios
                .post(`/pickup/create/new`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    voidShipment(data) {
        return new Promise(next => {
            authAxios
                .post(`/quote/void`, data, getToken())
                .then(d => {

                    next(d.data)
                })
                .catch(err => {

                    this.error(err)
                })
        })
    }

    downLoadZipForFedex(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadZipForFedex', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    downLoadZip(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadZip', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    downLoadCommercial(data) {
        return new Promise(next => {
            authAxios
                .post('/printCommercial', data, getToken())
                .then(d => {
                    if (d.data) {
                        let linkValue = API_URL + `${d.data.pdfName}`
                        window.open(linkValue, 'download')
                    }
                    next('done')
                })
                .catch(err => {
                    this.error(err)
                })
        })
    }

    loadUser(data) {
        return new Promise(next => {
            authAxios
                .get('/allUsers', {params: {...data}, ...getToken()})
                .then(d => {
                    next(d.data)
                })
                .catch(err => {


                    this.error(err)
                })
        })
    }

    downLoadLabelForAramex(data) {
        return new Promise((next) => {
            authAxios
                .post('/downloadLabelForAramex', data, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    addCoupon(data) {
        return new Promise(next => {
            authAxios
                .post('/coupon', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    updateCoupon(data) {
        return new Promise(next => {
            authAxios
                .put('/coupon/', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }


    getAllCoupons(data) {
        return new Promise((next) => {
            authAxios
                .get('/coupon', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    deleteCoupon(data) {
        return new Promise(next => {
            authAxios
                .delete(`/coupon/` + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    getCouponById(data) {
        return new Promise(next => {
            authAxios
                .get('/coupon/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    loadDomesticCities(data) {
        return new Promise((next) => {
            authAxios
                .get('/loadDomesticCity', {params: {...data}, ...getToken()})
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({error: true, err})
                    this.error(err)
                })

        })
    }

    changePasswordByAdmin(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/changePasswordByAdmin', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    error(err)
                    this.error(err)
                })
        })
    }

    getcouponDataInQuote(data) {
        return new Promise(next => {
            authAxios
                .get('/couponIdFromQuote/' + data._id, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }

    sendNotification(data) {
        return new Promise(next => {
            authAxios
                .post('/notification', data, getToken())
                .then(d => {
                    next(d.data)
                })
                .catch(err => {
                    next({error: true, err})
                    this.error(err)
                })
        })
    }
}

export default new Request()
