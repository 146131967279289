import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Tabs,
    Row,
    Col,
    Icon,
    InputNumber,
    Switch,
    Table
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'

const FormItem = Form.Item
const {Option} = Select
const TabPane = Tabs.TabPane

const services = [
    {name: 'dhl', text: 'DHL'},
    {name: 'fedex', text: 'Fedex'},
    {name: 'ups', text: 'UPS Saver'},
    {name: 'aramex', text: 'Aramex'}
]

@Form.create()
class Margin extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            data: {},
            dataArray: []
        }

    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let main = this.setFormVars(values)
                dispatch(showLoader())
                console.log(main, "------------------------------")
                let x = await Request.saveMargin(main)
                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: x.msg
                    })


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }


            }
        })
    }

    async componentDidMount() {

        const {form} = this.props
        let {data, error} = await Request.getAllMargin()
        if (error) {
            notification.error({
                message: 'Error Getting Data'
            })
        } else {
            form.setFieldsValue(this.getFormVars(data))
        }

    }

    getFormVars = (data) => {

        let retObj = {}

        data.map((val) => {
            let service = _.find(services, a => a.text === val.serviceName)
            if (!retObj[service.name]) {
                retObj[service.name] = {}
                retObj[service.name][`inboundParcelDimension`] = {}
                retObj[service.name][`outboundParcelDimension`] = {}
                retObj[service.name][`domesticParcelDimension`] = {}
                retObj[service.name][`inboundEnvelopeDimension`] = {}
                retObj[service.name][`outboundEnvelopeDimension`] = {}
                retObj[service.name][`domesticEnvelopeDimension`] = {}
                retObj[service.name][`inboundPalletDimension`] = {}
                retObj[service.name][`outboundPalletDimension`] = {}
                retObj[service.name][`domesticPalletDimension`] = {}

            }
            retObj[service.name][val.type.toLowerCase()] = val.amount
            retObj[service.name][`${val.type.toLowerCase()}Active`] = val.active || false
            retObj[service.name][`${val.type.toLowerCase()}MaxShipmentDays`] = val && val.maxShipmentDays
            retObj[service.name][`${val.type.toLowerCase()}ParcelDimension`]['maxWeight'] = val && val.parcelDimension && val.parcelDimension.maxWeight
            retObj[service.name][`${val.type.toLowerCase()}ParcelDimension`]['maxHeight'] = val && val.parcelDimension && val.parcelDimension.maxHeight
            retObj[service.name][`${val.type.toLowerCase()}ParcelDimension`]['maxWidth'] = val && val.parcelDimension && val.parcelDimension.maxWidth
            retObj[service.name][`${val.type.toLowerCase()}ParcelDimension`]['maxLength'] = val && val.parcelDimension && val.parcelDimension.maxLength
            retObj[service.name][`${val.type.toLowerCase()}EnvelopeDimension`]['maxWeight'] = val && val.envelopeDimension && val.envelopeDimension.maxWeight
            retObj[service.name][`${val.type.toLowerCase()}EnvelopeDimension`]['maxHeight'] = val && val.envelopeDimension && val.envelopeDimension.maxHeight
            retObj[service.name][`${val.type.toLowerCase()}EnvelopeDimension`]['maxWidth'] = val && val.envelopeDimension && val.envelopeDimension.maxWidth
            retObj[service.name][`${val.type.toLowerCase()}EnvelopeDimension`]['maxLength'] = val && val.envelopeDimension && val.envelopeDimension.maxLength
            retObj[service.name][`${val.type.toLowerCase()}PalletDimension`]['maxWeight'] = val && val.palletDimension && val.palletDimension.maxWeight
            retObj[service.name][`${val.type.toLowerCase()}PalletDimension`]['maxHeight'] = val && val.palletDimension && val.palletDimension.maxHeight
            retObj[service.name][`${val.type.toLowerCase()}PalletDimension`]['maxWidth'] = val && val.palletDimension && val.palletDimension.maxWidth
            retObj[service.name][`${val.type.toLowerCase()}PalletDimension`]['maxLength'] = val && val.palletDimension && val.palletDimension.maxLength
            retObj[service.name]['showPallet'] = val.showPallet || false
            retObj[service.name]['showParcel'] = val.showParcel || false
            retObj[service.name]['showEnvelope'] = val.showEnvelope || false

        })
        return retObj
    }

    setFormVars = (data) => {
        let retArr = []

        _.each(data, (val, key) => {
            console.log(val, "fffffffffffffffffffffffffffffffffffff")

            let service = _.find(services, a => a.name === key)
            retArr.push({
                serviceName: service.text,
                type: 'INBOUND',
                amount: val.inbound || 0,
                active: val.inboundActive,
                maxShipmentDays: val.inboundMaxShipmentDays,
                parcelDimension: val[`inboundParcelDimension`],
                envelopeDimension: val[`inboundEnvelopeDimension`],
                palletDimension: val[`inboundPalletDimension`],
                showEnvelope: val.showEnvelope,
                showPallet: val.showPallet,
                showParcel: val.showParcel
            })

            retArr.push({
                serviceName: service.text,
                type: 'OUTBOUND',
                amount: val.outbound || 0,
                active: val.outboundActive,
                maxShipmentDays: val.outboundMaxShipmentDays,
                parcelDimension: val[`outboundParcelDimension`],
                envelopeDimension: val[`outboundEnvelopeDimension`],
                palletDimension: val[`outboundPalletDimension`],
                showEnvelope: val.showEnvelope,
                showPallet: val.showPallet,
                showParcel: val.showParcel
            })

            if (service && service.name === 'aramex') {
                retArr.push({
                    serviceName: service.text,
                    type: 'DOMESTIC',
                    amount: val.domestic || 0,
                    active: val.domesticActive,
                    maxShipmentDays: val.domesticMaxShipmentDays,
                    parcelDimension: val[`domesticParcelDimension`],
                    envelopeDimension: val[`domesticEnvelopeDimension`],
                    palletDimension: val[`domesticPalletDimension`],
                    showEnvelope: val.showEnvelope,
                    showPallet: val.showPallet,
                    showParcel: val.showParcel
                })


            }

        })

        return retArr

    }
    getDataSource = (type) => {
        if (type === 'aramex') {
            return [
                {
                    type: 'Outbound Parcel Max Dimension',
                    formKey: 'outboundParcelDimension'
                },
                {
                    type: 'Inbound Parcel Max Dimension',
                    formKey: 'inboundParcelDimension'
                },
                {
                    type: 'Domestic Parcel Max Dimension',
                    formKey: 'domesticParcelDimension'
                },
                {
                    type: 'Outbound Document Max Dimension',
                    formKey: 'outboundEnvelopeDimension'
                },
                {
                    type: 'Inbound Document Max Dimension',
                    formKey: 'inboundEnvelopeDimension'
                },
                {
                    type: 'Domestic Document Max Dimension',
                    formKey: 'domesticEnvelopeDimension'
                },
                {
                    type: 'Outbound Palette Max Dimension',
                    formKey: 'outboundPalletDimension'
                },
                {
                    type: 'Inbound Palette Max Dimension',
                    formKey: 'inboundPalletDimension'
                },
                {
                    type: 'Domestic Palette Max Dimension',
                    formKey: 'domesticPalletDimension'
                },
            ]
        } else {
            return [
                {
                    type: 'Outbound Parcel Max Dimension',
                    formKey: 'outboundParcelDimension'
                },
                {
                    type: 'Inbound Parcel Max Dimension',
                    formKey: 'inboundParcelDimension'
                },
                {
                    type: 'Outbound Document Max Dimension',
                    formKey: 'outboundEnvelopeDimension'
                },
                {
                    type: 'Inbound Document Max Dimension',
                    formKey: 'inboundEnvelopeDimension'
                },
                {
                    type: 'Outbound Palette Max Dimension',
                    formKey: 'outboundPalletDimension'
                },
                {
                    type: 'Inbound Palette Max Dimension',
                    formKey: 'inboundPalletDimension'
                }
            ]
        }
    }

    formComponent = (type) => {
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props
        return (<>
            <Form.Item label="Outbound Margin">
                {getFieldDecorator(`${type}.outbound`)
                (<InputNumber
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    disabled={!getFieldValue(`${type}.outboundActive`)}
                />)}
                {getFieldDecorator(`${type}.outboundActive`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}


            </Form.Item>

            <Form.Item label="Inbound Margin">
                {getFieldDecorator(`${type}.inbound`)
                (<InputNumber
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    disabled={!getFieldValue(`${type}.inboundActive`)}
                />)}
                {getFieldDecorator(`${type}.inboundActive`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}
            </Form.Item>


            {type === 'aramex' && <Form.Item label="Domestic Margin">
                {getFieldDecorator(`${type}.domestic`)
                (<InputNumber
                    min={0}
                    max={100}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    disabled={!getFieldValue(`${type}.domesticActive`)}
                />)}
                {getFieldDecorator(`${type}.domesticActive`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}


            </Form.Item>}

            <Form.Item label="Outbound Max. Shipment Days">
                {getFieldDecorator(`${type}.outboundMaxShipmentDays`)
                (<InputNumber
                    placeholder='Days'
                />)}
            </Form.Item>
            <Form.Item label="Inbound Max Shipment Days">
                {getFieldDecorator(`${type}.inboundMaxShipmentDays`)
                (<InputNumber
                    placeholder='Days'
                />)}
            </Form.Item>
            {type === 'aramex' && <Form.Item label="Domestic Max Shipment Days">
                {getFieldDecorator(`${type}.domesticMaxShipmentDays`)
                (<InputNumber
                    placeholder='Days'
                />)}
            </Form.Item>}

            <Form.Item label="Show Pallet">
                {getFieldDecorator(`${type}.showPallet`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}
            </Form.Item>
            <Form.Item label="Show Parcel">

                {getFieldDecorator(`${type}.showParcel`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}
            </Form.Item>
            <Form.Item label="Show Envelope">
                {getFieldDecorator(`${type}.showEnvelope`, {valuePropName: 'checked'})
                (<Switch style={{marginLeft: '1em'}}/>)}
            </Form.Item>
            <Table
                key={type}
                size='small'
                columns={[
                    {
                        title: 'Type',
                        key: 'type',
                        dataIndex: 'type'
                    },
                    {
                        title: 'Max Weight',
                        key: 'maxWeight',
                        render: (val, record) => (getFieldDecorator(`${type}.${record.formKey}.maxWeight`)
                        (<InputNumber
                            placeholder='Weight'
                        />))
                    },
                    {
                        title: 'Max Width',
                        key: 'maxWidth',
                        render: (val, record) => (getFieldDecorator(`${type}.${record.formKey}.maxWidth`)
                        (<InputNumber
                            style={{marginLeft: '1em'}}
                            placeholder='Height'
                        />))
                    },
                    {
                        title: 'Max Height',
                        key: 'maxHeight',
                        render: (val, record) => (getFieldDecorator(`${type}.${record.formKey}.maxHeight`)
                        (<InputNumber
                            style={{marginLeft: '1em'}}
                            placeholder='Width'
                        />))
                    },
                    {
                        title: 'Max Length',
                        key: 'maxLength',
                        render: (val, record) => (getFieldDecorator(`${type}.${record.formKey}.maxLength`)
                        (<InputNumber
                            style={{marginLeft: '1em'}}
                            placeholder='Length'
                        />))
                    }
                ]}
                dataSource={this.getDataSource(type)}
                pagination={false}
            />

        </>)
    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {} = this.state

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        return (
            <PageHeaderWrapper
                title={'All Margins'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false}
                          style={{marginTop: 8}} {...formItemLayout}>

                        <Tabs defaultActiveKey="0" type="card"
                        >

                            <TabPane forceRender={true} tab={'DHL'} key={'0'}>
                                {this.formComponent('dhl')}
                            </TabPane>

                            <TabPane forceRender={true} tab={'Fedex'} key={'1'}>
                                {this.formComponent('fedex')}
                            </TabPane>


                            <TabPane forceRender={true} tab={'Aramex'} key={'2'}>
                                {this.formComponent('aramex')}
                            </TabPane>
                            <TabPane forceRender={true} tab={'UPS Saver'} key={'3'}>
                                {this.formComponent('ups')}
                            </TabPane>


                        </Tabs>


                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Margin)
