import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon,
    Form, Row, Col
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

@Form.create()
class DrawerForm extends Component {
    handleSubmit = e => {
        const {form, handleSubmit, singleTransit} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                values._id = this.state.id
                this.setState({loading: true})

                let x = await Request.updateTransitTime(values)
                this.setState({loading: false})


                if (!x.error) {

                    notification.success({
                        message: x.msg

                    })
                    if (handleSubmit) {
                        handleSubmit()
                    }
                    form.resetFields()


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }


            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            showBranchCommission: false,
            branchCommissionValue: 0,
            loading: false
        }
    }

    async componentDidMount() {


        const {singleTransit} = this.props
        let {data: countryData} = await Request.loadCountries()
        this.setState({allCountry: countryData})

        Request.getTransitById({_id: singleTransit && singleTransit._id}).then(({data, error, message}) => {
            if (!error) {
                this.setState({
                    id: data && data._id
                })

                this.props.form.setFieldsValue({
                    serviceName: data && data.serviceName,
                    isoCode: data && data.isoCode,
                    time: data && data.time,
                    days: data && data.days,

                })
            } else {
                notification.error({
                    message: 'Error Getting Data'
                })
            }
        })


    }

    render() {

        const {form} = this.props
        let {allCountry} = this.state
        const inputTypes = {
            fields: [

                {
                    label: 'Service Provider Name',
                    key: 'serviceName',
                    required: true,
                    type: 'select',
                    options: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
                    onChange: (mode) => {
                        this.props.form.setFieldsValue({serviceName: mode})
                    }

                },
                {
                    label: 'Country Code',
                    type: 'select',
                    key: 'isoCode',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x.isoCode,
                    valueAccessor: x => `${x.countryName}(${x.isoCode})`,
                    options: allCountry || [],
                    onChange: isoCode => {
                        this.props.form.setFieldsValue({
                            isoCode: isoCode
                        })

                    }
                },
                {
                    label: 'Time',
                    key: 'time',
                    dataIndex: 'time',

                },
                {
                    label: 'Days',
                    key: 'days',
                    dataIndex: 'days',
                    type: 'number'

                },


            ]
        }
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const {
            form: {getFieldDecorator}
        } = this.props
        return (


            <Form onSubmit={this.handleSubmit} hideRequiredMark>


                <Row>

                    <Col>

                        <GetAllFormFields
                            inputSchema={inputTypes}
                            getFieldDecorator={getFieldDecorator}
                            formItemLayout={formItemLayout}

                        />
                        <Form.Item>
                            <div style={{textAlign: 'center'}}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={this.state.loading}>
                                    UPDATE
                                </Button></div>

                        </Form.Item>

                    </Col>

                </Row>


            </Form>
        )


    }
}

class AllTransit extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            serviceName: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await Request.getAllTransitTime({
                ...params

            })
            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {serviceName, singleTransit} = this.state


        const columns = [
            {
                title: 'Service Provider Name',
                dataIndex: 'serviceName',
                key: 'serviceName',
                filters: serviceName && serviceName.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                title: 'Country Code',
                dataIndex: 'isoCode',
                key: 'isoCode',
                width: 150

            },


            {
                title: 'Time',
                key: 'time',
                width: 150,
                dataIndex: 'time',


            },
            {
                title: 'Days',
                key: 'days',
                width: 150,
                dataIndex: 'days',


            },
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Transit Time">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    this.setState({singleTransit: record}, () => {
                                        this.showDrawer()
                                    })
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Transits: ${this.state.count}` : `All Transits: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

                <Drawer
                    title="Edit Transit Time Details"
                    forceRender={true}
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20, marginTop: 20}}>
                        {this.state.visible && <DrawerForm
                            singleTransit={singleTransit && singleTransit}

                            handleSubmit={() => {

                                this.setState({visible: false})
                                this.table.current.reload()

                            }}/>}
                    </div>


                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTransit)
