import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Modal,
    Tag,
    Popconfirm,
    DatePicker,
    notification,
    Drawer,
    Switch,
    Select,
    Row,
    Col,
    Divider,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'
import {connect} from 'react-redux'

const { RangePicker } = DatePicker

class PendingShipment extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            selectedRowKeys: [],
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver'],
            time: {
                key: 'createdAt',
                from: null,
                to: null
            },

        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.quoteStatus = ['Payment Failed', 'Canceled']
            params.type='DOMESTIC'
            const {Option} = Select
            let user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                params.affiliatedUser = user._id
            }

            let data = await Request.getAllQuote({
                ...params, regExFilters: ['receiverData.fullName', 'senderData.fullName'],
                dateFilter: this.state.time,

            })

            this.setState({count: data.total})
            resolve(data)
        })
    }
    async componentDidMount() {
    }


    chooseRangerPicker = (date, dateString) => {
        this.setState(
            {
                time: {
                    key: 'createdAt',
                    from: date[0],
                    to: date[1]
                }
            }, () => {
                if (date[0] == undefined) {
                    this.table.current.reload()
                }
            }
        )

    }

    onOk = value => {
        this.table.current.reload()
    }

    render() {
       const {
         serviceProviderName
        } = this.state


        const columns = [
          
            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },
            {
                title: 'Coupon Code',
                dataIndex: 'couponName',
                key: 'couponName',
                searchTextName: 'couponName',
                width: 100,
                render: (val, r) => {
                    return val &&<Tag>{val}</Tag>
                }
            },
            {
                title: 'Commission Amt (%)',
                dataIndex: 'affiliatedUserCommission',
                key: 'affiliatedUserCommission',
                searchTextName: 'affiliatedUserCommission',
                width: 100,
                render: (val, r) => {
                    return val && <div>{val}({r.affiliatedUserCommissionPercentage} %)</div>
                }

            },
            {
                title: 'Status',
                dataIndex: 'quoteStatus',
                key: 'quoteStatus',
                render: (val, r) => {
                    return <Tag style={{marginRight: '10px'}}
                                color={val === 'Canceled' ? "#FF0000" : "#87d068"}>{val}</Tag>
                }

            },
            {
                title: 'Payment Response',
                dataIndex: 'paymentMode',
                key: 'paymentMode',
                width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && <>Payment Mode : {record.paymentMode}</>}</div>
                        {record && record.paymentResponse && record.paymentResponse.notes &&
                        <>
                            <small> Notes : {record.paymentResponse.notes}</small>
                            <br/>
                            <small> Reference No. : {record.paymentResponse.TransId}</small>
                            <br/>
                            <small> Payment Date
                                : {moment(record.paymentResponse.paymentDate).format('DD-MMM-YYYY')}</small>
                            <br/>
                            <small> Amount : {record.paymentResponse.amount}</small>

                        </>}
                    </div>
                }

            },


            {
                title: 'Canceled Date',
                key: 'shipmentSanitizeResponse.cancelDate',
                sorter: true,
                //width: 150,
                render: (val, record) => {
                    let {cancelDate} = record && record.shipmentSanitizeResponse
                    return <div>
                        {cancelDate && moment(cancelDate).format('DD-MMM-YYYY')}
                    </div>
                }
            },
            {
                title: 'Provider Name',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                title: 'Details',
                key: 'type',
                width: 150,
                render: (val, record) => {
                    if (record && record.packages.length) {
                        if (record.packages.length > 1) {
                            return <div>{_.map(record.packages, (ii, key) => {
                                if (parseInt(key) != record.packages.length - 1) {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>
                                        <Divider/>
                                    </div>
                                } else {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>

                                    </div>
                                }
                            })}</div>
                        } else {
                            return <span> {_.map(record.packages, (ii, key) => {
                                return <div>
                                    <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                    </div>
                                    <div>
                                        <FaWeightHanging/> {ii.weight} {ii.unit}
                                    </div>
                                    <div>
                                        <GiCube/> {ii.quantity}
                                    </div>

                                </div>

                            })}</span>
                        }
                    }


                }

            },

            {
                title: 'Quoted Price',
                key: 'netAmount',
                searchTextName: 'netAmount',
                // width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                        <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },

            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },

        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `Envois nationaux annulés: ${this.state.count}` : `Envois nationaux annulés: 0`}>

                <Card bordered={true}>
                <Row>
                        <Col span={12}>
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={['Start Time', 'End Time']}
                                onChange={this.chooseRangerPicker}
                                onOk={this.onOk}
                            />
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        // checkBox={this.onSelectChange}
                               extraProps={{
                                   scroll: {x: 1000}
                               }}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
             </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingShipment)
