import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Timeline, Button, Steps, Card, Descriptions } from 'antd'
import Request from '../../request'
import styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
const { Step } = Steps

class UPSTracking extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            message: ''
        }

    }

    async componentDidMount() {

    }

    handleSubmit = async (valData) => {
        this.setState({ loading: true, message: null })
        let data = await Request.trackPackage(valData)
        this.setState({ message: data.status || 'Invalid tracking number', loading: false })
    }
    render() {
        let { details, data, buttonFlag, onBack, t } = this.props
     
     
        let { message, loading } = this.state
        return (<>
            {data && data.arr && <div className={'steps'}>
                <Timeline mode='left'>
                    {_.flatMap(data.arr, (val, index) => {
                        return (
                            <Timeline.Item color='green' >
                                {<>
                                <div><b>{val.country} {val.city}</b> </div>
                                    <div>{`${val.msg}`}</div>
                                    <div>{`Date: ${val.date} ${val.time}`}</div>
                                </>}
                            </Timeline.Item>
                        )
                    })}
                    {/* {_.flatMap(details.ActivityLocation, (val, index) => {
                        return (
                            <Timeline.Item color='green' label={`${details.GMTDate} ${moment(details.Time, 'HHmmss').format('HH:mm:ss')}`}
                            >
                                {<>
                                    <div><b>{val.Address[0].CountryCode[0]}</b></div>
                                    <div>{`${details.Status[index].StatusType[0].Description[0]}`}</div>
                                </>}
                            </Timeline.Item>
                        )
                    })} */}
                </Timeline>
                {/* <Steps progressDot current={details.ActivityLocation.length - 1} direction="vertical">
                    {_.flatMap(details.ActivityLocation, (val, index) => {
                        return (
                            <Step status='finish' title={val.Address[0].CountryCode[0]}
                                description={<>
                                    <div>{`${details.Status[index].StatusType[0].Description[0]}`}</div>
                                    <div>{`Date: ${details.GMTDate} ${moment(details.Time, 'HHmmss').format('HH:mm:ss')}`}</div>
                                </>}
                            />
                        )
                    })}
                </Steps> */}
            </div>}
            <div className='description'>
                <Descriptions bordered size='small' layout='vertical' >
                    <Descriptions.Item span={3} label={'TrackingNumber'}>{data.trackingNumber}</Descriptions.Item>
                    <Descriptions.Item span={3} label={'From'}>{data.shipmentData.shipperAddress.fullAddress}</Descriptions.Item>
                    <Descriptions.Item span={3} label={'To'}>{data.shipmentData.shipToAddress.fullAddress}</Descriptions.Item>
                </Descriptions>

            </div>
        </>)

    }

}

const mapStateToProps = ({ counter, router }) => ({
    count: counter.count,
    isIncrementing: counter.isIncrementing,
    isDecrementing: counter.isDecrementing,
    search: router.location.search,
    btnLoading: counter.btnLoading

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UPSTracking)
