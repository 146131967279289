import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Descriptions,
    Table,
    Tag,
    Row,
    Col,
    Tooltip,
    Icon,
    Radio
} from 'antd'
import {
    PieChart, Pie, Sector, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip as BarTooltip
} from 'recharts'
import { ChromePicker } from 'react-color'
import { RiCheckboxBlankLine } from 'react-icons/ri'

import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../request'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import { getUrlPushWrapper } from '../../routes'
import styles from './styles.less'
import ProviderIcon from '../../components/ProviderIcon'

const Option = { Select }
const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
const RADIAN = Math.PI / 180;

const data = [
    { name: 'Group A', value: 400, pv: 200 },
    { name: 'Group B', value: 300, pv: 200 },
    { name: 'Group C', value: 300, pv: 200 },
    { name: 'Group D', value: 200, pv: 200 },


];
const COLORS = ['#FFCC00', '#EE2E24', '#660099', '#E9A53A'];
const barChart = [
    {
        name: 'Page A', uv: 4000, pv: 2400, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];
const costBar = [
    {
        name: 'Page A', uv: 4000, pv: 2400, mv: 4000, amt: 2400,
    },
    {
        name: 'Page B', uv: 3000, pv: 1398, mv: 4000, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, mv: 4000, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, mv: 4000, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, mv: 4000, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, mv: 4000, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, mv: 4000, amt: 2100,
    },
];

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shipmentData: [],
            value: 'month',
            shipmentValue: 'month'
        }
    }

    changeShippingData = e => {
        console.log('radio checked', e.target.value);
        let filter = {}
        if (e.target.value === 'month') {
            filter = { isMonthly: true }
        }
        else if (e.target.value === 'day') {
            filter = { isDay: true }
        }
        else {
            filter = { isWeekly: true }
        }
        this.setState({
            shipmentValue: e.target.value,
        }, () => {
            Request.shipmentChart({ ...filter }).then(({ data: shipmentData }) => {
                this.setState({ shipmentData })
            })

        });
    };


    async componentDidMount() {

        let endDate = moment().endOf('month')
        let startDate = moment().subtract(1, 'month').startOf('month')
        Request.getAllQuote({
            createdAt: {$gte: startDate, $lte: endDate},
            quoteStatus: ['Placed']
        }).then(({data: quoteData}) => {
            this.setState({quoteData})
        })
        Request.serviceProviderChart({ isMonthly: true }).then(({ data: serviceProviderData }) => {
            this.setState({ serviceProviderData })
        })
        Request.shipmentChart({ isMonthly: true }).then(({ data: shipmentData }) => {
            this.setState({ shipmentData })
        })
        Request.userChart().then(({ data: userChart }) => {
            this.setState({ userChart })
        })

        Request.costAndMarginChart({ isWeekly: true }).then(({ data: costMarginData }) => {
            this.setState({ costMarginData })
        })


    }

    onChange = e => {
        console.log('radio checked', e.target.value);
        let filter = {}
        if (e.target.value === 'month') {
            filter = { isMonthly: true }
        }
        else if (e.target.value === 'day') {
            filter = { isDay: true }
        }
        else {
            filter = { isWeekly: true }
        }
        this.setState({
            value: e.target.value,
        }, () => {
            Request.serviceProviderChart({ ...filter }).then(({ data: serviceProviderData }) => {
                this.setState({ serviceProviderData })
            })

        });
    };


    render() {


        let { shipmentData, serviceProviderData, costMarginData, quoteData, userChart } = this.state
        const columns = [
            {
                title: 'Created At',
                key: 'createdAt',
                width: 120,
                render: (val, record) => {
                    return <div>
                        <div>
                            {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                        </div>
                        <div> {record && record.createdAt ? moment(record.createdAt).format('h:mm a') : null}
                        </div>
                    </div>
                }
            },
            {
                title: 'Order Id',
                key: 'orderId',
                dataIndex: 'orderId',
                width: 80


            },
            {
                title: 'Sender Name',
                key: 'senderData.fullName',
                width: 100,
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.senderData && record.senderData.fullName}</div>

                    </div>)
                }
            },

            {
                title: 'Shipment',
                key: 'shipment',
                width: 150,
                render: (val, record) => {
                    return (<div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <div>
                            <div>{record && record.pickupLocation && record.pickupLocation.countryISO}</div>
                            <div>{record && record.pickupLocation && record.pickupLocation.zipCode}</div>

                        </div>
                        <div>-</div>
                        <div>

                            <div>{record && record.packageDestination && record.packageDestination.countryISO}</div>
                            <div>{record && record.packageDestination && record.packageDestination.zipCode}</div>

                        </div>
                    </div>)
                }
            },

            {
                title: 'Carrier',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                width: 80
            },


            {
                title: 'Quote Status',
                key: 'quoteStatus',
                dataIndex: 'quoteStatus',
                width: 100,
                render: (val) => {
                    if (val === 'Placed') {
                        return <div><Tag style={{ backgroundColor: '#52c41a', color: 'white' }}>{val}</Tag>
                        </div>
                    }
                    else if (val === 'Failed') {
                        return <div><Tag style={{ backgroundColor: 'red', color: 'white' }}>{val}</Tag>
                        </div>
                    } else {
                        return <div><Tag style={{ backgroundColor: 'orange', color: 'white' }}>{val}</Tag>
                        </div>

                    }


                }
            },
            {
                key: 'actions',
                title: 'Action',
                width: 80,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="View Details">
                            <a href={`/shipmentDetails?id=${record._id}`} target={'_blank'}>

                                <Button icon={'eye'} shape={'circle'} />

                            </a>
                        </Tooltip>

                    </React.Fragment>
                }
            },


        ]


        return (
            <div className={styles.dashboard}>
                <div className={styles.firstRow}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Card>
                                <Row><Col span={12}><h3>SHIPPING</h3></Col> <Col span={12}>

                                    <Radio.Group onChange={this.changeShippingData} value={this.state.shipmentValue}
                                        style={{ float: 'right' }}>
                                        <Radio value={'month'}>Month</Radio>
                                        <Radio value={'week'}>Week</Radio>
                                        <Radio value={'day'}>Day</Radio>
                                    </Radio.Group>

                                </Col></Row>
                                <div>
                                    {shipmentData && <BarChart
                                        width={450}
                                        height={300}
                                        data={shipmentData}
                                        margin={{
                                            top: 5, right: 10, left: 10, bottom: 5,
                                        }}
                                        barSize={20}
                                    >
                                        <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                        <YAxis />
                                        <BarTooltip />
                                        <Legend />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Bar dataKey="shipment" fill="#2B3252" />
                                    </BarChart>}
                                </div>


                            </Card>
                        </Col>
                        <Col span={12}>


                            <Card>
                                <Row><Col span={12}><h3>COST V/S MARGIN</h3></Col> <Col span={12}><h3
                                    className={styles.dashboardHeading}></h3>
                                </Col></Row>
                                <div>
                                    <BarChart
                                        width={450}
                                        height={300}
                                        data={costMarginData}
                                        margin={{
                                            top: 5, right: 10, left: 10, bottom: 4,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="2 2" />
                                        <XAxis dataKey="name" padding={{ left: 10, right: 10 }} />
                                        <YAxis />
                                        <BarTooltip />
                                        <Legend />
                                        <Bar dataKey="margin" fill="#2B3252" />

                                        <Bar dataKey="amount" fill="#EB5757" />
                                        <Bar dataKey="totalAmount" fill="#F2994A" />

                                    </BarChart>
                                </div>

                            </Card>

                        </Col>

                    </Row>

                </div>

                <Row gutter={16} type={'flex'}>

                    <Col span={18} className={styles.colFlex}>
                        <Card className={styles.cardFlex}>
                            <Row><Col span={12}><h3>EASYSHIP ORDER</h3></Col> <Col span={12}> <a
                                style={{ float: 'right' }}
                                href={`/shipments/placed`} target={'_blank'}>

                                <Button style={{ marginBottom: '10px' }}>View All</Button>
                            </a>
                            </Col></Row>
                            <Table rowKey={x => x.orderId}
                                size={'small'} bordered className={'table'} dataSource={quoteData || []}
                                columns={columns}
                                pagination={false}
                                scroll={{ y: 350 }}

                            />

                        </Card>


                    </Col>
                    <Col span={6} className={styles.colFlex}>


                        <Card className={styles.cardFlex}>
                            <Row><Col><h3>CARRIER'S SHIPMENT</h3></Col> </Row>
                            <Row><Col> <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={'month'}>Month</Radio>

                                <Radio value={'week'}>Week</Radio>
                                <Radio value={'day'}>Today</Radio>

                            </Radio.Group></Col> </Row>


                            <div><PieChart width={200} height={250} onMouseEnter={this.onPieEnter}>
                                <BarTooltip />
                                <Pie
                                    data={serviceProviderData}
                                    cx={90}
                                    cy={100}
                                    innerRadius={40}
                                    outerRadius={95}
                                    fill="#8884d8"
                                    dataKey="value"
                                    labelLine={false}
                                    label={renderCustomizedLabel}


                                >

                                    {
                                        serviceProviderData && serviceProviderData.map((entry, index) => {
                                            if (entry.name == 'UPS Saver') {
                                                return <Cell
                                                    key={`cell-${index}`}
                                                    fill={'#E9A53A'} />
                                            } else if (entry.name == 'DHL') {
                                                return <Cell
                                                    key={`cell-${index}`}
                                                    fill={'#FFCC00'} />
                                            }
                                            else if (entry.name == 'Fedex') {
                                                return <Cell
                                                    key={`cell-${index}`}
                                                    fill={'#660099'} />
                                            } else {
                                                return <Cell
                                                    key={`cell-${index}`}
                                                    fill={'#EE2E24'} />
                                            }

                                        })
                                    }

                                </Pie>

                            </PieChart>
                                <div className={styles.displayLogo}>
                                    <ul className={styles.dashboardList}>
                                        <li className={styles.dhl}><ProviderIcon
                                            type={'DHL'} />
                                        </li>
                                        <li className={styles.ups}><ProviderIcon
                                            type={'UPS Saver'} />
                                        </li>
                                    </ul>
                                    <ul className={styles.dashboardList}>
                                        <li className={styles.fedex}><ProviderIcon
                                            type={'Fedex'} />

                                        </li>
                                        <li className={styles.aramex}><ProviderIcon type={'Aramex'} />

                                        </li>
                                    </ul>
                                </div>


                            </div>


                        </Card>


                    </Col>
                </Row>
                <div className={styles.firstRow}>
                        <Row gutter={16}>
                            <Col span={24}> 
                                <Card>
                                    <Row><Col span={12}><h3>ALL USERS</h3></Col> <Col span={12}>
                                    </Col></Row>
                                    <div>
                                        {userChart && <BarChart
                                            width={800}
                                            height={300}
                                            data={userChart}
                                            margin={{
                                                top: 5, right: 10, left: 15, bottom: 5,
                                            }}
                                            barSize={20}
                                        >
                                            <XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
                                            <YAxis />
                                            <BarTooltip />
                                            <Legend />
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <Bar dataKey="user" fill="#2B3252" />
                                        </BarChart>}
                                    </div>


                                </Card>
                            </Col>

                        </Row>

                    </div>
            </div>

        )
    }

}

export default Dashboard

