import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row,
    Col,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class PriceList extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: ''
        }

    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = null
                let array = []
                let {data} = await Request.getAllPriceView(values)
                _.each(data, (i, key) => {
                    let abc = {}
                    i = _.sortBy(i, ['zone'], ['asc']);

                    abc = {
                        weight: parseFloat(key),
                        zone1: {zone: i[0].amount, _id: i[0]._id},
                        zone2: {zone: i[1].amount, _id: i[1]._id},
                        zone3: {zone: i[2].amount, _id: i[2]._id},
                        zone4: {zone: i[3].amount, _id: i[3]._id},
                        zone5: {zone: i[4].amount, _id: i[4]._id},
                        zone6: {zone: i[5].amount, _id: i[5]._id},
                        zone7: {zone: i[6].amount, _id: i[6]._id},
                        zone8: {zone: i[7] && i[7].amount, _id: i[7] && i[7]._id},
                        zone9: {zone: i[8] && i[8].amount, _id: i[8] && i[8]._id},
                        zone10: {zone: i[9] && i[9].amount, _id: i[9] && i[9]._id},
                        zone11: {zone: i[10] && i[10].amount, _id: i[10] && i[10]._id},
                    }


                    array.push(abc)

                })

                array = _.sortBy(array, ['weight'], ['asc']);

                this.setState({array})
                dispatch(hideLoader())

            }
        })
    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)


    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {} = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Provider',
                    key: 'serviceName',
                    required: true,
                    type: 'select',
                    // disabled: true,
                    options: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
                    onChange: (mode) => {
                        this.props.form.setFieldsValue({serviceName: mode})
                    }

                },
                {
                    label: 'Type',
                    key: 'type',
                    required: true,
                    // disabled: true,
                    type: 'select',
                    options: ['INBOUND', 'OUTBOUND'],
                    onChange: (mode) => {
                        this.props.form.setFieldsValue({type: mode})
                    }

                }


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const columns = [
            {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight'
            },

            {
                title: 'Column 1',
                dataIndex: 'zone1',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 2',
                dataIndex: 'zone2',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 3',
                dataIndex: 'zone3',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 4',
                dataIndex: 'zone4',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 5',
                dataIndex: 'zone5',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 6',
                dataIndex: 'zone6',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },
            {
                title: 'Column 7',
                dataIndex: 'zone7',
                render: (val, record) => {
                    return <div>{val && val.zone}</div>
                }
            },

        ];

        return (
            <PageHeaderWrapper
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark=
                        {false} style={{marginTop: 8}}>

                        <Row>
                            <Col>
                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputTypes}
                                                  formItemLayout={formItemLayout}
                                                  getFieldDecorator=
                                                      {getFieldDecorator}/>
                            </Col></Row>


                        <Form.Item style={{
                            marginTop: 32, textAlign:
                                'center'
                        }}>
                            <Button size="large" type="primary"
                                    htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                SUBMIT
                            </Button>
                        </Form.Item>

                    </Form>


                    <TableComp ref={this.table}
                               columns={columns}
                               dataSource={this.state.array}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 20,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>


                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PriceList)
