export default {}

export const pageTitle = 'Easyship'
export const subTitle = 'Powered by Scizers'


//let mainURL = 'http://localhost:8040'
let mainURL = 'https://api.easyship.ma'
if (process.env.NODE_ENV == 'production') {
  mainURL = 'https://api.easyship.ma'
}

export const apiUrl = mainURL

