import React, {Component} from 'react'

import {push} from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import Dashboard from './containers/dashboard'
import Undercons from './containers/undercons'
import AddCountry from './containers/country/add'
import AllCountry from './containers/country/all'
import AddZone from './containers/zones/add'
import AllZones from './containers/zones/all'
import AddCity from './containers/city/add'
import AllCity from './containers/city/all'
import AddPrice from './containers/price/add'
import AllPrice from './containers/price/all'
import AllPriceList from './containers/price/all/allPricesList'
import AllMargin from './containers/margin/all'
import AllQuote from './containers/quotes/all'
import AllUser from './containers/users/all'
import AllAffiliateUser from './containers/users/allAffiliateUser'

import UpdateUser from './containers/users/update'
import AllTransit from './containers/transit/all'
import AddFuelPrice from './containers/fuelSurcharge/add'

import FuelChargeList from './containers/fuelSurcharge/all'

import QuoteDetails from './containers/quotes/all/quoteDetailPage'
import OrderList from './containers/order/all'
import OrderDetails from './containers/order/all/orderDetails'
import PaidPayments from './containers/payments/all'
import CustomerSummary from './containers/Reports/all/customerSummaryReport'
import InvoiceList from './containers/Reports/all/invoiceList'
import AddUser from './containers/users/add'
import PendingShipment from './containers/order/all/pendingShipmentList'
import CancelShipment from './containers/order/all/cancelShipmentList'
import AddCoupon from './containers/coupons/add'
import FailedPayment from './containers/payments/all/failedPayments'
import CouponList from './containers/coupons/all'

import {FaCity, FaFontAwesomeFlag, FaFileInvoice} from 'react-icons/fa'
import {RiSecurePaymentLine, RiPercentLine, RiCouponLine} from 'react-icons/ri'
import {GiFuelTank} from 'react-icons/gi'
import {MdLocationCity} from 'react-icons/md'
import {AiOutlineShoppingCart, AiOutlineFlag} from 'react-icons/ai'
import {TiDocumentText, TiDocument} from 'react-icons/ti'

import DomesticOrderList from './containers/order/Domestic'
import DomesticPendingShipment from './containers/order/Domestic/pendingShipmentList'
import DomesticCancelShipment from './containers/order/Domestic/cancelShipmentList'

import AffiliateOrderList from './containers/affiliatedShipment/all'
import AffiliatePendingShipment from './containers/affiliatedShipment/all/pendingShipmentList'
import AffiliateCancelShipment from './containers/affiliatedShipment/all/cancelShipmentList'
import AffiliateDomesticOrderList from './containers/affiliatedShipment/Domestic'
import AffiliateDomesticPendingShipment from './containers/affiliatedShipment/Domestic/pendingShipmentList'
import AffiliateDomesticCancelShipment from './containers/affiliatedShipment/Domestic/cancelShipmentList'


const menu = [
    {
        'path': '/dashboard',
        'name': 'Dashboard',
        'icon': 'dashboard',
        'key': 'dashboard',
        'title': 'Dashboard',
        'homepage': true,
        'component': Dashboard,
        'authority': [
            'admin', 'manager'
        ]
    },
    {
        'path': '/shipments',
        'name': 'Expeditions',
        'key': 'shipments',
        'icon': <AiOutlineShoppingCart style={{marginRight: '10px'}}/>,
        'title': 'Expeditions',
        'authority': [
            'admin', 'manager',
        ],
        'children': [
            {
                'path': '/shipments/placed',
                'name': 'Expéditions Confirmées',
                'title': 'Expéditions Confirmées',
                'component': OrderList,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/shipments/pending',
                'name': 'Expéditions En cours',
                'title': 'Expéditions En cours',
                'component': PendingShipment,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/shipments/canceled',
                'name': 'Expéditions Annulées',
                'title': 'Expéditions Annulées',
                'component': CancelShipment,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/shipments/domestic/placed',
                'name': 'Nationales confirmées',
                'title': 'Nationales confirmées',
                'component': DomesticOrderList,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/shipments/domestic/pending',
                'name': 'Nationales en attente',
                'title': 'Nationales en attente',
                'component': DomesticPendingShipment,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/shipments/domestic/canceled',
                'name': 'Envois nationaux annulés',
                'title': 'Envois nationaux annulés',
                'component': DomesticCancelShipment,
                'authority': [
                    'admin', 'manager'
                ]
            },

        ]

    },
    {
        'path': '/shipmentDetails',
        'name': 'Shipment Details',
        'icon': 'dashboard',
        'key': 'shipmentDetails',
        'title': 'Shipment Details',
        dontShowOnMenu: true,
        'component': OrderDetails,
        'authority': [
            'admin', 'manager'
        ]
    },
    {
        'path': '/orderPayments',
        'name': 'Paiements',
        'icon': <RiSecurePaymentLine style={{marginRight: '10px'}}/>,
        'key': 'payments',
        'title': 'Paiements',
        'authority': [
            'admin', 'manager'
        ],
        'children': [
            {
                'path': '/orderPayments/paid',
                'name': 'Paiements Payés',
                'title': 'Paiements Payés',
                'component': PaidPayments,
                'authority': [
                    'admin', 'manager'
                ]
            },
            // {
            //     'path': '/orderPayments/pending',
            //     'name': 'Pending Payments',
            //     'title': 'Pending Payments',
            //     'component': PendingPayment,
            //     'authority': [
            //         'admin', 'manager'
            //     ]
            // },
            {
                'path': '/orderPayments/failed',
                'name': 'Paiements échoués',
                'title': 'Paiements échoués',
                'component': FailedPayment,
                'authority': [
                    'admin', 'manager'
                ]
            },


        ]

    },
    {
        'path': '/quote/all',
        'name': 'Devis',
        'icon': 'table',
        'key': 'quote',
        'title': 'Devis',
        'component': AllQuote,
        'authority': [
            'admin', 'manager'
        ]
    },
    {
        'path': '/quoteDetails',
        'name': 'Quote',
        'icon': 'dashboard',
        'key': 'quote',
        'title': 'Quotes',
        dontShowOnMenu: true,
        'component': QuoteDetails,
        'authority': [
            'admin', 'manager'
        ]
    },


    {
        'path': '/reports',
        'name': 'Reports',
        'icon': <TiDocumentText style={{marginRight: '10px'}}/>,
        'key': 'reports',
        'authority': [
            'admin', 'manager'
        ],
        'children': [
            {
                'path': '/reports/customerSummaryReport',
                'name': 'Customer Summary Report',
                'title': 'Customer Summary Report',
                'key': 'customerSummaryReport',
                'component': CustomerSummary,
                'authority': ['admin', 'manager'],
                // 'icon': <TiDocumentText style={{marginRight: '10px'}}/>
            },
            {
                'path': '/reports/invoiceDetail',
                'name': 'Invoice',
                'title': 'Invoice',
                'key': 'invoice',
                'component': InvoiceList,
                // 'icon': <FaFileInvoice style={{marginRight: '10px'}}/>,
                'authority': ['admin', 'manager']
            }

        ]
    },
    {
        path: '/settings',
        icon: 'database',
        name: 'System Settings',
        key: 'settings',
        authority: ['admin', 'manager'],
        children: [
            {
                'path': '/settings/zone',
                'name': 'Zones',
                'icon': <MdLocationCity style={{marginRight: '10px'}}/>,
                'key': 'zones',
                'authority': [
                    'admin', 'manager'
                ],
                'children': [
                    {
                        'path': '/settings/zone/add',
                        'name': 'Add Zone',
                        'title': 'Add Zone',
                        'component': AddZone,
                        'authority': [
                            'admin', 'manager'
                        ]
                    },
                    {
                        'path': '/settings/zone/all',
                        'name': 'All Zone',
                        'title': 'All Zone',
                        'key': 'allZone',
                        'component': AllZones,
                        'authority': [
                            'admin', 'manager']
                    },
                    {
                        'path': '/settings/zone/edit-zone',
                        'name': 'Update Zone',
                        'title': 'Update Zone',
                        'key': 'editZone',
                        dontShowOnMenu: true,
                        'component': AddZone,
                        'authority': [
                            'admin', 'manager']
                    }
                ]
            },


            {
                'path': '/settings/country',
                'name': 'Country',
                'icon': <AiOutlineFlag style={{marginRight: '10px'}}/>,
                'key': 'country',
                'authority': [
                    'admin', 'manager'
                ],
                'children': [
                    {
                        'path': '/settings/country/add',
                        'name': 'Add Country',
                        'title': 'Add Country',
                        'component': AddCountry,
                        'authority': [
                            'admin', 'manager'
                        ]
                    },
                    {
                        'path': '/settings/country/all',
                        'name': 'All Country',
                        'title': 'All Country',
                        'key': 'allCountry',
                        'component': AllCountry,
                        'authority': [
                            'admin', 'manager']
                    },
                    {
                        'path': '/settings/country/edit-country',
                        'name': 'Update Country',
                        'title': 'Update Country',
                        'key': 'editCountry',
                        dontShowOnMenu: true,
                        'component': AddCountry,
                        'authority': [
                            'admin', 'manager']
                    }
                ]
            },

            {
                'path': '/settings/city',
                'name': 'City',
                'icon': <FaCity style={{marginRight: '10px'}}/>,
                'key': 'city',
                'authority': [
                    'admin', 'manager'
                ],
                'children': [
                    {
                        'path': '/settings/city/add',
                        'name': 'Add City',
                        'title': 'Add City',
                        'component': AddCity,
                        'authority': [
                            'admin', 'manager'
                        ]
                    },
                    {
                        'path': '/settings/city/all',
                        'name': 'All City',
                        'title': 'All City',
                        'key': 'allCity',
                        'component': AllCity,
                        'authority': [
                            'admin', 'manager']
                    },
                    {
                        'path': '/settings/city/edit-city',
                        'name': 'Update City',
                        'title': 'Update City',
                        'key': 'editCity',
                        dontShowOnMenu: true,
                        'component': AddCity,
                        'authority': [
                            'admin', 'manager']
                    }
                ]
            },

            {
                'path': '/settings/fuelCharge',
                'name': 'Fuel & Vat Charge',
                'icon': <GiFuelTank style={{marginRight: '10px'}}/>,
                'key': 'fuelCharge',
                'authority': [
                    'admin', 'manager'
                ],
                'children': [
                    {
                        'path': '/settings/fuelCharge/add',
                        'name': 'Add Fuel & Vat Charge',
                        'title': 'Add Fuel Charge',
                        'component': AddFuelPrice,
                        'authority': [
                            'admin', 'manager'
                        ]
                    },
                    {
                        'path': '/settings/fuelCharge/all',
                        'name': 'All Fuels & Vat Charge',
                        'title': 'All Fuels Charge',
                        'key': 'fuelList',
                        'component': FuelChargeList,
                        'authority': [
                            'admin', 'manager']
                    },
                    {
                        'path': '/settings/fuelCharge/edit-fuel',
                        'name': 'Update Fuel Price',
                        'title': 'Update Fuel Price',
                        'key': 'editFuel',
                        dontShowOnMenu: true,
                        'component': AddFuelPrice,
                        'authority': [
                            'admin', 'manager']
                    },

                ]
            },


        ]
    },


    {
        'path': '/User',
        'name': 'User',
        'icon': 'user',
        'key': 'user',
        'authority': [
            'admin'
        ],
        'children': [
            {
                'path': '/user/add',
                'name': 'Add User',
                'title': 'Add User',
                'key': 'addUser',
                'component': AddUser,
                'authority': [
                    'admin']
            },

            {
                'path': '/user/all',
                'name': 'All User',
                'title': 'All User',
                'key': 'allUser',
                'component': AllUser,
                'authority': ['admin']
            },
            {
                'path': '/user/Affiliate/all',
                'name': 'All Affiliate User',
                'title': 'All Affiliate User',
                'key': 'allAffiliateUser',
                'component': AllAffiliateUser,
                'authority': ['admin']
            },
            {
                'path': '/user/edit-user',
                'name': 'Update User',
                'title': 'Update User',
                'key': 'editUser',
                dontShowOnMenu: true,
                'component': UpdateUser,
                'authority': [
                    'admin']
            }
        ]
    },


    {
        'path': '/price',
        'name': 'Price',
        'icon': 'money-collect',
        'key': 'price',
        'authority': [
            'admin', 'manager'
        ],
        'children': [
            {
                'path': '/price/add',
                'name': 'Add Price',
                'title': 'Add Price',
                'component': AddPrice,
                'authority': [
                    'admin', 'manager'
                ]
            },

            // {
            //     'path': '/price/all',
            //     'name': 'All Price',
            //     'title': 'All Price',
            //     'key': 'allPrice',
            //     'component': AllPrice,
            //     'authority': [
            //         'admin']
            // },
            {
                'path': '/price/all',
                'name': 'All Price',
                'title': 'All Price',
                'key': 'allPriceList',
                'component': AllPriceList,
                'authority': [
                    'admin']
            },
            {
                'path': '/price/edit-price',
                'name': 'Update Price',
                'title': 'Update Price',
                'key': 'editPrice',
                dontShowOnMenu: true,
                'component': AddPrice,
                'authority': [
                    'admin']
            }

        ]
    },

    {
        'path': '/margin/all',
        'name': 'Margins and Settings',
        'icon': <RiPercentLine style={{marginRight: '10px'}}/>,
        'key': 'margin',
        'title': 'Margins',
        'component': AllMargin,
        'authority': [
            'admin', 'manager'
        ]
    },

    {
        'path': '/transit/all',
        'name': 'Transit Time',
        'icon': 'clock-circle',
        'key': 'transit',
        'title': 'Transit',
        'component': AllTransit,
        'authority': [
            'admin', 'manager'
        ]
    },
    {
        'path': '/coupon',
        'name': 'Coupon',
        'icon': <RiCouponLine style={{marginRight: '10px'}}/>,
        'key': 'coupon',
        'authority': [
            'admin', 'manager'
        ],
        'children': [
            {
                'path': '/coupon/add',
                'name': 'Add Coupon',
                'title': 'Add Coupon',
                'component': AddCoupon,
                'authority': [
                    'admin', 'manager'
                ]
            },
            {
                'path': '/coupon/all',
                'name': 'All Coupons',
                'title': 'All Coupons',
                'key': 'coupons',
                'component': CouponList,
                'authority': [
                    'admin','manager']
            },
            {
                'path': '/coupon/edit-coupon',
                'name': 'Update Coupon',
                'title': 'Update Coupon',
                'key': 'editCoupon',
                dontShowOnMenu: true,
                'component': AddCoupon,
                'authority': [
                    'admin','manager']
            }


        ]
    },
    {
        'path': '/affiliate/shipments',
        'name': 'Expeditions',
        'key': 'shipments',
        'icon': <AiOutlineShoppingCart style={{marginRight: '10px'}}/>,
        'title': 'Expeditions',
        'authority': [
           'affiliate'
        ],
        'children': [
            {
                'path': '/affiliate/shipments/placed',
                'name': 'Expéditions Confirmées',
                'title': 'Expéditions Confirmées',
                'component': AffiliateOrderList,
                'authority': [
                    'affiliate'
                ]
            },
            {
                'path': '/affiliate/shipments/pending',
                'name': 'Expéditions En cours',
                'title': 'Expéditions En cours',
                'component': AffiliatePendingShipment,
                'authority': [
                   'affiliate'
                ]
            },
            {
                'path': '/affiliate/shipments/canceled',
                'name': 'Expéditions Annulées',
                'title': 'Expéditions Annulées',
                'component': AffiliateCancelShipment,
                'authority': [
                   'affiliate'
                ]
            },
            {
                'path': '/affiliate/shipments/domestic/placed',
                'name': 'Nationales confirmées',
                'title': 'Nationales confirmées',
                'component': AffiliateDomesticOrderList,
                'authority': [
                    'affiliate'
                ]
            },
            {
                'path': '/affiliate/shipments/domestic/pending',
                'name': 'Nationales en attente',
                'title': 'Nationales en attente',
                'component': AffiliateDomesticPendingShipment,
                'authority': [
                   'affiliate'
                ]
            },
            {
                'path': '/affiliate/shipments/domestic/canceled',
                'name': 'Envois nationaux annulés',
                'title': 'Envois nationaux annulés',
                'component': AffiliateDomesticCancelShipment,
                'authority': [
                   'affiliate'
                ]
            },

        ]

    },

]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
    return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

    if (!params) params = {}

    let keyArr = keyString.split('.')
    let val = _.find(menu, p => p.key === keyArr[0])
    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }
    if (keyArr.length === 3) {
        val = _.find(val.children, p => p.key === keyArr[1])
        val = _.find(val.children, p => p.key === keyArr[2])
    }

    if (!val) {
        return `/`
    }
    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

    return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return push(path.build(params))
    }

    return 'error'
}

export const getUrlParams = (keyString, route) => {

    let obj = getUrlObject(keyString)

    if (obj) {
        const path = new Path(obj.path)

        return path.test(route)
    }

    return {error: true}
}

export const getUrlObject = (keyString) => {

    let keyArr = keyString.split('.')

    let val = _.find(menu, p => p.key === keyArr[0])

    if (!val) {
        return `/`
    }

    if (keyArr.length === 2) {
        val = _.find(val.children, p => p.key === keyArr[1])
    }

    if (!val) {
        return `/`
    }

    return val
}
//</editor-fold>

export default menu

