import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import ChangePassword from '../../../components/changePassword'

import { TableComp } from 'sz-react-utils-lite'
import {hideLoader, showLoader} from '../../../modules/actions'

import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Tag,
    Switch,
    Input, Button, Icon,
    Row, Col
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'

class AllUser extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.userType = ['affiliate']
            let data = await Request.getAllUser({
                ...params, regExFilters: ['email', 'fullName'],

            })
            this.setState({ count: data.total })
            console.log(data)
            resolve(data)
        })
    }

    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }

    componentDidMount() {
    }
    disabledUser = async (value,data) => {
        const {dispatch, form} = this.props     
        console.log(value,"activeactiveactiveactiveactiveactive")
        dispatch(showLoader())
        data.active = value
        let x = await Request.updateUserForDisabled(data)
        dispatch(hideLoader())
        if (!x.error) {
            notification.success({
                message: 'Updated Successfully'
            })
        } else {
            notification.error({
                message: 'Error Saving',
                description: x.msg
            })
        }
        this.table.current.reload()
    }
    render() {
        const { submitting, dispatch, currentUser } = this.props
        const { changePasswordRecord } = this.state


        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                //  width: 150
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName',
                key: 'fullName',
                searchTextName: 'fullName',
                //width: 150
            },
            {
                title: 'Active',
                dataIndex: 'active',
                key: 'active',
                render: (val,record) => {
                    return <Tag color={val==true?'green':'red'}>
                        {val?'Yes':'No'}
                    </Tag>
                }
            },
            {
                title: 'Created On',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (val) => {
                    return (val && <Tag>
                        {val && moment(val).format('L')}
                    </Tag>)
                }
            },


            {
                key: 'actions',
                title: 'Action',
                // width: 100,
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title="Enable/Disable User">
                        <Switch defaultChecked={record.active} size="small" onChange={(value)=>this.disabledUser(value,record)} />
                        </Tooltip>

                        <Tooltip title="Change Password">

                            <Button style={{ marginRight: 6,marginLeft:10 }}
                                shape="circle"
                                size="small"
                                icon={'unlock'}
                                onClick={() => {
                                    this.setState({ changePasswordRecord: record }, () => {
                                        this.showDrawer()
                                    })


                                }}>

                            </Button>

                        </Tooltip>

                    </React.Fragment>
                }
            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Affiliates Users: ${this.state.count}` : `All User: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                        columns={columns}
                        apiRequest={this.apiRequest}
                        pagination={{
                            ...this.state.pagination,
                            defaultPageSize: 10,
                            pageSizeOptions: ['10', '25', '50', '100'],
                            showSizeChanger: true,
                            ...this.props.pagination
                        }} />


                    <Drawer
                        title="Change Password"
                        forceRender={true}
                        placement="right"
                        closable={true}
                        key={this.state.key}
                        onClose={() => {

                            this.setState({
                                visible: false
                            })
                        }}
                        visible={this.state.visible}
                        width={800}>
                        <Card bordered={true}>
                            <Row>
                                <Col span={12}>
                                    <b>Name : </b>{changePasswordRecord ? changePasswordRecord.fullName : null}
                                </Col>
                                <Col span={12}>
                                    <b>Email : </b>{changePasswordRecord ? changePasswordRecord.email : null}
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false} style={{ marginTop: 10 }}>
                            <ChangePassword handleSubmit={async values => {
                                console.log(values, "nsdnfjfnrjfn")
                                this.setState({ visible: false })


                            }} email={changePasswordRecord && changePasswordRecord.email} />
                        </Card>


                    </Drawer>

                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUser)
