import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Button,
    DatePicker,
    Radio,
    Row,
    Col,
    Select,
    Card,
    notification
} from 'antd'
import {AiOutlineSwap, AiOutlineDelete, AiOutlinePlusCircle} from 'react-icons/ai'
import {RightOutlined} from '@ant-design/icons'
import Request from '../../request'
import moment from 'moment/moment'
import async from 'async'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../request'
import {hideLoader, showLoader} from '../../modules/actions'

const Option = Select.Option

@Form.create()
class ChangePassword extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false

        }


    }


    handleSubmit = async (e) => {
        const {dispatch, form, handleSubmit, userId} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {

                let values = _.clone(valData)
                const {dispatch, email} = this.props
                console.log(email, "FFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF")
                this.setState({loading: true})
                if (values.password !== values.confirmPassword) {
                    this.setState({loading: false})

                    notification.error({
                        message: 'Password does not match.'
                    })
                }
                else {
                    let x = await Request.changePasswordByAdmin({
                        email: email,
                        password: values.password
                    })


                    if (x.error) {
                        this.setState({loading: false})

                        notification.error({
                            message: x.message
                        })
                    } else {
                        this.setState({loading: false})

                        handleSubmit(x.success)

                        notification.success({
                            message: x.message
                        })

                        form.resetFields()

                    }


                }

            }
        })
    }


    async componentDidMount() {

    }


    render() {

        const {} = this.props
        let {} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        let inputTypes = {
            fields: [
                {
                    label: 'New Password',
                    key: 'password',
                    type: 'password',
                    required: true
                },
                {
                    label: 'Confirm Password',
                    key: 'confirmPassword',
                    type: 'password',
                    required: true
                }
            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        return (<Card bordered={true}>
            <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                <Row>
                    <Col>
                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>
                    </Col></Row>


                <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                    <Button size="large" type="primary" htmlType="submit" loading={this.state.loading}
                            style={{width: '15%'}}>
                        SAVE
                    </Button>
                </Form.Item>

            </Form>
        </Card>)


    }
}

export default ChangePassword
