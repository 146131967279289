import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row,
    Col,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class Price extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: ''
        }

    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updatePrice(values)
                } else {
                    console.log(values)
                    x = await Request.addPrice(values)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    if (!this.state.id) {
                        this.props.form.resetFields()

                    }


                    notification.success({
                        message: this.state.id
                            ? x.msg
                            : x.msg
                    })

                    if (this.state.id) {
                        dispatch(goBack())

                    }
                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let priceId = searchParams.get('id')
        if (priceId) {
            Request.getPriceById({_id: priceId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: priceId
                    })

                    this.props.form.setFieldsValue({
                        serviceName: data && data.serviceName,
                        zone: data && data.zone,
                        amount: data && data.amount,
                        type: data && data.type,
                        weight: data && data.weight

                    })
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }

    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {} = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Provider',
                    key: 'serviceName',
                    required: true,
                    type: 'select',
                    // disabled: true,
                    options: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
                    onChange: (mode) => {
                        this.props.form.setFieldsValue({serviceName: mode})
                    }

                },
                {
                    label: 'Type',
                    key: 'type',
                    required: true,
                   // disabled: true,
                    type: 'select',
                    options: ['INBOUND', 'OUTBOUND'],
                    onChange: (mode) => {
                        this.props.form.setFieldsValue({type: mode})
                    }

                },

                {
                    label: 'Upload CSV',
                    key: 'csvFile',
                    type: "file",
                    required: true,
                    // disabled: true

                }


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Price' : 'Add Price'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Row>
                            <Col>
                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputTypes} formItemLayout={formItemLayout}
                                                  getFieldDecorator={getFieldDecorator}/>
                            </Col></Row>


                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                {this.state.id ? `UPDATE` : `SAVE`}
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Price)
