import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Row,
    Col,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Select,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'

//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'

const {Option} = Select;


class CustomerSummaryReport extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
        }

    }

    exportData = async () => {

        let data = await Request.customerSummaryReport({
            ...this.state.filters, export: true
        })
        if (data) {
            window.open(data.url)

        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.quoteStatus = "Placed"


            if (this.state.userId) {
                params.userId = this.state.userId
            }
            let data = await Request.customerSummaryReport({
                ...params

            })

            console.log(data)
            this.setState({
                filters: {
                    ...params
                }
            })
            this.setState({count: data.length})
            resolve({data: data})
        })
    }


    async componentDidMount() {

        let {data} = await Request.getAllUser({results: 1000000000000})
        this.setState({userList: data})
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {packageType, shipmentResponse, userList} = this.state


        const columns = [

            {
                title: 'Customer Name',
                key: 'userName',
                dataIndex: 'userName',
                width: 100,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.userName}</div>
                        <div>{record && record.email}</div>
                    </div>
                }


            },
            {
                title: 'Customer Revenue',
                key: 'customerRevenue',
                dataIndex: 'netAmount',
                width: 100,
                sorter: true,

            },


            {
                title: 'Carrier Cost',
                key: 'carrierAmount',
                width: 100,
                render: (val, record) => {
                    return <div>{record && record.carrierAmount ? record.carrierAmount.toFixed(2) : ''}</div>
                }
            },

            {
                title: 'Total Packages',
                key: 'totalShipment',
                width: 100,
                dataIndex: 'totalPackages'
            },
            {
                title: 'Total Shipment',
                key: 'totalQuotes',
                width: 100,
                dataIndex: 'totalQuotes'
            },
            {
                title: 'Avg Margin/Shipment',
                key: 'avgMargin',
                sorter: true,
                width: 100,
                render: (val, record) => {
                    return <div>{record && record.avgMargin ? record.avgMargin.toFixed(2) : ''}</div>

                }

            },
            {
                title: 'Avg Margin %',
                key: 'avgPercentage',
                sorter: true,
                width: 100,
                render: (val, record) => {
                    return <div>{record && record.avgPercentage ? record.avgPercentage.toFixed(2) : ''}</div>

                }

            },


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Customer Summary: ${this.state.count}` : `Customer Summary: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Customer</h4>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                allowClear
                                placeholder="Select Customer"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.shippingAddress && val.shippingAddress.fullName} ({val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                            <Button
                                style={{marginBottom: '10px', marginLeft: '10px'}}
                                type="default"
                                onClick={() => {
                                    this.exportData()
                                }}
                                loading={this.props.loading}>
                                Export Excel
                            </Button>
                        </Col>


                    </Row>


                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerSummaryReport)
