import React, {PureComponent} from 'react'

import DHL_LOGO from '../../assets/logos/dhl-logo.png'
import FEDEX_LOGO from '../../assets/logos/fedex-logo.png'
import UPS_LOGO from '../../assets/logos/ups-logo.png'
import ARAMEX_LOGO from '../../assets/logos/aramex-logo.png'

export default class ICON extends PureComponent {

    render() {

        const {type, className} = this.props
        let logo = null

        if (type.toLowerCase() == 'dhl') {
            logo = DHL_LOGO
        } else if (type.toLowerCase() == 'fedex') {
            logo = FEDEX_LOGO
        } else if (type.toLowerCase() == 'aramex') {
            logo = ARAMEX_LOGO
        } else {
            logo = UPS_LOGO
        }

        return <>
            <img src={logo} className={className}/>
        </>

    }

}


