import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row,
    Col,
    Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { getUrlPushWrapper } from '../../../routes'

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddCoupon extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: ''
        }

    }

    handleSubmit = e => {
        const { dispatch, form, handleSubmit } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                values.status = values.status == true ? 'Active' : 'Inactive'

                let startDate = moment(values.startDate).format('DD-MM-YYYY')
                let endDate = moment(values.expiry).format('DD-MM-YYYY')

                if (moment(endDate, 'DD-MM-YYYY').isSame(moment(startDate, 'DD-MM-YYYY')) || moment(endDate, 'DD-MM-YYYY').isBefore(moment(startDate, 'DD-MM-YYYY'))) {
                    return notification.error({
                        message: 'Start Date should be less than Expiry Date'
                    })

                }
                let x = null
                dispatch(showLoader())
                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateCoupon(values)
                } else {
                    x = await Request.addCoupon(values)
                }

                dispatch(hideLoader())
                console.log(x, "sjdnfjdnfjdfnjfnjrfnrej")

                if (!x.error) {
                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.props.form.setFieldsValue({
                            paymentMethod: ['Chèque', 'Espèce', 'Western Union', 'Wafacash', 'Virement / Versement Bancaire', 'Carte Bancaire'],


                        })

                    }


                    notification.success({
                        message: this.state.id
                            ? x.msg
                            : x.msg
                    })

                    if (this.state.id) {
                        dispatch(goBack())

                    }
                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }
    apiRequest = () => {
        return new Promise(async (resolve) => {
            let params = {}
            params.userType = ['affiliate']
            params.active = true
            let data = await Request.getAllUser({
                ...params, regExFilters: ['email', 'fullName'],

            })
            this.setState({ allUsers: data && data.data })
        })
    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let couponId = searchParams.get('id')
        this.apiRequest()
        if (couponId) {
            Request.getCouponById({ _id: couponId }).then(({ data, error, message }) => {
                if (!error) {
                    this.setState({
                        id: couponId
                    })

                    this.props.form.setFieldsValue({
                        coupon: data && data.coupon,
                        couponName: data && data.couponName,
                        status: data && data.status == 'Active' ? true : false,
                        discountType: data && data.discountType,
                        discountAmount: data && data.discountAmount,
                        affiliatedUser: data && data.affiliatedUser,
                        paymentMethod: data && data.paymentMethod,
                        startDate: data && data.startDate && moment(data.startDate),
                        expiry: data && data.expiry && moment(data.expiry),
                        maximumUsage: data && data.maximumUsage,
                        description: data && data.description,

                    })
                    if(data && data.affiliatedUser){
                        this.props.form.setFieldsValue({
                            affiliatedUserCommission: data && data.affiliatedUserCommission,
                        })
                    }
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.props.form.setFieldsValue({
                paymentMethod: ['Chèque', 'Espèce', 'Western Union', 'Wafacash', 'Virement / Versement Bancaire', 'Carte Bancaire'],


            })
        }


    }

    render() {

        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props

        const { } = this.state


        const inputTypes = {
            fields: [

                {
                    label: 'Coupon Name',
                    key: 'couponName',
                    required: true
                },
                {
                    label: 'Coupon Code',
                    key: 'coupon',
                    required: true,
                    disabled: this.state.id ? true : false,
                    onChange: (coupon) => {
                        console.log(coupon, coupon.target.value)
                        this.props.form.setFieldsValue({ coupon: coupon.target.value.toUpperCase() })
                    }
                },
                {
                    label: 'Active',
                    key: 'status',
                    type: 'switch',
                    options: ['Active', 'Inactive'],
                    // required: true,
                    onChange: (status) => {
                        this.props.form.setFieldsValue({ status: status })
                    }
                },
                {
                    label: 'Discount Type',
                    key: 'discountType',
                    type: 'select',
                    options: ['Percentage', 'Flat'],
                    required: true,
                    onChange: (discountType) => {
                        this.props.form.setFieldsValue({ discountType: discountType })
                    }
                },

                {
                    label: 'Discount Amount',
                    key: 'discountAmount',
                    required: true,
                    type: 'number'

                },
                {
                    label: 'Description',
                    key: 'description',
                    required: true,
                    type: 'textarea',
                    rows: 5

                },


            ]
        }
        const inputTypes1 = {
            fields: [
                {
                    label: 'Payment Method',
                    key: 'paymentMethod',
                    type: 'select',
                    mode: 'multiple',
                    options: ['Chèque', 'Espèce', 'Western Union', 'Wafacash', 'Virement / Versement Bancaire', 'Carte Bancaire'],
                    required: true,
                    onChange: (paymentMethod) => {
                        this.props.form.setFieldsValue({ paymentMethod: paymentMethod })
                    }
                },

                {
                    label: 'Start Date',
                    key: 'startDate',
                    type: 'date',
                    required: true,


                },
                {
                    label: 'Expiry Date',
                    key: 'expiry',
                    type: 'date',
                    required: true,

                },


                {
                    label: 'Maximum Usage',
                    key: 'maximumUsage',
                    required: true,
                    type: 'number'

                },
                {
                    label: 'Affiliated User',
                    key: 'affiliatedUser',
                    type: 'select',
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.fullName}`,
                    options: this.state.allUsers || [],
                    onChange: v => {
                        this.props.form.setFieldsValue({ affiliatedUser: v })
                    }
                },

                {
                    label: 'Aff. User Commission(%)',
                    key: 'affiliatedUserCommission',
                    required:true,
                    hidden: this.props.form.getFieldValue('affiliatedUser')?false:true,
                    type: 'number'

                },


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Coupon' : 'Add Coupon'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{ marginTop: 8 }}>

                        <Row>

                            <Col span={12}>
                                <GetAllFormFields apiurl={API_URL}
                                    inputSchema={inputTypes} formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator} />
                            </Col>
                            <Col span={12}>
                                <GetAllFormFields apiurl={API_URL}
                                    inputSchema={inputTypes1} formItemLayout={formItemLayout}
                                    getFieldDecorator={getFieldDecorator} />
                            </Col>
                        </Row>


                        <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                style={{ width: '15%' }}>
                                {this.state.id ? `UPDATE` : `SAVE`}
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCoupon)
