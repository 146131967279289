import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import Highlighter from 'react-highlight-words';

import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Row,
    Col,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Select,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request, {API_URL} from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'

//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

import {hideLoader, showLoader} from "../../../modules/actions";
import {Form} from "antd/lib/index";

const {Option} = Select;

@Form.create()


class InvoiceReport extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
        }

    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                console.log(values)
                let x = await Request.importCSV(values)


                dispatch(hideLoader())

                if (!x.error) {
                    if (!this.state.id) {
                        //  this.props.form.resetFields()

                    }
                    console.log(x, "eferfrfrefrfrefreferre")
                    if (x) {
                        this.setState({dataSource: x.data, count: x.data && x.data.length})

                    }

                    // notification.success({
                    //     message: this.state.id
                    //         ? x.msg
                    //         : x.msg
                    // })


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }


    showDrawer = (resp) => {
        this.setState({
            shipmentResponse: resp,
            visible: true
        })
    }

    exportData = async () => {


        let data = await Request.exportFile(this.state.dataSource || [])
        if (data) {
            window.open(data.url)

        }

    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };


    async componentDidMount() {

    }

    render() {
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props
        const {submitting, dispatch, currentUser} = this.props
        const {packageType, shipmentResponse, userList, dataSource} = this.state
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        const columns = [

            {
                title: 'Invoice Number',
                key: 'Invoice Number',
                dataIndex: 'Invoice Number',
                width: 100,
                ...this.getColumnSearchProps('Invoice Number'),


            },
            {
                title: 'Invoice Date',
                key: 'Invoice Date',
                dataIndex: 'Invoice Date',
                width: 100,
                render: (val, record) => {
                    return <div>{val ? moment(val, 'YYYYMMDD').format('YYYY-DD-MM') : ''}</div>
                }

            },
            {
                title: 'Billing Account Name',
                key: 'Billing Account Name',
                dataIndex: 'Billing Account Name',
                width: 100,
                // sorter: true,

            },
            {
                title: 'Amount With Vat',
                key: 'totalAmountWithVat',
                dataIndex: 'totalAmountWithVat',
                sorter: (a, b) => a.totalAmountWithVat - b.totalAmountWithVat,


            },
            {
                title: 'Amount Without Vat',
                key: 'totalAmountWithoutVat',
                dataIndex: 'totalAmountWithoutVat',
                sorter: (a, b) => a.totalAmountWithoutVat - b.totalAmountWithoutVat,


            },
            {
                title: 'Senders Name',
                key: 'Senders Name',
                dataIndex: 'Senders Name',


            },
            {
                title: 'Receivers Name',
                key: 'Receivers Name',
                dataIndex: 'Receivers Name',


            },


        ]
        const inputTypes = {
            fields: [


                {
                    label: 'Upload CSV',
                    key: 'csvFile',
                    type: "file",
                    required: true,
                    // disabled: true

                }


            ]
        }


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Invoices: ${this.state.count}` : `Invoices : 0`}>

                <Card bordered={true}>

                    {dataSource && dataSource.length > 0 && <Row>
                        <Col span={8}>

                            <Button
                                style={{marginBottom: 10}}
                                type="default"
                                onClick={() => {
                                    this.exportData()
                                }}
                                loading={this.props.loading}>
                                Export Excel
                            </Button>

                        </Col>


                    </Row>}


                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>
                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>
                        <Form.Item style={{marginTop: 32, textAlign: 'center', width: '79%'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}>
                                UPLOAD
                            </Button>
                        </Form.Item>
                    </Form>

                    {dataSource && dataSource.length > 0 &&
                    <Table size={'middle'} bordered className={'table'} dataSource={dataSource}
                           columns={columns}
                           pagination={true}
                           scroll={{x: 1000}}/>}

                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceReport)
