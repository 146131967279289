import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row,
    Col,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class AddZone extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: '',
            allCity: [],
            showDomestic: false
        }

    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                console.log(values, "dfgdfggt")
                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id
                    x = await Request.updateZone(values)
                } else {
                    console.log(values)
                    x = await Request.addZone(values)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.setState({showDomestic: false, allCity: [], typeSet: false})

                    }


                    notification.success({
                        message: this.state.id
                            ? x.msg
                            : x.msg
                    })

                    if (this.state.id) {
                        dispatch(goBack())

                    }
                    this.props.form.setFieldsValue({})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }
    showDomesticCities = async () => {
        let {typeSet, setCountry} = this.state
        console.log(setCountry, typeSet)

        if (typeSet && (setCountry && setCountry.length && setCountry.includes('MA'))) {
            let {data: allCity} = await Request.loadDomesticCities()
            console.log(allCity, "Fffkfmkfnrjrnjrg")
            this.setState({allCity, showDomestic: true})


        } else {
            this.setState({allCity: [], showDomestic: false})

        }

    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)


        let {data: allCountries} = await Request.loadCountries()

        console.log(allCountries)
        this.setState({allCountry: allCountries})
        let zoneId = searchParams.get('id')
        if (zoneId) {
            Request.getZoneById({_id: zoneId}).then(async ({data, error, message}) => {
                console.log(data)
                if (!error) {
                    this.setState({
                        id: zoneId
                    })
                    if (data.type === 'DOMESTIC') {
                        let {data: allCities} = await Request.loadDomesticCities()
                        this.setState({
                            allCity: allCities,
                            showDomestic: true,
                            setCountry: data && data.zoneCountries,
                            typeSet: true
                        })
                        this.props.form.setFieldsValue({zoneCities: data && data.zoneCities})
                    }

                    console.log(data)
                    this.props.form.setFieldsValue({
                        serviceName: data && data.serviceName,
                        zone: data && data.zone,
                        zoneCountries: data && data.zoneCountries,
                        type: data && data.type,

                    })
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }

    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {allCity, showDomestic} = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Provider',
                    key: 'serviceName',
                    required: true,
                    type: 'select',
                    options: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
                    onChange: (mode) => {

                        this.props.form.setFieldsValue({serviceName: mode})
                    }

                },
                {
                    label: 'Zone#',
                    key: 'zone',
                    required: true
                },
                {
                    label: 'Country',
                    type: 'select',
                    key: 'zoneCountries',
                    mode: 'multiple',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x.isoCode,
                    valueAccessor: x => `${x.countryName}(${x.isoCode})`,
                    options: this.state.allCountry || [],
                    onChange: country => {
                        this.setState({setCountry: country}, () => {
                            this.showDomesticCities()
                        })


                        this.props.form.setFieldsValue({
                            zoneCountries: country
                        })

                    }
                },

                {
                    label: 'Type',
                    key: 'type',
                    required: true,
                    type: 'select',
                    options: ['INBOUND', 'OUTBOUND', 'DOMESTIC'],
                    onChange: (mode) => {
                        if (mode === 'DOMESTIC') {
                            this.setState({typeSet: true}, () => {
                                this.showDomesticCities()
                            })
                        } else {
                            this.setState({typeSet: false}, () => {
                                this.showDomesticCities()
                            })


                        }


                        this.props.form.setFieldsValue({type: mode})
                    }

                },
                {
                    label: 'Domestic Cities',
                    type: 'select',
                    key: 'zoneCities',
                    mode: 'multiple',
                    required: true,
                    showSearch: true,
                    hidden: showDomestic ? false : true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.cityName}(${x.isoCode})`,
                    options: this.state.allCity || [],
                    onChange: city => {
                        console.log(city)
                        this.props.form.setFieldsValue({
                            zoneCities: city
                        })

                    }
                },

            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Zone' : 'Add Zone'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Row>
                            <Col>
                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputTypes} formItemLayout={formItemLayout}
                                                  getFieldDecorator={getFieldDecorator}/>
                            </Col></Row>


                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                {this.state.id ? `UPDATE` : `SAVE`}
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddZone)
