import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import async from 'async'
import Highlighter from 'react-highlight-words';

import {
    Table,
    Badge,
    Card,
    Tooltip,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon,
    Spin,
    Select,
    Row,
    Col
} from 'antd'

import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'

const {Option} = Select

class AllPayments extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            loading: true
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params['paymentStatus'] = ['Paid']
            if (this.state.userId) {
                params.userId = this.state.userId
            }
            let data = await Request.getAllQuote({
                ...params,regExFilters: ['paymentTransId']

            })

            this.setState({count: data.total})
            resolve(data)
        })
    }



    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({})
        this.setState({allUsers})
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {packageType, shipmentResponse, typeList, dataSource, allUsers} = this.state


        const columns = [
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                fixed: 'left',
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title={'View Shipment'}>
                            <a href={`/shipmentDetails?id=${record && record._id}`} target={'_blank'}>
                                <Button icon={'eye'} shape={'circle'}/>
                            </a>
                        </Tooltip>

                    </React.Fragment>
                }
            },


            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',


            },
            {
                title: 'Payment Status',
                dataIndex: 'paymentStatus',
                key: 'paymentStatus',
                render: (val, record) => {
                    return <Tag style={{marginRight: '10px'}}
                                color={"#87d068"}>{val}</Tag>
                }

            },
            {
                title: 'Transaction Id',
                dataIndex: 'paymentTransId',
                key: 'paymentTransId',
                searchTextName: 'paymentTransId',


            },
            {
                title: 'User Details',
                key: 'userId',
                //width: 200,
                dataIndex: 'userId',
                render: (val, record) => {
                    return <div>
                        <div>Name : {val && val.fullName}</div>
                        <div>Email : {val && val.email}</div>

                    </div>
                },
            },

            {
                title: 'Name',
                key: 'BillToName',
                render: (val, record) => {
                    return <div>{record && record.paymentResponse && record.paymentResponse.BillToName}
                    </div>
                }


            },

            {
                title: 'Payment Response',
                dataIndex: 'paymentResponse',
                key: 'paymentResponse',
                width: 150,
                render: (val, record) => {
                    return <React.Fragment>
                        <Tag
                            color={val && val.Response == 'Approved' ? "#87d068" : "#f50"}>{val && val.Response}</Tag>
                        <div
                            style={{marginTop: '10px'}}>{record && <>Payment Method : {record.paymentMode}</>}</div>
                        <small> Notes : {record && record.paymentNotes}</small>


                    </React.Fragment>


                }

            },
            {
                title: 'Date',
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                sorter: true,
                render: (val) => {
                    return <Tag>{val}</Tag>
                }
            },
            {
                title: 'Amount',
                dataIndex: 'paidAmount',
                sorter: true,
                key: 'paidAmount',
                render: (val, record) => {
                    return <div>{val && 'MAD ' + val}</div>
                }
            },


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Paiements Payés: ${this.state.count}` : `Paiements Payés: 0`}>

                <Card bordered={true}>
                    <Row>
                        <Col span={12} style={{marginBottom: '10px'}}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select User Name"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allUsers &&
                                allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{
                                   scroll: {x: 1400}
                               }}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>


                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllPayments)
