import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Button,
    DatePicker,
    Radio,
    Row,
    Col,
    Select,
    InputNumber,
    AutoComplete,
    Icon,
    Spin,
    notification,
    Modal
} from 'antd'
import styles from './styles.less'
import {AiOutlineSwap, AiOutlineDelete, AiOutlinePlusCircle} from 'react-icons/ai'
import {RightOutlined} from '@ant-design/icons'
import Request from '../../request'
import moment from 'moment/moment'
import async from 'async'
import _ from 'lodash'
import {timeOptions} from "./optionsDataFile";
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../request'

const Option = Select.Option

@Form.create()
class SchedulePickup extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false, typeList: ['INBOUND', 'OUTBOUND'],
            serviceName: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
            packageType: [{value: 'Parcel', text: 'Parcel'}, {value: 'Envelope', text: 'Document'}, {
                value: 'Pallet',
                text: 'Pallet'
            }],
        }


    }

    state = {
        form: {}
    }

    handleShowModal = () => {
        this.setState({showModal: true})
    }

    handleCancel = (reloadTable) => {
        let {handleCancel} = this.props
        this.props.form.resetFields()
        this.setState({
            btnLoading: false
        });
        handleCancel(reloadTable)

    };
    timeValidator = (rule, val, callback) => {
        const {t} = this.props
        console.log(this.props.form.getFieldValue('openTime'))
        if (val == undefined) {
            callback(' ')

        } else {

            let openTime = this.props.form.getFieldValue('openTime')
            let closeTime = this.props.form.getFieldValue('closeTime')
            if (closeTime && openTime) {
                openTime = moment(openTime, "h:mma")
                closeTime = moment(closeTime, "h:mma")

                if (!openTime.isBefore(closeTime)) {
                    callback('Open Time should be less than Close Time')

                } else {
                    callback()

                }
            }

        }


    }
    handleSubmit = async (e) => {
        const {dispatch, form, handleSubmit} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                const {t, dispatch, quoteId} = this.props


                this.setState({btnLoading: true})

                let x = await Request.createPickup({...values, _id: quoteId})
                this.setState({btnLoading: false})

                if (x.success) {
                    notification.success({
                        message: 'Pickup Created Successfully'
                    })
                    this.handleCancel(true)
                    this.props.form.resetFields()

                } else {
                    notification.error({
                        message: x.msg ? x.msg : 'Error Saving'
                    })
                }

            }
        })
    }
    onFinishFailed = async errorInfo => {
        const {t} = this.props
        return notification.error({
            message: t('Please Fill All Required Fields')
        })

    }
    getTimeOptions = () => {
        let afterDate = this.props.form.getFieldValue('pickupDate')
        let timeOptionsArr = []
        if (afterDate && afterDate.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
            async.forEach(timeOptions, (doc, cb) => {

                let compareTime = moment(doc.time, ["h:mma"])

                if (moment().add("hour", 1).isBefore(compareTime)) {
                    timeOptionsArr.push({
                        value: doc.value,
                        time: doc.time
                    })
                }
                cb()
            }, () => {
                this.setState({
                    timeOptionsArr
                })
            })
        } else {
            this.setState({
                timeOptionsArr: timeOptions
            })
        }
    }


    async componentDidMount() {
        this.getTimeOptions()

    }


    render() {

        const {t, showModal, handleCancel, quoteDetails} = this.props
        let {btnLoading, timeOptionsArr} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        return <div className={"container"}>
            <Modal
                forceRender={true}
                title={'Add Pickup'}
                visible={showModal}
                footer={null}
                okButtonProps={{
                    loading: btnLoading
                }}
                onCancel={this.handleCancel}
            >
                {quoteDetails && <>
                    <div style={{marginBottom: '20px'}}>
                        <span style={{display: 'flex'}}><span>Order ID : </span>{quoteDetails.orderId}</span>
                        <span>{quoteDetails && quoteDetails.pickupLocation && quoteDetails.pickupLocation.cityName} {quoteDetails && quoteDetails.pickupLocation && quoteDetails.pickupLocation.zipCode}
                                    </span>
                        <span style={{marginLeft: '15px', marginRight: '15px'}}> <Icon style={{fontSize: '15px'}}
                                                                                       type="arrow-right"/>

                                    </span>
                        <span
                            style={{marginBottom: '15px'}}> {quoteDetails && quoteDetails.packageDestination && quoteDetails.packageDestination.cityName} {quoteDetails && quoteDetails.packageDestination && quoteDetails.packageDestination.zipCode}
                        </span>
                    </div>
                </>}

                <Form
                    onSubmit={this.handleSubmit} className="login-form"
                >

                    <Form.Item
                        label={'Pickup Date'}
                        rules={[{required: true, message: ' '}]}
                        name="pickupDate">
                        {getFieldDecorator('pickupDate', {
                            rules: [{required: true, message: 'Please input your pickupDate!'}],
                        })(
                            <DatePicker placeholder={'Select date'}
                                        allowClear={false}
                                        disabledDate={d => !d || d.isBefore(moment().startOf('day')) || d.isAfter(moment().startOf('day').add(7, 'days'))}
                                        onChange={(data) => {
                                            this.props.form.setFieldsValue({
                                                pickupDate: data,
                                                openTime: '',
                                                closeTime: ''
                                            })
                                            this.getTimeOptions()

                                        }}
                            />
                        )}

                    </Form.Item>
                    <Form.Item name="openTime"
                               className={'timeselector'}
                               label={'Opening Time'}>
                        {getFieldDecorator('openTime', {
                            rules: [{
                                required: true,
                                // message: 'Please input your open time!',
                                validator: this.timeValidator,
                            }],
                        })(
                            <Select
                                optionFilterProp="children"
                                placeholder={'Opening Time'}
                                onChange={(data) => {
                                    this.props.form.setFieldsValue({openTime: data})
                                    this.props.form.validateFields(['closeTime'])

                                }}>

                                {timeOptionsArr && timeOptionsArr.map((val, idn) => {
                                    return (
                                        <Option key={idn}
                                                value={val.time}>{val.time}</Option>
                                    )
                                })}

                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item name="closeTime"
                               className={'timeselector'}
                               label={'Closing Time'}>
                        {getFieldDecorator('closeTime', {
                            rules: [{
                                required: true,
                                //  message: 'Please input your close Time!',
                                validator: this.timeValidator,
                            }],
                        })(
                            <Select
                                optionFilterProp="children"
                                placeholder={'Closing Time'}
                                onChange={(data) => {
                                    this.props.form.setFieldsValue({closeTime: data})
                                    this.props.form.validateFields(['openTime'])
                                }}
                            >
                                {timeOptionsArr && timeOptionsArr.map((val, idn) => {
                                    return (
                                        <Option key={idn}
                                                value={val.time}>{val.time}</Option>
                                    )
                                })}

                            </Select>)}
                    </Form.Item>
                    <Button htmlType="submit" type="primary" loading={btnLoading}>
                        SUBMIT
                    </Button>
                </Form>
            </Modal>
        </div>

    }
}

export default SchedulePickup
