import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Modal,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Divider,
    Input, Button, Icon, Select,
    Row, Col,
    AutoComplete,
    Spin
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'

import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'
import ScheduleForm from '../../../components/schedulePickup'
import DhlTrackingDetails from '../../../components/Tracking/dhlTrackingDetails'
import AramexTrackingDetails from '../../../components/Tracking/aramexTrackingDetails'
import UpsTrackingDetails from '../../../components/Tracking/upsTrackingDetails'

const {Option} = Select

class AllOrder extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            selectedRowKeys: [],
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver'],
            trackLoader: [],
            cancelLoader: [],
            downloadCommercial: [],
            trackingNumberSearch: ''

        }
        this.onChangeTrackingNumber = _.debounce(this.trackingSearch, 100, true)


    }

    trackingSearch = async (test) => {
        this.setState({trackingNumberSearch: test})
        this.table.current.reload()

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.quoteStatus = ['Placed']
            params.type='DOMESTIC'

            if (this.state.userId) {
                params.userId = this.state.userId
            }
            if (this.state.affiliateUserId) {
                params.affiliatedUser = this.state.affiliateUserId
            }
            if (this.state.trackingNumberSearch) {
                params['shipmentSanitizeResponse.trackingNumber'] = this.state.trackingNumberSearch
            }
            let data = await Request.getAllQuote({
                ...params, regExFilters: ['senderData.fullName', 'receiverData.fullName']

            })

            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = (resp) => {
        this.setState({
            shipmentResponse: resp,
            visible: true
        })
    }
    handleTrackingModal = async (trackingData, key) => {
        this.setLoaderForTrack(key, true)

        let data = await Request.trackPackage({
            shipmentType: trackingData.serviceProviderName,
            trackingNumber: trackingData.shipmentSanitizeResponse.trackingNumber[0]
        })


        this.setLoaderForTrack(key, false)

        if (data.success) {
            this.setState({
                packages: data.activity,
                data: data,
                showTrackingModal: true,
                shipmentType: trackingData.serviceProviderName
            })
        } else {
            notification.error({
                message: data.status
            })
        }
    }
    onTrackingClose = () => {
        this.setState({
            showTrackingModal: false
        })
    }
    setLoaderForTrack = (key, value) => {
        let {trackLoader} = this.state
        let newLoading = _.clone(trackLoader)
        newLoading[key] = value
        this.setState({trackLoader: newLoading})
    }
    setLoaderForCancel = (key, value) => {
        let {cancelLoader} = this.state
        let newLoading = _.clone(cancelLoader)
        newLoading[key] = value
        this.setState({cancelLoader: newLoading})
    }
    setLoaderForCommercial = (key, value) => {
        let {downloadCommercial} = this.state
        let newLoading = _.clone(downloadCommercial)
        newLoading[key] = value
        this.setState({downloadCommercial: newLoading})
    }

    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({})
        let {data: allAffiliateUsers} = await Request.loadUser({userType:'affiliate'})
        this.setState({allUsers,allAffiliateUsers})
    }


    handleShowModal = (quote) => {
        this.setState({showModal: true, quoteId: quote._id, quoteDetails: quote})

    }

    handleCancel = (reloadTable) => {
        this.setState({
            showModal: false,
            btnLoading: false,
        });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };
    voidShipment = async (id, key) => {
        this.setLoaderForCancel(key, true)

        let shipment = await Request.voidShipment({_id: id})
        this.setLoaderForCancel(key, false)

        if (shipment.error) {
            notification.error({
                message: shipment.message
            })
        } else {
            this.table.current.reload()

            notification.success({
                message: shipment.message
            })
        }
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {allAffiliateUsers,
            packageType, shipmentResponse, showTrackingModal, quoteDetails,
            packages, shipmentType, data, typeList, selectedRowKeys, serviceProviderName, showModal, allUsers, trackingList, showSpin
        } = this.state


        const columns = [
            {
                key: 'actions',
                title: 'Action',
                width: 180,
                fixed: 'left',
                render: (text, record, key) => {
                    return <React.Fragment>
                        <Tooltip title="View Details">
                            <a href={`/shipmentDetails?id=${record._id}`} target={'_blank'}>

                                <Button style={{marginRight: '5px'}} icon={'eye'} shape={'circle'}/>

                            </a>
                        </Tooltip>
                        <Tooltip title="View Shipment Response">
                            <Button style={{marginRight: '5px'}} icon={'read'} shape={'circle'} onClick={() => {
                                // this.setState({})
                                this.showDrawer(record.shipmentResponse)
                            }}/>

                        </Tooltip>
                        {record && record.quoteStatus !== 'Canceled' && <> <Tooltip title="Reschedule Pickup">
                            <Button style={{marginRight: '5px'}} icon={'api'} shape={'circle'} onClick={() => {
                                // this.setState({})
                                this.handleShowModal(record)
                            }}/>

                        </Tooltip>

                            <Tooltip title="Tracking">
                                <Button loading={this.state.trackLoader[key]} style={{marginRight: '5px'}} icon={'fork'}
                                        shape={'circle'} onClick={() => {
                                    // this.setState({})
                                    this.handleTrackingModal(record, key)
                                }}/>

                            </Tooltip>

                            <Tooltip title="Void Shipment">
                                <Popconfirm
                                    title="Are you sure Void Shipment"
                                    onConfirm={() => this.voidShipment(record._id, key)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button loading={this.state.cancelLoader[key]}
                                            style={{marginRight: '5px', marginTop: '5px'}} icon={'delete'}
                                            shape={'circle'}/>
                                </Popconfirm>

                            </Tooltip></>}

                        {record && record.shipmentObj && record.shipmentObj.shipmentReason !== 'documents' &&
                        <Tooltip title={'Download Commercial'}>

                            <Button loading={this.state.downloadCommercial[key]}
                                    style={{marginRight: '5px'}}
                                    icon={'file-pdf'}
                                    shape={'circle'}
                                    onClick={async () => {

                                        this.setLoaderForCommercial(key, true)
                                        let data = await Request.downLoadCommercial({_id: record && record._id})
                                        this.setLoaderForCommercial(key, false)


                                    }}>
                            </Button>

                        </Tooltip>}

                    </React.Fragment>
                }
            },
            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },
            {
                title: 'Status',
                key: 'quoteStatus',
                render: (val) => {
                    return <div><Tag style={{backgroundColor: '#52c41a', color: 'white'}}>{val}</Tag>
                    </div>

                }
            },

            {
                title: 'Payment Response',
                dataIndex: 'paymentResponse',
                key: 'paymentResponse',
                width: 150,
                render: (val, record) => {
                    return <React.Fragment>
                        <Tag
                            color={val && val.Response == 'Approved' ? "#87d068" : "#f50"}>{val && val.Response}</Tag>
                        <div
                            style={{marginTop: '10px'}}>{record && <>Payment Method : {record.paymentMode}</>}</div>
                        {record && record.paymentResponse && record.paymentResponse.notes &&
                        <>
                            <small> Notes : {record.paymentResponse.notes}</small>
                            <br/>
                            <small> Reference No : {record.paymentResponse.TransId}</small>
                            <br/>
                            <small> Payment Date
                                : {moment(record.paymentResponse.paymentDate).format('DD-MMM-YYYY')}</small>
                            <br/>
                            <small> Amount : {record.paymentResponse.amount}</small>

                        </>}

                    </React.Fragment>


                }

            },


            {
                title: 'Tracking Number',
                render: (val, record) => {

                    return _.map(record && record.shipmentSanitizeResponse && record.shipmentSanitizeResponse.trackingNumber, (iii) => {
                        return <div className={styles.trackingClass}><a><AiOutlineEye
                            style={{marginTop: '4px'}}/> {iii}</a></div>
                    })

                },
                key: 'shipmentSanitizeResponse.trackingNumber',

            },
            {
                title: 'Pickup Date',
                key: 'pickupInformation.pickupDate',
                sorter: true,
                //width: 150,
                render: (val, record) => {
                    let {pickUpStatus}=record
                    let {pickupDate, pickup, openTime, closeTime} = record && record.pickupInformation
                    return pickUpStatus == 'Created'&&<div>
                        {pickupDate && moment(pickupDate).format('DD-MMM-YYYY')}
                        <div>{closeTime && openTime ? `(${openTime}- ${closeTime})` : ''}</div>
                        <div>{pickup && pickup === 'bookLater' ? 'Book Later' : ''}</div>
                    </div>
                }
            },
            {
                title: 'Provider Name',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                title: 'Sender Details',
                key: 'senderData.fullName',
                searchTextName: 'Sender Name',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.senderData && record.senderData.fullName}</div>
                        <div>{record && record.senderData && record.senderData.address}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.cityName}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.countryISO}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.zipCode}</div>

                    </div>)
                }
            },
            {
                title: 'Receiver Details',
                key: 'receiverData.fullName',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.receiverData && record.receiverData.fullName}</div>
                        <div>{record && record.receiverData && record.receiverData.address}</div>
                        <div>{record && record.packageDestination && record.packageDestination.cityName}</div>
                        <div>{record && record.packageDestination && record.packageDestination.countryISO}</div>
                        <div>{record && record.packageDestination && record.packageDestination.zipCode}</div>

                    </div>)
                },
                searchTextName: 'Receiver Name',
            },

            {
                title: 'User Details',
                key: 'userId',
                width: 200,
                dataIndex: 'userId',
                render: (val, record) => {
                    return (<div>
                        <div>Name : {val && val.fullName}</div>
                        <div>Email : {val && val.email}</div>
                    </div>)
                }
            },
            {
                title: 'Coupon Details',
                key: 'coupon',
                width: 200,
                render: (val, record) => {
                    return record.couponName && <div>
                        <div style={{ display: 'flex' }}> Code :  <Tag>{record.couponName}</Tag></div>
                        <div style={{ display: 'flex' }}>Discount : ({record.couponAmount})</div>
                    </div>

                }
            },
            {
                title: 'Affiliated Commission Amt (%)',
                dataIndex: 'affiliatedUserCommission',
                key: 'affiliatedUserCommission',
                searchTextName: 'affiliatedUserCommission',
                width: 100,
                render: (val, r) => {
                    return val && <div>{`${val} MAD`}({r.affiliatedUserCommissionPercentage} %)</div>
                 
                }

            },
            {
                title: ' Affiliated User Details',
                width: 200,
                dataIndex: 'affiliatedUser',
                render: (val, record) => {

                    return val&&<div>
                        <div>Name :{record && record.affiliatedUser && record.affiliatedUser.fullName}</div>
                       <div>Email :{record && record.affiliatedUser && record.affiliatedUser.email}</div>

                    </div>
                }
            },
            {
                title: 'Details',
                key: 'type',
                width: 150,
                render: (val, record) => {
                    if (record && record.packages.length) {
                        if (record.packages.length > 1) {
                            return <div>{_.map(record.packages, (ii, key) => {
                                if (parseInt(key) != record.packages.length - 1) {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>
                                        <Divider/>
                                    </div>
                                } else {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>

                                    </div>
                                }
                            })}</div>
                        } else {
                            return <span> {_.map(record.packages, (ii, key) => {
                                return <div>
                                    <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                    </div>
                                    <div>
                                        <FaWeightHanging/> {ii.weight} {ii.unit}
                                    </div>
                                    <div>
                                        <GiCube/> {ii.quantity}
                                    </div>

                                </div>

                            })}</span>
                        }
                    }


                }

            },

            {
                title: 'Quoted Price',
                key: 'netAmount',
                searchTextName: 'netAmount',
                // width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                        <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },

            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },
            {
                title: 'Updated At',
                key: 'updatedAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.updatedAt ? moment(record.updatedAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },

        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `Expéditions Nationales confirmées: ${this.state.count}` : `Expéditions Nationales confirmées: 0`}>

                <Card bordered={true}>


                    <Row>
                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select User Name"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allUsers &&
                                allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col span={10}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Affiliate User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Affiliate User Name"
                                onChange={id => {
                                    this.setState({affiliateUserId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allAffiliateUsers &&
                                allAffiliateUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                       
                        <Col span={6}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Search Tracking Number</h4>

                            <Input style={{width: 150}} placeholder={'Tracking Number'} onChange={(val) => {
                                this.onChangeTrackingNumber(val.target.value)

                            }}>

                            </Input>

                        </Col>
                    </Row>


                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        // checkBox={this.onSelectChange}
                               extraProps={{
                                   scroll: {x: 2300}
                               }}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=" Shipment Response"
                    forceRender={true}
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20, marginTop: 20}}>
                        <JSONPretty data={JSON.stringify(shipmentResponse, undefined, 14)}
                                    theme={JSONPrettyMon}></JSONPretty>
                    </div>


                </Drawer>
                <ScheduleForm handleCancel={this.handleCancel.bind(this)} showModal={showModal}
                              quoteId={this.state.quoteId} quoteDetails={quoteDetails}/>
                <Modal
                    title="Shipment Tracking"
                    width={500}
                    footer={null}
                    onCancel={this.onTrackingClose}
                    visible={showTrackingModal}>
                    {shipmentType === 'UPS Saver' &&
                    <UpsTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                    {shipmentType === 'Aramex' &&
                    <AramexTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                    {shipmentType === 'DHL' && <DhlTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                </Modal>
            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllOrder)
