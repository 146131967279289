import React, {Fragment} from 'react'
import {Icon} from 'antd'
import styles from './AuthLayout.less'
import logo from '../assets/login.jpg'
import {Link} from 'react-router-dom'
import GlobalFooter from '../components/GlobalFooter'
import {subTitle, pageTitle} from '../settings'


const links = [
    {
        key: 'help',
        title: 'HELP',
        href: ''
    }
]

const copyright = (
    <Fragment>
        Copyright <Icon type="copyright"/> 2018
    </Fragment>
)

class UserLayout extends React.PureComponent {

    render() {
        const {children} = this.props
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.main}>
                        <div className={styles.top}>
                            <img alt="logo" className={styles.logo} src={logo}/>
                            <div className={styles.header}>
                                <h2>LOGIN ACCOUNT</h2>
                            </div>
                            {/*<div className={styles.desc}></div>*/}
                        </div>
                        {children}


                    </div>

                </div>
                {/*<GlobalFooter links={links} copyright={copyright}/>*/}
            </div>
        )
    }
}

export default UserLayout
