import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Modal,
    Tooltip,
    Popconfirm,
    notification,
    Tag,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'

class AllCoupons extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            status: ['Active', 'Inactive'],
            discountType: ['Percentage', 'Flat'],
            paymentMethod: ['Chèque', 'Espèce', 'Western Union', 'Wafacash', 'Virement / Versement Bancaire', 'Carte Bancaire'],
        }

    }

    deleteCoupon = async data => {
        this.setState({ loading: true })
        let x = await Request.deleteCoupon(data)
        this.setState({ loading: false })

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    showModal = async (record) => {
        let data = await Request.getcouponDataInQuote(record)
        this.setState({
            quoteData: data,
            visible: true,
        });
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllCoupons({
                ...params, regExFilters: ['coupon'],

            })
            this.setState({ count: data.total })
            resolve(data)
        })
    }


    componentDidMount() {
    }

    render() {
        const { submitting, dispatch, currentUser } = this.props
        const { status, paymentMethod, discountType, quoteData } = this.state


        const columns = [
            {
                key: 'actions',
                title: 'Action',
                fixed: 'left',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Coupon">
                            <Button
                                shape="circle"
                                style={{ marginRight: 6 }}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('coupon.editCoupon', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete Coupon">
                            <Popconfirm
                                title="Are you sure delete this Coupon?"
                                onConfirm={() => {
                                    this.deleteCoupon({ _id: record._id })
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>

                    </React.Fragment>
                }
            },
            {
                title: 'Coupon Name',
                dataIndex: 'couponName',
                fixed: 'left',
                key: 'coupon',
                searchTextName: 'couponName',
                width: 150
            },
            {
                title: 'Coupon Code',
                dataIndex: 'coupon',
                fixed: 'left',
                key: 'coupon',
                searchTextName: 'coupon',
                width: 150
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description'
            },
            {
                title: 'Active',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                filters: status && status.map(x => ({ value: x, text: x })),
                render: (val) => {
                    return <div><Tag
                        style={{ backgroundColor: val === 'Active' ? '#52c41a' : 'red', color: 'white' }}>{val}</Tag>
                    </div>


                }

            },
            {
                title: 'Affiliated User Details',
                key: 'affiliatedUser.fullName',
                searchTextName: 'affiliated User Name',
                width: 100,
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.affiliatedUser && record.affiliatedUser.fullName}</div>
                        <div>{record && record.affiliatedUser && record.affiliatedUser.email}</div>

                    </div>)
                }
            },
            {
                title: 'Maximum Usage',
                dataIndex: 'maximumUsage',
                key: 'maximumUsage',
                width: 50
            },
            {
                title: 'Uses Count',
                dataIndex: 'usesCount',
                key: 'usesCount',
                width: 120,
                render: (val, record) => {
                    return <>{val} <Button type="primary" onClick={() => this.showModal(record)}>
                        View
                  </Button></>
                }
            },

            {
                title: 'Payment Method',
                dataIndex: 'paymentMethod',
                key: 'paymentMethod',
                width: 150,
                filters: paymentMethod && paymentMethod.map(x => ({ value: x, text: x })),
                render: (item, record) => {
                    if (item && item.length) {
                        if (item.length > 1) {
                            return <div>{_.map(item, (i, key) => {
                                if (parseInt(key) != item.length - 1) {
                                    return `${i} ,`
                                } else {
                                    return `${i} `
                                }
                            })}</div>
                        } else {
                            return <div>{_.map(item, (i, key) => {
                                return i
                            })}</div>
                        }
                    }
                }

            },
            {
                title: 'Discount Type / Discount Amount',
                dataIndex: 'discountType',
                key: 'discountType',
                filters: discountType && discountType.map(x => ({ value: x, text: x })),
                width: 150,
                render: (val, record) => {
                    return (val + ' / ' + record.discountAmount)
                }
            },
            {
                title: 'Starting Date',
                dataIndex: 'startDate',
                key: 'startDate',
                width: 100,
                render: (val) => {
                    return <div><Tag>{moment(val).format('L')}</Tag>
                    </div>
                }

            },
            {
                title: 'Expiry Date',
                dataIndex: 'expiry',
                key: 'expiry',
                width: 100,
                render: (val) => {
                    return <div><Tag>{moment(val).format('L')}</Tag>
                    </div>


                }

            },


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Coupons: ${this.state.count}` : `All Coupon: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                        columns={columns}
                        apiRequest={this.apiRequest}
                        extraProps={{
                            scroll: { x: 1700 }
                        }}
                        pagination={{
                            ...this.state.pagination,
                            defaultPageSize: 50,
                            pageSizeOptions: ['10', '25', '50', '100'],
                            showSizeChanger: true,
                            ...this.props.pagination
                        }} />
                </Card>

                <Modal
                    title="Quotes"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <div style={{display:'flex',justifyContent: "space-evenly"}}>
                        <h3>Placed Shipment :</h3>
                        <span>
                            {quoteData && quoteData.placed}
                        </span>
                    </div>
                    <div style={{display:'flex',justifyContent: "space-evenly"}}>
                        <h3>Pending Shipment :</h3>
                        <div>
                            {quoteData && quoteData.pending}
                        </div>
                    </div>
                    <div style={{display:'flex',justifyContent: "space-evenly"}}><h3>Cancelled Shipment :</h3>
                        <div>
                            {quoteData && quoteData.cancelled}</div>
                    </div>

                </Modal>
            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCoupons)
