import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllZones extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(), zoneTypeList: ['INBOUND', 'OUTBOUND', 'DOMESTIC'],
            serviceName: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],

        }

    }

    deleteZone = async data => {
        this.setState({loading: true})
        let x = await Request.deleteZone(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllZone({
                ...params, regExFilters: ['zone'],

            })
            this.setState({count: data.total})
            console.log(this.state.allCountry, "dfknfrjrn")
            resolve(data)
        })
    }


    async componentDidMount() {

    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        console.log(dispatch, "drffffffffffffffffffffffffffffffffff")
        const {zoneTypeList, serviceName} = this.state


        const columns = [
            {
                title: 'Provider Name',
                dataIndex: 'serviceName',
                key: 'serviceName',
                filters: serviceName && serviceName.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                searchTextName: 'zone',
                width: 150
            },
            {
                title: 'Countries',
                key: 'zoneCountries',
                //dataIndex: 'zoneCountries',
                width: 150,
                render: (item) => {
                    if (item.length) {
                        if (item.length > 1) {
                            return <div>{item.length} Countries with: {_.map(item, (i, key) => {
                                if (parseInt(key) != item.length - 1) {
                                    return <span> {`${i}`},</span>
                                } else {
                                    return <span>{i}
                                    </span>
                                }
                            })}</div>
                        } else {
                            return <span>{item.length} Country with: {_.map(item, (i, key) => {
                                return <span>{i}
                                </span>

                            })}</span>
                        }
                    }

                }


            },

            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 150,
                filters: zoneTypeList && zoneTypeList.map(x => ({value: x, text: x})),

            },

            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Zone">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('settings.zones.editZone', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete Zone">
                            <Popconfirm
                                title="Are you sure delete this Zone?"
                                onConfirm={() => {
                                    this.deleteZone({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Zones: ${this.state.count}` : `All Zone: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllZones)
