import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import ChangePassword from '../../../components/changePassword'

import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Tag,
    Switch,
    Input, Button, Icon,
    Row, Col, Modal, Form, Checkbox
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

const {TextArea} = Input;

class AllUser extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            selectedRows: []
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.userType = ['user', 'admin']
            let data = await Request.getAllUser({
                ...params, regExFilters: ['email', 'fullName'],

            })
            this.setState({count: data.total})
            console.log(data)
            resolve(data)
        })
    }

    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }
    sendNotification = async () => {
        let {selectedRows, title, message, sendStatus} = this.state
        if (selectedRows.length === 0) {
            return
        }

        if (title && message) {
            this.setState({notificationLoader: true})
            console.log(message, title)

            if (sendStatus) {

                let {data: allUsers} = await Request.loadUser({userType: ['user', 'admin']})
                let ids = _.map(allUsers, (x) => x._id)
                selectedRows = ids
            }

            let {data, error, msg} = await Request.sendNotification({
                sendStatus: sendStatus,
                user: selectedRows,
                title: title,
                message: message
            })
            this.setState({notificationLoader: false})


            if (!error) {
                notification.success({message: msg})
                this.table.current.reload()
                this.setState({
                    userDrawer: false,
                    selectedRows: [],
                    title: undefined,
                    message: undefined,
                    sendStatus: false,
                    reloadTableCheckbox: Date.now()
                })

            }
            else {
                notification.error({message: msg})
            }

        } else {
            return notification.error({
                message: 'Please Fill Details'
            })
        }


    }

    onSelectChange = selectedRowKeys => {
        console.log(selectedRowKeys, "fffffffffffffffffffffffffffffffffff")
        let {selectedRowKeys: selectedData} = selectedRowKeys
        this.setState({selectedRows: selectedData});

    };

    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {changePasswordRecord, selectedRows, userDrawer, notificationLoader, reloadTableCheckbox} = this.state


        const columns = [
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email',
                //  width: 150
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName',
                key: 'fullName',
                searchTextName: 'fullName',
                //width: 150
            },
            {
                title: 'Billing Address',
                dataIndex: 'billingAddress',
                key: 'billingAddress',
                render: (val) => {
                    return (<div>
                        {val && val[0] ? <div>{val[0].fullName}</div> : ''}
                        {val && val[0] ? <div>{val[0].city},</div> : ''}
                        {val && val[0] ? <div>{val[0].postalCode},</div> : ''}
                        {val && val[0] ? <div>{val[0].country}</div> : ''}


                    </div>)
                }
            },

            {
                title: 'Shipping Address',
                dataIndex: 'shippingAddress',
                key: 'shippingAddress',
                render: (val) => {
                    return (<div>
                        {val && val[0] ? <div>{val[0].fullName}</div> : ''}
                        {val && val[0] ? <div>{val[0].city},</div> : ''}
                        {val && val[0] ? <div>{val[0].postalCode},</div> : ''}
                        {val && val[0] ? <div>{val[0].country}</div> : ''}


                    </div>)
                }
            },

            {
                title: 'Created On',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: (val) => {
                    return (val && <Tag>
                        {val && moment(val).format('L')}
                    </Tag>)
                }
            },


            {
                key: 'actions',
                title: 'Action',
                // width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit User">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('user.editUser', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                        <Tooltip title="Change Password">

                            <Button style={{marginRight: 6}}
                                    shape="circle"
                                    size="small"
                                    icon={'unlock'}
                                    onClick={() => {
                                        this.setState({changePasswordRecord: record}, () => {
                                            this.showDrawer()
                                        })


                                    }}>

                            </Button>

                        </Tooltip>

                    </React.Fragment>
                }
            }

        ]

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Users: ${this.state.count}` : `All User: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col>
                            <div style={{textAlign: 'right'}}>
                                <Button disabled={selectedRows.length === 0}
                                        onClick={() => this.setState({userDrawer: true})}>Send
                                    Notification</Button>
                            </div>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               checkBox={this.onSelectChange}
                               key={reloadTableCheckbox}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>


                    <Drawer
                        title="Change Password"
                        forceRender={true}
                        placement="right"
                        closable={true}
                        key={this.state.key}
                        onClose={() => {

                            this.setState({
                                visible: false
                            })
                        }}
                        visible={this.state.visible}
                        width={800}>
                        <Card bordered={true}>
                            <Row>
                                <Col span={12}>
                                    <b>Name : </b>{changePasswordRecord ? changePasswordRecord.fullName : null}
                                </Col>
                                <Col span={12}>
                                    <b>Email : </b>{changePasswordRecord ? changePasswordRecord.email : null}
                                </Col>
                            </Row>
                        </Card>
                        <Card bordered={false} style={{marginTop: 10}}>
                            <ChangePassword handleSubmit={async values => {
                                console.log(values, "nsdnfjfnrjfn")
                                this.setState({visible: false})


                            }} email={changePasswordRecord && changePasswordRecord.email}/>
                        </Card>


                    </Drawer>

                    <Modal
                        title="Send Notification"
                        onCancel={async () => {
                            this.setState({
                                userDrawer: false,
                                title: undefined,
                                message: undefined,
                                sendStatus: false
                            })
                        }}
                        visible={userDrawer}
                        okText='Send'
                        onOk={this.sendNotification}
                        okButtonProps={{
                            loading: notificationLoader
                        }}
                    >
                        <div>
                            <Form {...formItemLayout}>
                                <Form.Item label='Title'>
                                    <Input required={true} value={this.state.title} onChange={(val) => {
                                        this.setState({title: val.target.value})
                                    }}/>
                                </Form.Item>
                                <Form.Item label='Message'>
                                    <TextArea rows={5} value={this.state.message} onChange={(val) => {
                                        this.setState({message: val.target.value})
                                    }}/>
                                </Form.Item>
                                <Form.Item label='Send To All Customers'>
                                    <Checkbox checked={this.state.sendStatus} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.setState({sendStatus: true})
                                        }
                                        else {
                                            this.state.sendStatus = false;

                                        }
                                    }}></Checkbox>

                                </Form.Item>
                            </Form>
                        </div>
                    </Modal>

                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllUser)
