export const countryCodeList = [
    { code: "+93", value: "93", countryCode: "AF", },
    { code: "+358", value: "358", countryCode: "AX", },
    { code: "+355", value: "355", countryCode: "AL", },
    { code: "+213", value: "213", countryCode: "DZ", },
    { code: "+1 684", value: "1 684", countryCode: "AS", },
    { code: "+376", value: "376", countryCode: "AD", },
    { code: "+244", value: "244", countryCode: "AO", },
    { code: "+1 264", value: "1 264", countryCode: "AI", },
    { code: "+672", value: "672", countryCode: "AQ", },
    { code: "+1268", value: "1268", countryCode: "AG", },
    { code: "+54", value: "54", countryCode: "AR", },
    { code: "+374", value: "374", countryCode: "AM", },
    { code: "+297", value: "297", countryCode: "AW", },
    { code: "+61", value: "61", countryCode: "AU", },
    { code: "+43", value: "43", countryCode: "AT", },
    { code: "+994", value: "994", countryCode: "AZ", },
    { code: "+1 242", value: "1 242", countryCode: "BS", },
    { code: "+973", value: "973", countryCode: "BH", },
    { code: "+880", value: "880", countryCode: "BD", },
    { code: "+1 246", value: "1 246", countryCode: "BB", },
    { code: "+375", value: "375", countryCode: "BY", },
    { code: "+32", value: "32", countryCode: "BE", },
    { code: "+501", value: "501", countryCode: "BZ", },
    { code: "+229", value: "229", countryCode: "BJ", },
    { code: "+1 441", value: "1 441", countryCode: "BM", },
    { code: "+975", value: "975", countryCode: "BT", },
    { code: "+591", value: "591", countryCode: "BO", },
    { code: "+387", value: "387", countryCode: "BA", },
    { code: "+267", value: "267", countryCode: "BW", },
    { code: "+55", value: "55", countryCode: "BR", },
    { code: "+246", value: "246", countryCode: "IO", },
    { code: "+673", value: "673", countryCode: "BN", },
    { code: "+359", value: "359", countryCode: "BG", },
    { code: "+226", value: "226", countryCode: "BF", },
    { code: "+257", value: "257", countryCode: "BI", },
    { code: "+855", value: "855", countryCode: "KH", },
    { code: "+237", value: "237", countryCode: "CM", },
    { code: "+1", value: "1", countryCode: "CA", },
    { code: "+238", value: "238", countryCode: "CV", },
    { code: "+ 345", value: " 345", countryCode: "KY", },
    { code: "+236", value: "236", countryCode: "CF", },
    { code: "+235", value: "235", countryCode: "TD", },
    { code: "+56", value: "56", countryCode: "CL", },
    { code: "+86", value: "86", countryCode: "CN", },
    { code: "+61", value: "61", countryCode: "CX", },
    { code: "+61", value: "61", countryCode: "CC", },
    { code: "+57", value: "57", countryCode: "CO", },
    { code: "+269", value: "269", countryCode: "KM", },
    { code: "+242", value: "242", countryCode: "CG", },
    { code: "+243", value: "243", countryCode: "CD", },
    { code: "+682", value: "682", countryCode: "CK", },
    { code: "+506", value: "506", countryCode: "CR", },
    { code: "+225", value: "225", countryCode: "CI", },
    { code: "+385", value: "385", countryCode: "HR", },
    { code: "+53", value: "53", countryCode: "CU", },
    { code: "+357", value: "357", countryCode: "CY", },
    { code: "+420", value: "420", countryCode: "CZ", },
    { code: "+45", value: "45", countryCode: "DK", },
    { code: "+253", value: "253", countryCode: "DJ", },
    { code: "+1 767", value: "1 767", countryCode: "DM", },
    { code: "+1 849", value: "1 849", countryCode: "DO", },
    { code: "+593", value: "593", countryCode: "EC", },
    { code: "+20", value: "20", countryCode: "EG", },
    { code: "+503", value: "503", countryCode: "SV", },
    { code: "+240", value: "240", countryCode: "GQ", },
    { code: "+291", value: "291", countryCode: "ER", },
    { code: "+372", value: "372", countryCode: "EE", },
    { code: "+251", value: "251", countryCode: "ET", },
    { code: "+500", value: "500", countryCode: "FK", },
    { code: "+298", value: "298", countryCode: "FO", },
    { code: "+679", value: "679", countryCode: "FJ", },
    { code: "+358", value: "358", countryCode: "FI", },
    { code: "+33", value: "33", countryCode: "FR", },
    { code: "+594", value: "594", countryCode: "GF", },
    { code: "+689", value: "689", countryCode: "PF", },
    { code: "+241", value: "241", countryCode: "GA", },
    { code: "+220", value: "220", countryCode: "GM", },
    { code: "+995", value: "995", countryCode: "GE", },
    { code: "+49", value: "49", countryCode: "DE", },
    { code: "+233", value: "233", countryCode: "GH", },
    { code: "+350", value: "350", countryCode: "GI", },
    { code: "+30", value: "30", countryCode: "GR", },
    { code: "+299", value: "299", countryCode: "GL", },
    { code: "+1 473", value: "1 473", countryCode: "GD", },
    { code: "+590", value: "590", countryCode: "GP", },
    { code: "+1 671", value: "1 671", countryCode: "GU", },
    { code: "+502", value: "502", countryCode: "GT", },
    { code: "+44", value: "44", countryCode: "GG", },
    { code: "+224", value: "224", countryCode: "GN", },
    { code: "+245", value: "245", countryCode: "GW", },
    { code: "+595", value: "595", countryCode: "GY", },
    { code: "+509", value: "509", countryCode: "HT", },
    { code: "+379", value: "379", countryCode: "VA", },
    { code: "+504", value: "504", countryCode: "HN", },
    { code: "+852", value: "852", countryCode: "HK", },
    { code: "+36", value: "36", countryCode: "HU", },
    { code: "+354", value: "354", countryCode: "IS", },
    { code: "+91", value: "91", countryCode: "IN", },
    { code: "+62", value: "62", countryCode: "ID", },
    { code: "+98", value: "98", countryCode: "IR", },
    { code: "+964", value: "964", countryCode: "IQ", },
    { code: "+353", value: "353", countryCode: "IE", },
    { code: "+44", value: "44", countryCode: "IM", },
    { code: "+972", value: "972", countryCode: "IL", },
    { code: "+39", value: "39", countryCode: "IT", },
    { code: "+1 876", value: "1 876", countryCode: "JM", },
    { code: "+81", value: "81", countryCode: "JP", },
    { code: "+44", value: "44", countryCode: "JE", },
    { code: "+962", value: "962", countryCode: "JO", },
    { code: "+7 7", value: "7 7", countryCode: "KZ", },
    { code: "+254", value: "254", countryCode: "KE", },
    { code: "+686", value: "686", countryCode: "KI", },
    { code: "+850", value: "850", countryCode: "KP", },
    { code: "+82", value: "82", countryCode: "KR", },
    { code: "+965", value: "965", countryCode: "KW", },
    { code: "+996", value: "996", countryCode: "KG", },
    { code: "+856", value: "856", countryCode: "LA", },
    { code: "+371", value: "371", countryCode: "LV", },
    { code: "+961", value: "961", countryCode: "LB", },
    { code: "+266", value: "266", countryCode: "LS", },
    { code: "+231", value: "231", countryCode: "LR", },
    { code: "+218", value: "218", countryCode: "LY", },
    { code: "+423", value: "423", countryCode: "LI", },
    { code: "+370", value: "370", countryCode: "LT", },
    { code: "+352", value: "352", countryCode: "LU", },
    { code: "+853", value: "853", countryCode: "MO", },
    { code: "+389", value: "389", countryCode: "MK", },
    { code: "+261", value: "261", countryCode: "MG", },
    { code: "+265", value: "265", countryCode: "MW", },
    { code: "+60", value: "60", countryCode: "MY", },
    { code: "+960", value: "960", countryCode: "MV", },
    { code: "+223", value: "223", countryCode: "ML", },
    { code: "+356", value: "356", countryCode: "MT", },
    { code: "+692", value: "692", countryCode: "MH", },
    { code: "+596", value: "596", countryCode: "MQ", },
    { code: "+222", value: "222", countryCode: "MR", },
    { code: "+230", value: "230", countryCode: "MU", },
    { code: "+262", value: "262", countryCode: "YT", },
    { code: "+52", value: "52", countryCode: "MX", },
    { code: "+691", value: "691", countryCode: "FM", },
    { code: "+373", value: "373", countryCode: "MD", },
    { code: "+377", value: "377", countryCode: "MC", },
    { code: "+976", value: "976", countryCode: "MN", },
    { code: "+382", value: "382", countryCode: "ME", },
    { code: "+1664", value: "1664", countryCode: "MS", },
    { code: "+212", value: "212", countryCode: "MA", },
    { code: "+258", value: "258", countryCode: "MZ", },
    { code: "+95", value: "95", countryCode: "MM", },
    { code: "+264", value: "264", countryCode: "NA", },
    { code: "+674", value: "674", countryCode: "NR", },
    { code: "+977", value: "977", countryCode: "NP", },
    { code: "+31", value: "31", countryCode: "NL", },
    { code: "+599", value: "599", countryCode: "AN", },
    { code: "+687", value: "687", countryCode: "NC", },
    { code: "+64", value: "64", countryCode: "NZ", },
    { code: "+505", value: "505", countryCode: "NI", },
    { code: "+227", value: "227", countryCode: "NE", },
    { code: "+234", value: "234", countryCode: "NG", },
    { code: "+683", value: "683", countryCode: "NU", },
    { code: "+672", value: "672", countryCode: "NF", },
    { code: "+1 670", value: "1 670", countryCode: "MP", },
    { code: "+47", value: "47", countryCode: "NO", },
    { code: "+968", value: "968", countryCode: "OM", },
    { code: "+92", value: "92", countryCode: "PK", },
    { code: "+680", value: "680", countryCode: "PW", },
    { code: "+970", value: "970", countryCode: "PS", },
    { code: "+507", value: "507", countryCode: "PA", },
    { code: "+675", value: "675", countryCode: "PG", },
    { code: "+595", value: "595", countryCode: "PY", },
    { code: "+51", value: "51", countryCode: "PE", },
    { code: "+63", value: "63", countryCode: "PH", },
    { code: "+872", value: "872", countryCode: "PN", },
    { code: "+48", value: "48", countryCode: "PL", },
    { code: "+351", value: "351", countryCode: "PT", },
    { code: "+1 939", value: "1 939", countryCode: "PR", },
    { code: "+974", value: "974", countryCode: "QA", },
    { code: "+40", value: "40", countryCode: "RO", },
    { code: "+7", value: "7", countryCode: "RU", },
    { code: "+250", value: "250", countryCode: "RW", },
    { code: "+262", value: "262", countryCode: "RE", },
    { code: "+590", value: "590", countryCode: "BL", },
    { code: "+290", value: "290", countryCode: "SH", },
    { code: "+1 869", value: "1 869", countryCode: "KN", },
    { code: "+1 758", value: "1 758", countryCode: "LC", },
    { code: "+590", value: "590", countryCode: "MF", },
    { code: "+508", value: "508", countryCode: "PM", },
    { code: "+1 784", value: "1 784", countryCode: "VC", },
    { code: "+685", value: "685", countryCode: "WS", },
    { code: "+378", value: "378", countryCode: "SM", },
    { code: "+239", value: "239", countryCode: "ST", },
    { code: "+966", value: "966", countryCode: "SA", },
    { code: "+221", value: "221", countryCode: "SN", },
    { code: "+381", value: "381", countryCode: "RS", },
    { code: "+248", value: "248", countryCode: "SC", },
    { code: "+232", value: "232", countryCode: "SL", },
    { code: "+65", value: "65", countryCode: "SG", },
    { code: "+421", value: "421", countryCode: "SK", },
    { code: "+386", value: "386", countryCode: "SI", },
    { code: "+677", value: "677", countryCode: "SB", },
    { code: "+252", value: "252", countryCode: "SO", },
    { code: "+27", value: "27", countryCode: "ZA", },
    { code: "+500", value: "500", countryCode: "GS", },
    { code: "+34", value: "34", countryCode: "ES", },
    { code: "+94", value: "94", countryCode: "LK", },
    { code: "+249", value: "249", countryCode: "SD", },
    { code: "+597", value: "597", countryCode: "SR", },
    { code: "+47", value: "47", countryCode: "SJ", },
    { code: "+268", value: "268", countryCode: "SZ", },
    { code: "+46", value: "46", countryCode: "SE", },
    { code: "+41", value: "41", countryCode: "CH", },
    { code: "+963", value: "963", countryCode: "SY", },
    { code: "+886", value: "886", countryCode: "TW", },
    { code: "+992", value: "992", countryCode: "TJ", },
    { code: "+255", value: "255", countryCode: "TZ", },
    { code: "+66", value: "66", countryCode: "TH", },
    { code: "+670", value: "670", countryCode: "TL", },
    { code: "+228", value: "228", countryCode: "TG", },
    { code: "+690", value: "690", countryCode: "TK", },
    { code: "+676", value: "676", countryCode: "TO", },
    { code: "+1 868", value: "1 868", countryCode: "TT", },
    { code: "+216", value: "216", countryCode: "TN", },
    { code: "+90", value: "90", countryCode: "TR", },
    { code: "+993", value: "993", countryCode: "TM", },
    { code: "+1 649", value: "1 649", countryCode: "TC", },
    { code: "+688", value: "688", countryCode: "TV", },
    { code: "+256", value: "256", countryCode: "UG", },
    { code: "+380", value: "380", countryCode: "UA", },
    { code: "+971", value: "971", countryCode: "AE", },
    { code: "+44", value: "44", countryCode: "GB", },
    { code: "+1", value: "1", countryCode: "US", },
    { code: "+598", value: "598", countryCode: "UY", },
    { code: "+998", value: "998", countryCode: "UZ", },
    { code: "+678", value: "678", countryCode: "VU", },
    { code: "+58", value: "58", countryCode: "VE", },
    { code: "+84", value: "84", countryCode: "VN", },
    { code: "+1 284", value: "1 284", countryCode: "VG", },
    { code: "+1 340", value: "1 340", countryCode: "VI", },
    { code: "+681", value: "681", countryCode: "WF", },
    { code: "+967", value: "967", countryCode: "YE", },
    { code: "+260", value: "260", countryCode: "ZM", },
    { code: "+263", value: "263", countryCode: "ZW", }
]
