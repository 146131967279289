import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Modal,
    DatePicker,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Divider,
    Input, Button, Icon, Select,
    Row, Col,
    AutoComplete,
    Spin
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import { FaWeightHanging } from 'react-icons/fa'
import { AiOutlineEye, AiOutlineCodeSandbox } from 'react-icons/ai'
import { GiCube } from 'react-icons/gi'
import { connect } from 'react-redux'
const { RangePicker } = DatePicker

const { Option } = Select

class AllOrder extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            selectedRowKeys: [],
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver'],
            trackLoader: [],
            cancelLoader: [],
            downloadCommercial: [],
            trackingNumberSearch: '',
            time: {
                key: 'createdAt',
                from: null,
                to: null
            },
            commisionValue: 0
        }
    }

    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.quoteStatus = ['Placed']
            if (this.state.userId) {
                params.userId = this.state.userId
            }
            let user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                params.affiliatedUser = user._id
            }
            params.showCommision = true
            if (this.state.trackingNumberSearch) {
                params['shipmentSanitizeResponse.trackingNumber'] = this.state.trackingNumberSearch
            }
            let data = await Request.getAllQuote({
                ...params, regExFilters: ['senderData.fullName', 'receiverData.fullName'],
                dateFilter: this.state.time,
            })
            console.log(data)
            this.setState({ count: data.total, commisionValue: data.commisionValue })

            resolve(data)
        })
    }
    async componentDidMount() {

    }
    chooseRangerPicker = (date, dateString) => {
        this.setState(
            {
                time: {
                    key: 'createdAt',
                    from: date[0],
                    to: date[1]
                }
            }, () => {
                if (date[0] == undefined) {
                    this.table.current.reload()
                }
            }
        )

    }

    onOk = value => {
        this.table.current.reload()
    }
    render() {
        const { submitting, dispatch, currentUser } = this.props
        const {
            packageType, shipmentResponse, showTrackingModal, quoteDetails,
            packages, shipmentType, data, typeList, selectedRowKeys, serviceProviderName, showModal, allUsers, trackingList, showSpin,
            commisionValue

        } = this.state

        const TITLE = () => {
            return<div style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div>{this.state.count ? `Expéditions Confirmées: ${this.state.count}` : `Expéditions Confirmées: 0`}</div>
                <div>{`Total Commision : ${commisionValue} MAD`}</div>
            </div>
        }


        const columns = [

            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },
            {
                title: 'Coupon Code',
                dataIndex: 'couponName',
                key: 'couponName',
                searchTextName: 'couponName',
                width: 100,
                render: (val, r) => {
                    return val && <Tag>{val}</Tag>
                }
            },
            {
                title: 'Commission Amt (%)',
                dataIndex: 'affiliatedUserCommission',
                key: 'affiliatedUserCommission',
                searchTextName: 'affiliatedUserCommission',
                width: 100,
                render: (val, r) => {
                    return val && <div>{val}({r.affiliatedUserCommissionPercentage} %)</div>
                }

            },
            {
                title: 'Status',
                key: 'quoteStatus',
                render: (val) => {
                    return <div><Tag style={{ backgroundColor: '#52c41a', color: 'white' }}>{val}</Tag>
                    </div>

                }
            },

            {
                title: 'Payment Response',
                dataIndex: 'paymentResponse',
                key: 'paymentResponse',
                width: 150,
                render: (val, record) => {
                    return <React.Fragment>
                        <Tag
                            color={val && val.Response == 'Approved' ? "#87d068" : "#f50"}>{val && val.Response}</Tag>
                        <div
                            style={{ marginTop: '10px' }}>{record && <>Payment Method : {record.paymentMode}</>}</div>
                        {record && record.paymentResponse && record.paymentResponse.notes &&
                            <>
                                <small> Notes : {record.paymentResponse.notes}</small>
                                <br />
                                <small> Reference No : {record.paymentResponse.TransId}</small>
                                <br />
                                <small> Payment Date
                                : {moment(record.paymentResponse.paymentDate).format('DD-MMM-YYYY')}</small>
                                <br />
                                <small> Amount : {record.paymentResponse.amount}</small>

                            </>}

                    </React.Fragment>


                }

            },
            {
                title: 'Provider Name',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({ value: x, text: x })),
                width: 150
            },
            {
                title: 'Details',
                key: 'type',
                width: 150,
                render: (val, record) => {
                    if (record && record.packages.length) {
                        if (record.packages.length > 1) {
                            return <div>{_.map(record.packages, (ii, key) => {
                                if (parseInt(key) != record.packages.length - 1) {
                                    return <div>
                                        <div><AiOutlineCodeSandbox /> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging /> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube /> {ii.quantity}
                                        </div>
                                        <Divider />
                                    </div>
                                } else {
                                    return <div>
                                        <div><AiOutlineCodeSandbox /> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging /> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube /> {ii.quantity}
                                        </div>

                                    </div>
                                }
                            })}</div>
                        } else {
                            return <span> {_.map(record.packages, (ii, key) => {
                                return <div>
                                    <div><AiOutlineCodeSandbox /> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                    </div>
                                    <div>
                                        <FaWeightHanging /> {ii.weight} {ii.unit}
                                    </div>
                                    <div>
                                        <GiCube /> {ii.quantity}
                                    </div>

                                </div>

                            })}</span>
                        }
                    }


                }

            },

            {
                title: 'Quoted Price',
                key: 'netAmount',
                searchTextName: 'netAmount',
                // width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                            <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },
            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={<TITLE />}>

                <Card bordered={true}>


                    <Row>
                        <Col span={12}>
                            <RangePicker
                                showTime={{ format: 'HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={['Start Time', 'End Time']}
                                onChange={this.chooseRangerPicker}
                                onOk={this.onOk}
                            />
                        </Col>
                    </Row>


                    <TableComp ref={this.table}
                        columns={columns}
                        apiRequest={this.apiRequest}
                        // checkBox={this.onSelectChange}
                        extraProps={{
                            scroll: { x: 1000 }
                        }}
                        pagination={{
                            ...this.state.pagination,
                            defaultPageSize: 10,
                            pageSizeOptions: ['10', '25', '50', '100'],
                            showSizeChanger: true,
                            ...this.props.pagination
                        }} />
                </Card>
            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({ global }) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllOrder)
