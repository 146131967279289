import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Collapse,
    Spin,
    Select,
    Transfer,
    Button,
    Table,
    Card,
    Row,
    Col,
    Checkbox,
    InputNumber,
    Icon, Tooltip, Popconfirm
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
// import GetAllFormFields from '../../../components/_utils/formUtils'

import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'

const {countryCodeList} = require('../../../countryCodeList')

const FormItem = Form.Item
const {Option} = Select


@Form.create()
class UpdateUser extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {}
        console.log(countryCodeList)
    }

    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {receiveNewsLetter, billingUserChecked} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())
                let x = null

                values.billingAddressChecked = billingUserChecked
                values.receiveNewsLetter = receiveNewsLetter
                values._id = this.state.id

                let shippingAddress = {
                    fullName: values.fullName,
                    society: values.society,
                    country: values.country,
                    phoneCode: values.phoneCode,
                    phoneNumber: values.phoneNumber,
                    city: values.city,
                    postalCode: values.postalCode,
                    address: values.address,
                    _id: this.state.shippingId

                }
                console.log(this.state.billingId, this.state.shippingId)
                let billingAddress = {
                    fullName: values.billingFullName,
                    society: values.billingSociety,
                    country: values.billingCountry,
                    phoneCode: values.billingPhoneCode,
                    phoneNumber: values.billingPhoneNumber,
                    city: values.billingCity,
                    postalCode: values.billingPostalCode,
                    address: values.billingAddress,
                    _id: this.state.billingId
                }
                values.billingAddress = billingAddress
                values.shippingAddress = shippingAddress

                console.log(values, "-------------------")

                  x = await Request.updateUser(values)


                dispatch(hideLoader())

                if (!x.error) {

                    notification.success({
                        message: 'Updated Successfully'
                    })
                    dispatch(goBack())

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }


    onchange = (ei) => {

        this.setState({newReferenceNumber: ei})


    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        let userId = searchParams.get('id')
        let {data: countryData} = await Request.loadCountries()
        let {data} = await Request.getUserById({_id: userId})
        if (data) {
            this.setState({id: userId, countryData})
            this.setState({
                billingUserChecked: true,

//                billingUserChecked: data.billingUserChecked ? true : false,
                receiveNewsLetter: data.receiveNewsLetter ? data.receiveNewsLetter : false,
                billingId: data.billingAddress && data.billingAddress._id,
                shippingId: data.shippingAddress && data.shippingAddress._id

            })


            this.props.form.setFieldsValue({
                fullName: data.shippingAddress && data.shippingAddress.fullName,
                address: data.shippingAddress && data.shippingAddress.address,
                city: data.shippingAddress && data.shippingAddress.city,
                country: data.shippingAddress && data.shippingAddress.country,
                phoneCode: data.shippingAddress && data.shippingAddress.phoneCode,
                phoneNumber: data.shippingAddress && data.shippingAddress.phoneNumber,
                postalCode: data.shippingAddress && data.shippingAddress.postalCode,
                society: data.shippingAddress && data.shippingAddress.society,
                billingFullName: data.billingAddress && data.billingAddress.fullName,
                billingAddress: data.billingAddress && data.billingAddress.address,
                billingCity: data.billingAddress && data.billingAddress.city,
                billingCountry: data.billingAddress && data.billingAddress.country,
                billingPhoneCode: data.billingAddress && data.billingAddress.phoneCode,
                billingPhoneNumber: data.billingAddress && data.billingAddress.phoneNumber,
                billingPostalCode: data.billingAddress && data.billingAddress.postalCode,
                billingSociety: data.billingAddress && data.billingAddress.society,
                billingEmail: data.billingAddress && data.billingAddress.email,
                billingUserChecked: data.billingUserChecked
            })
        }

    }

    render() {

        const {submitting, dispatch} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {billingUserChecked, receiveNewsLetter} = this.state


        const shippingAddress = {
            fields: [

                {
                    label: 'Full Name',
                    key: 'fullName',
                    required: true
                },
                {
                    label: 'Country',
                    type: 'select',
                    key: 'country',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x.countryName,
                    valueAccessor: x => `${x.countryName}(${x.isoCode})`,
                    options: this.state.countryData || [],
                    onChange: country => {
                        this.props.form.setFieldsValue({
                            country: country
                        })
                        _.each(this.state.countryData, (item) => {
                            let found = _.find(countryCodeList, (ii) => {
                                return ii.countryCode === item.isoCode && item.countryName === country
                            })

                            if (found) {
                                this.props.form.setFieldsValue({
                                    phoneCode: found.code
                                })
                            }
                        })


                    }
                },

                {
                    label: 'City',
                    key: 'city',
                    required: true

                },
                {
                    label: 'Address',
                    key: 'address',
                    type: 'textArea',
                    required: true,
                    rows: 5

                }


            ]
        }
        const shippingAddress1 = {
            fields: [

                {
                    label: 'Society',
                    key: 'society',
                    required: true
                },
                {
                    label: 'Phone',
                    key: 'phoneNumber',
                    required: true

                },
                {
                    label: 'Phone Code',
                    type: 'select',
                    key: 'phoneCode',
                    disabled: true,
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x.value,
                    valueAccessor: x => x.code,
                    options: countryCodeList || [],
                    onChange: phoneCode => {
                        console.log(phoneCode)
                        this.props.form.setFieldsValue({
                            phoneCode: phoneCode
                        })

                    }
                },
                {
                    label: 'Postal Code',
                    key: 'postalCode',
                    required: true

                }


            ]
        }
        const billingAddress = {
            fields: [

                {
                    label: 'Full Name',
                    key: 'billingFullName',
                    required: true
                },
                {
                    label: 'Country',
                    type: 'select',
                    key: 'billingCountry',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x.countryName,
                    valueAccessor: x => `${x.countryName}(${x.isoCode})`,
                    options: this.state.countryData || [],
                    onChange: country => {
                        this.props.form.setFieldsValue({
                            billingCountry: country
                        })
                        _.each(this.state.countryData, (item) => {
                            let found = _.find(countryCodeList, (ii) => {
                                return ii.countryCode === item.isoCode && item.countryName === country
                            })

                            if (found) {
                                this.props.form.setFieldsValue({
                                    billingPhoneCode: found.code
                                })
                            }
                        })


                    }
                },
                {
                    label: 'City',
                    key: 'billingCity',
                    required: true

                },
                {
                    label: 'Address',
                    key: 'billingAddress',
                    type: 'textArea',
                    required: true,
                    rows: 5

                }


            ]
        }
        const billingAddress1 = {
            fields: [

                {
                    label: 'Society',
                    key: 'billingSociety',
                    required: true
                },
                {
                    label: 'Phone',
                    key: 'billingPhoneNumber',
                    required: true

                },
                {
                    label: 'Phone Code',
                    type: 'select',
                    key: 'billingPhoneCode',
                    required: true,
                    disabled: true,
                    showSearch: true,
                    keyAccessor: x => x.value,
                    valueAccessor: x => x.code,
                    options: countryCodeList || [],
                    onChange: phoneCode => {
                        console.log(phoneCode)
                        this.props.form.setFieldsValue({
                            billingPhoneCode: phoneCode
                        })

                    }
                },
                {
                    label: 'Postal Code',
                    key: 'billingPostalCode',
                    required: true

                },
                {
                    label: 'Email',
                    key: 'billingEmail',
                    required: true

                }


            ]
        }


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        return (
            <PageHeaderWrapper
                title={'Update User'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Card bordered={true} style={{marginBottom: '15px'}}>
                            <div style={{
                                backgroundColor: '#f0f2f5',
                                height: '26px',
                                paddingLeft: '8px',
                                marginBottom: '15px'
                            }}><h3>Shipping Address</h3>
                            </div>

                            <Row>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={shippingAddress} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={shippingAddress1} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                            </Row>
                        </Card>

                        <Row>
                            <Col span={12}>
                                <Checkbox checked={this.state.billingUserChecked} onChange={(val) => {
                                    if (val.target.checked) {
                                        this.setState({
                                            billingUserChecked: true,
                                        })

                                    }
                                    else {
                                        this.setState({
                                            billingUserChecked: false,
                                        })

                                    }
                                }}>
                                    Use billing address for invoicing
                                </Checkbox>


                            </Col>
                        </Row>
                        {billingUserChecked && <Card bordered={true} style={{marginBottom: '15px', marginTop: '15px'}}>
                            <div style={{
                                backgroundColor: '#f0f2f5',
                                height: '26px',
                                paddingLeft: '8px',
                                marginBottom: '15px'
                            }}><h3>Billing Address</h3>
                            </div>

                            <Row>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={billingAddress} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={billingAddress1} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                            </Row>
                        </Card>}
                        <Row>
                            <Col span={12}>

                                <Checkbox checked={this.state.receiveNewsLetter} onChange={(val) => {
                                    if (val.target.checked) {
                                        this.setState({receiveNewsLetter: true})

                                    }
                                    else {
                                        this.setState({receiveNewsLetter: false})

                                    }
                                }}>
                                    I would like to receive newsletters and promotional offers from EasyShip
                                </Checkbox>
                            </Col>
                        </Row>

                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%', marginRight: '10px'}}>
                                SAVE
                            </Button>
                            <Button size="large" type="dashed" loading={this.props.loading}
                                    style={{width: '15%'}} onClick={() => {
                                dispatch(
                                    goBack())
                            }}>
                                Cancel
                            </Button>
                        </Form.Item>


                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateUser)
