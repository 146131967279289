import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllCity extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now()}

    }

    deleteCity = async data => {
        this.setState({loading: true})
        let x = await Request.deleteCity(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllCity({
                ...params, regExFilters: ['cityName', 'postalCode'],

            })
            this.setState({count: data.total})
            resolve(data)
        })
    }


    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {} = this.state


        const columns = [
            {
                title: 'City Name',
                dataIndex: 'cityName',
                key: 'cityName',
                searchTextName: 'cityName',
                width: 150
            },
            {
                title: 'ISO Code',
                dataIndex: 'isoCode',
                key: 'isoCode',
                searchTextName: 'isoCode',
                width: 150
            },
            {
                title: 'Postal Code',
                dataIndex: 'postalCode',
                key: 'postalCode',
                searchTextName: 'postalCode',
                width: 150
            },
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit City">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('settings.city.editCity', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete City">
                            <Popconfirm
                                title="Are you sure delete this City?"
                                onConfirm={() => {
                                    this.deleteCity({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All City: ${this.state.count}` : `All City: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCity)
