import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllPrices extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            zoneTypeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['DHL', 'Fedex', 'UPS Saver', 'Aramex']
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllPrice({
                ...params, regExFilters: ['zone'],

            })
            this.setState({count: data.total})
            console.log(data, "sdhfbfhrjfbrfjrbfyrh")
            resolve(data)
        })
    }


    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {zoneTypeList, serviceProviderName} = this.state


        const columns = [
            {
                title: 'Provider Name',
                dataIndex: 'serviceName',
                key: 'serviceName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),

                width: 150
            },
            {
                title: 'Weight',
                dataIndex: 'weight',
                key: 'weight',
                searchTextName: 'weight',
                width: 150
            },
            {
                title: 'Zone',
                dataIndex: 'zone',
                key: 'zone',
                searchTextName: 'zone',
                width: 150
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                searchTextName: 'amount',
                width: 150
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                filters: zoneTypeList && zoneTypeList.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Price">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('price.editPrice', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                        {/*<Tooltip title="Delete City">*/}
                        {/*<Popconfirm*/}
                        {/*title="Are you sure delete this City?"*/}
                        {/*onConfirm={() => {*/}
                        {/*this.deleteCity({_id: record._id})*/}
                        {/*}}*/}
                        {/*onCancel={() => {*/}
                        {/*console.log()*/}
                        {/*}}*/}
                        {/*okText="Yes"*/}
                        {/*cancelText="No">*/}
                        {/*<Button*/}
                        {/*shape="circle"*/}
                        {/*size="small"*/}

                        {/*icon="delete"*/}
                        {/*/>*/}
                        {/*</Popconfirm>*/}

                        {/*</Tooltip>*/}
                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Price: ${this.state.count}` : `All Price: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllPrices)
