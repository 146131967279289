import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Descriptions,
    Row,
    Col,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'
import styles from './styles.less'
import ProviderIcon from '../../../components/ProviderIcon'

@Form.create()
class quoteDetails extends React.Component {
    state = {
        quoteDetails: null
    }

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let quoteId = searchParams.get('id')

        if (quoteId) {
            let {data} = await Request.getQuoteById({_id: quoteId})
            console.log(data.shipmentResponse)
            if (data) {



                this.setState({
                    quoteDetails: data,
                    senderData: data.senderData,
                    receiverData: data.receiverData,
                    packages: data.packages,
                    pickupInformation: data.pickupInformation,
                    insuranceDetails: data.insurance,
                    shipmentData: data.shipmentObj,
                    customObligation: data.customObligation,
                    cinNumber:data.cinNumber,
                    cinFileUrl:data.cinFileUrl
                })
                console.log(this.state.customObligation)
            }
        }
    }
    cinFileFunc = () => {
        const { cinFileUrl } = this.state
        if (cinFileUrl && cinFileUrl.length) {
            if (cinFileUrl[cinFileUrl.length - 1].response) {
                window.location.href = cinFileUrl[cinFileUrl.length - 1].response.url
            } else if (cinFileUrl[cinFileUrl.length - 1].url) {
                window.location.href = cinFileUrl[cinFileUrl.length - 1].url
            }
        }
    }

    render() {
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props
        const {dispatch} = this.props

        const {quoteDetails, packages, receiverData, senderData, pickupInformation, insuranceDetails, shipmentData, customObligation,cinNumber,cinFileUrl} = this.state
        return (<PageHeaderWrapper title={'Quote Details'}>
            <Card bordered={false}>
                {quoteDetails && <div>
                    <Card bordered={false}>

                        <Row>
                            <Col span={24}>
                                <Card className={styles.formRow}>
                                    <Row>
                                        <Col span={12}>
                                            <h1>Packages</h1>
                                            {_.map(packages, (ii) => {

                                                if (quoteDetails && quoteDetails.packageType == 'Envelope') {
                                                    return (
                                                        <span><b>{`${ii.quantity} ${quoteDetails.packageType} of ${ii.weight}${ii.unit}`}</b></span>)
                                                } else {
                                                    return (
                                                        <span><b>{`${ii.quantity} ${quoteDetails.packageType} of ${ii.weight}${ii.unit} (${ii.length} x ${ii.width} x ${ii.height} cm)`}</b></span>
                                                    )

                                                }


                                            })}
                                        </Col>
                                        <Col span={12}>
                                            {quoteDetails && quoteDetails.serviceProviderName &&
                                            <ProviderIcon type={quoteDetails.serviceProviderName}/>}

                                        </Col>
                                    </Row>
                                </Card>


                                <div className={styles.formRowmew}>

                                    <div className={styles.card}>
                                        <Descriptions title="Sender Details" column={1} size={'small'}>
                                            <Descriptions.Item
                                                label={<b>Full
                                                    Name</b>}>{senderData && senderData.fullName}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Email</b>}>{senderData && senderData.email}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Phone</b>}>{senderData && senderData.phone}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={<b>Phone
                                                    Code</b>}>{senderData && senderData.phoneCode}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Address</b>}> {senderData && senderData.address}</Descriptions.Item>
                                            {senderData && senderData.addressLine && senderData.addressLine.length > 0 &&
                                            <Descriptions.Item label={
                                                <b>Address Line</b>}>{_.map(senderData.addressLine, (i, key) => {
                                                if (senderData.addressLine.length - 1 != key) {
                                                    return `${i},`

                                                } else {
                                                    return i
                                                }

                                            })}
                                            </Descriptions.Item>}
                                        </Descriptions>
                                    </div>
                                    <div className={styles.card}>
                                        <Descriptions title="Receiver Details" column={1} size={'small'}>
                                            <Descriptions.Item
                                                label={<b>Full
                                                    Name</b>}>{receiverData && receiverData.fullName}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Email</b>}>{receiverData && receiverData.email}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Phone</b>}>{receiverData && receiverData.phone}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={<b>Phone
                                                    Code</b>}>{receiverData && receiverData.phoneCode}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Address</b>}> {receiverData && receiverData.address}</Descriptions.Item>
                                            {receiverData && receiverData.addressLine && receiverData.addressLine.length > 0 &&
                                            <Descriptions.Item label={
                                                <b>Address Line</b>}>{_.map(receiverData.addressLine, (i, key) => {
                                                if (receiverData.addressLine.length - 1 != key) {
                                                    return `${i},`

                                                } else {
                                                    return i
                                                }

                                            })}
                                            </Descriptions.Item>}
                                        </Descriptions>
                                    </div>

                                </div>

                                <Card className={styles.formRow}>
                                    <Row><Col><h1>Pickup Information</h1></Col></Row>
                                    <Row>
                                        <Col span={6}>

                                            <b>Pickup
                                                Date
                                                :</b> {pickupInformation && moment(pickupInformation.pickupDate).format('DD-MM-YYYY')}
                                        </Col>
                                        <Col span={6}>
                                            <b>Pick Up :</b> {pickupInformation && pickupInformation.pickup}
                                        </Col>

                                        <Col span={6}>
                                            <b>Open Time :</b> {pickupInformation && pickupInformation.openTime}
                                        </Col>
                                        <Col span={6}>
                                            <b>Close Time :</b> {pickupInformation && pickupInformation.closeTime}


                                        </Col>
                                    </Row>
                                </Card>


                                <Card className={styles.formRow}>
                                    <Row>
                                        <Col span={6}>
                                            <Descriptions column={1} title={'Insurance Details'} size={'small'}>
                                                <Descriptions.Item
                                                    label={<b>Declared
                                                        Value</b>}>{insuranceDetails && insuranceDetails.declaredValue}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label={
                                                        <b>Currency</b>}>{insuranceDetails && insuranceDetails.currency}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label={
                                                        <b>Amount</b>}>{insuranceDetails && insuranceDetails.insureAmount}</Descriptions.Item>
                                            </Descriptions>


                                        </Col>
                                        <Col span={6}>
                                            <Descriptions column={1} title={'Shipment Details'} size={'small'}>
                                                <Descriptions.Item
                                                    label={<b>Shipment
                                                        Reason</b>}>{shipmentData && shipmentData.shipmentReason}</Descriptions.Item>
                                                <Descriptions.Item
                                                    label={
                                                        <b>Content</b>}>{shipmentData && shipmentData.content}</Descriptions.Item>

                                            </Descriptions>

                                        </Col>

                                    </Row>
                                </Card>
                                {cinNumber?<Card className={styles.formRowmew}>
                                    <h3>CIN NUMBER</h3>
                                    <div style={{ display: 'flex' }}>
                                        <h4>
                                            {cinNumber}
                                        </h4>
                                        {cinFileUrl&&cinFileUrl.length?<Button style={{ marginLeft: '5px', height: '24px' }} onClick={() => {
                                            this.cinFileFunc()
                                        }}>View File</Button>:''}
                                    </div>
                                </Card>:''}


                                {customObligation && customObligation.length > 0 && <Card>
                                    <Row>

                                        <Col span={24}>
                                            <h1>Custom Obligations</h1>
                                            <table border="1"
                                                   className={styles.table1}>
                                                <tbody>

                                                <tr>
                                                    <th>Description</th>
                                                    <th>Country
                                                        Name
                                                    </th>
                                                    <th>Currency</th>
                                                    <th>Quantity</th>
                                                    <th>Unit Price
                                                    </th>
                                                    <th>Total</th>

                                                </tr>
                                                {customObligation && _.map(customObligation, (item) => {
                                                    return <tr>
                                                        <td>{item.description}</td>
                                                        <td>{item.countryName}</td>
                                                        <td>{item.currency1}</td>

                                                        <td>{item.quantity}</td>
                                                        <td>{item.unitPrice}</td>
                                                        <td>{item.total}</td>


                                                    </tr>

                                                })}
                                                </tbody>

                                            </table>

                                        </Col>
                                    </Row>

                                </Card>
                                }


                            </Col>
                        </Row>
                    </Card>

                </div>}

            </Card>
        </PageHeaderWrapper>)
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(quoteDetails)
