import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../../request'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'


@Form.create()
class AddUser extends PureComponent {

    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                dispatch(showLoader())


                let x = null
                if (values.userType == 'Manager') values.userType = 'manager'
                else if (values.userType == 'Admin') values.userType = 'admin'
                else values.userType = 'affiliate'


                if (values.password !== values.confirmPassword) {
                    notification.error({
                        message: 'Password does not match.'
                    })
                    dispatch(hideLoader())


                } else {
                    x = await Request.addUser(values)

                    dispatch(hideLoader())


                    if (!x.error) {

                        notification.success({
                            message: 'User added Successfully'
                        })

                        if (!this.state.id) {
                            this.props.form.resetFields()
                        }


                    } else {
                        notification.error({
                            message: 'Error Saving',
                            description: x.msg
                        })
                    }
                }


            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)


    }

    render() {

        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props


        const inputTypes = {
            fields: [
                {
                    label: 'Full Name',
                    key: 'fullName',
                    required: true
                },
                // {
                //     label: 'Mobile',
                //     key: 'mobile',
                //     required: true
                // },
                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true,
                },
                {
                    label: 'Confirm Password',
                    key: 'confirmPassword',
                    type: 'password',
                    required: true,
                },
                {
                    label: 'User Type',
                    key: 'userType',
                    required: true,
                    type: 'select', options: ['Admin', 'Manager', 'Affiliate'],
                    onChange: v => {
                        this.props.form.setFieldsValue({ userType: v })
                    }

                },


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }


        return (
            <PageHeaderWrapper
                title={'Add User'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>

                        <GetAllFormFields apiurl={API_URL}
                            inputSchema={inputTypes} formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator} />

                        <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                style={{ width: '15%' }}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)
