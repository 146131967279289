import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Modal,
    Tag,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Select,
    Row,
    Col,
    Divider,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import {FaWeightHanging} from 'react-icons/fa'
import {AiOutlineEye, AiOutlineCodeSandbox} from 'react-icons/ai'
import {GiCube} from 'react-icons/gi'

import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import JSONPretty from 'react-json-pretty';
import JSONPrettyMon from 'react-json-pretty/dist/monikai'
import ScheduleForm from '../../../components/schedulePickup'
import DhlTrackingDetails from '../../../components/Tracking/dhlTrackingDetails'
import AramexTrackingDetails from '../../../components/Tracking/aramexTrackingDetails'
import UpsTrackingDetails from '../../../components/Tracking/upsTrackingDetails'

const {Option} = Select

class PendingShipment extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            selectedRowKeys: [],
            visible: false,
            key: Date.now(),
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver']

        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.quoteStatus = ['Payment Failed', 'Canceled']
            const {Option} = Select
            if (this.state.userId) {
                params.userId = this.state.userId
            }
            if (this.state.affiliateUserId) {
                params.affiliatedUser = this.state.affiliateUserId
            }

            let data = await Request.getAllQuote({
                ...params, regExFilters: ['receiverData.fullName', 'senderData.fullName']

            })

            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = (resp) => {
        this.setState({
            shipmentResponse: resp,
            visible: true
        })
    }
    handleTrackingModal = async (trackingData) => {
        let data = await Request.trackPackage({
            shipmentType: trackingData.serviceProviderName,
            trackingNumber: trackingData.shipmentSanitizeResponse.trackingNumber[0]
        })
        if (data.success) {
            this.setState({
                packages: data.activity,
                data: data,
                showTrackingModal: true,
                shipmentType: trackingData.serviceProviderName
            })
        } else {
            notification.error({
                message: data.status
            })
        }
    }
    onTrackingClose = () => {
        this.setState({
            showTrackingModal: false
        })
    }

    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({})
        let {data: allAffiliateUsers} = await Request.loadUser({userType:'affiliate'})
        this.setState({allUsers,allAffiliateUsers})
    }

    handleShowModal = (quote) => {
        this.setState({showModal: true, quoteId: quote._id, quoteDetails: quote})

    }

    handleCancel = (reloadTable) => {
        this.setState({
            showModal: false,
            btnLoading: false,
        });
    };

    onSelectChange = selectedRowKeys => {
        this.setState({selectedRowKeys});
    };
    voidShipment = async (id) => {
        let shipment = await Request.voidShipment({_id: id})
        if (shipment.error) {
            notification.error({
                message: shipment.message
            })
        } else {
            notification.success({
                message: shipment.message
            })
        }
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {allAffiliateUsers,
            packageType, shipmentResponse, showTrackingModal, quoteDetails,
            packages, shipmentType, data, typeList, selectedRowKeys, serviceProviderName, showModal, allUsers
        } = this.state


        const columns = [
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                fixed: 'left',
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="View Details">
                            <a href={`/shipmentDetails?id=${record._id}`} target={'_blank'}>

                                <Button icon={'eye'} shape={'circle'}/>

                            </a>
                        </Tooltip>
                        <Tooltip title="View Shipment Response">
                            <Button style={{marginLeft: '5px'}} icon={'read'} shape={'circle'} onClick={() => {
                                // this.setState({})
                                this.showDrawer(record.shipmentResponse)
                            }}/>

                        </Tooltip>

                    </React.Fragment>
                }
            },
            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },
            {
                title: 'Status',
                dataIndex: 'quoteStatus',
                key: 'quoteStatus',
                render: (val, r) => {
                    return <Tag style={{marginRight: '10px'}}
                                color={val === 'Canceled' ? "#FF0000" : "#87d068"}>{val}</Tag>
                }

            },
            {
                title: 'Payment Response',
                dataIndex: 'paymentMode',
                key: 'paymentMode',
                width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && <>Payment Mode : {record.paymentMode}</>}</div>
                        {record && record.paymentResponse && record.paymentResponse.notes &&
                        <>
                            <small> Notes : {record.paymentResponse.notes}</small>
                            <br/>
                            <small> Reference No. : {record.paymentResponse.TransId}</small>
                            <br/>
                            <small> Payment Date
                                : {moment(record.paymentResponse.paymentDate).format('DD-MMM-YYYY')}</small>
                            <br/>
                            <small> Amount : {record.paymentResponse.amount}</small>

                        </>}
                    </div>
                }

            },


            {
                title: 'Canceled Date',
                key: 'shipmentSanitizeResponse.cancelDate',
                sorter: true,
                //width: 150,
                render: (val, record) => {
                    let {cancelDate} = record && record.shipmentSanitizeResponse
                    return <div>
                        {cancelDate && moment(cancelDate).format('DD-MMM-YYYY')}
                    </div>
                }
            },
            {
                title: 'Provider Name',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),
                width: 150
            },
            {
                title: 'Sender Details',
                key: 'senderData.fullName',
                searchTextName: 'Sender Name',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.senderData && record.senderData.fullName}</div>
                        <div>{record && record.senderData && record.senderData.address}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.cityName}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.countryISO}</div>
                        <div>{record && record.pickupLocation && record.pickupLocation.zipCode}</div>

                    </div>)
                }
            },
            {
                title: 'Receiver Details',
                key: 'receiverData.fullName',
                searchTextName: 'Receiver Name',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.receiverData && record.receiverData.fullName}</div>
                        <div>{record && record.receiverData && record.receiverData.address}</div>
                        <div>{record && record.packageDestination && record.packageDestination.cityName}</div>
                        <div>{record && record.packageDestination && record.packageDestination.countryISO}</div>
                        <div>{record && record.packageDestination && record.packageDestination.zipCode}</div>

                    </div>)
                }
            },
            {
                title: 'User Details',
                key: 'userId',
                width: 200,
                dataIndex: 'userId',
                render: (val, record) => {

                    return <div>
                        <div>Name : {val && val.fullName}</div>
                        <div>Email : {val && val.email}</div>

                    </div>
                }
            },
            {
                title: 'Affiliated Commission Amt (%)',
                dataIndex: 'affiliatedUserCommission',
                key: 'affiliatedUserCommission',
                searchTextName: 'affiliatedUserCommission',
                width: 100,
                render: (val, r) => {
                    return val && <div>{`${val} MAD`}({r.affiliatedUserCommissionPercentage} %)</div>
                }

            },
            {
                title: ' Affiliated User Details',
               width: 200,
                dataIndex: 'affiliatedUser',
                render: (val, record) => {

                    return val&&<div>
                        <div>Name :{record && record.affiliatedUser && record.affiliatedUser.fullName}</div>
                       <div>Email :{record && record.affiliatedUser && record.affiliatedUser.email}</div>

                    </div>
                }
            },

            {
                title: 'Details',
                key: 'type',
                width: 150,
                render: (val, record) => {
                    if (record && record.packages.length) {
                        if (record.packages.length > 1) {
                            return <div>{_.map(record.packages, (ii, key) => {
                                if (parseInt(key) != record.packages.length - 1) {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>
                                        <Divider/>
                                    </div>
                                } else {
                                    return <div>
                                        <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                        </div>
                                        <div>
                                            <FaWeightHanging/> {ii.weight} {ii.unit}
                                        </div>
                                        <div>
                                            <GiCube/> {ii.quantity}
                                        </div>

                                    </div>
                                }
                            })}</div>
                        } else {
                            return <span> {_.map(record.packages, (ii, key) => {
                                return <div>
                                    <div><AiOutlineCodeSandbox/> {`${ii.length} x ${ii.width} x ${ii.height}`}
                                    </div>
                                    <div>
                                        <FaWeightHanging/> {ii.weight} {ii.unit}
                                    </div>
                                    <div>
                                        <GiCube/> {ii.quantity}
                                    </div>

                                </div>

                            })}</span>
                        }
                    }


                }

            },

            {
                title: 'Quoted Price',
                key: 'netAmount',
                searchTextName: 'netAmount',
                // width: 150,
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                        <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },

            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },
            {
                title: 'Updated At',
                key: 'updatedAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.updatedAt ? moment(record.updatedAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },


        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `Expéditions Annulées: ${this.state.count}` : `Expéditions Annulées: 0`}>

                <Card bordered={true}>
                    <Row>
                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select User Name"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allUsers &&
                                allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                        <Col span={10}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Affiliate User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Affiliate User Name"
                                onChange={id => {
                                    this.setState({affiliateUserId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allAffiliateUsers &&
                                allAffiliateUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                       
                    </Row>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        // checkBox={this.onSelectChange}
                               extraProps={{
                                   scroll: {x: 1800}
                               }}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=" Shipment Response"
                    forceRender={true}
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div style={{marginLeft: 20, marginRight: 20, marginTop: 20}}>
                        <JSONPretty data={JSON.stringify(shipmentResponse, undefined, 14)}
                                    theme={JSONPrettyMon}></JSONPretty>
                    </div>


                </Drawer>
                <ScheduleForm handleCancel={this.handleCancel.bind(this)} showModal={showModal}
                              quoteId={this.state.quoteId} quoteDetails={quoteDetails}/>
                <Modal
                    title="Shipment Tracking"
                    width={500}
                    footer={null}
                    onCancel={this.onTrackingClose}
                    visible={showTrackingModal}>
                    {shipmentType === 'UPS Saver' &&
                    <UpsTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                    {shipmentType === 'Aramex' &&
                    <AramexTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                    {shipmentType === 'DHL' && <DhlTrackingDetails details={packages} data={data} buttonFlag={false}/>}
                </Modal>
            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PendingShipment)
