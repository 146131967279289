import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllCountry extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now()}

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllCountry({
                ...params, regExFilters: ['countryName', 'frenchName'],

            })
            this.setState({count: data.total})
            resolve(data)
        })
    }


    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {} = this.state


        const columns = [
            {
                title: 'Country Name',
                dataIndex: 'countryName',
                key: 'countryName',
                searchTextName: 'countryName',
                width: 150
            },
            {
                title: 'ISO Code',
                dataIndex: 'isoCode',
                key: 'isoCode',
                searchTextName: 'isoCode',
                width: 150
            },
            {
                title: 'French Name',
                dataIndex: 'frenchName',
                key: 'frenchName',
                searchTextName: 'frenchName',
                width: 150
            },
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Country">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('settings.country.editCountry', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Country: ${this.state.count}` : `All Country: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 50,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCountry)
