import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'

class AllFuelsCharge extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(), serviceName: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'], vatArray: []
        }

    }

    deleteFuelCharge = async data => {
        this.setState({loading: true})
        let x = await Request.deleteFuelCharge(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.msg,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }

    apiRequest = (params) => {
        return new Promise(async (resolve) => {

            let data = await Request.getAllFuelCharge({
                ...params,

            })
            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    componentDidMount() {
    }

    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {serviceName, vatArray, amountTableHeading} = this.state


        const columns = [
            {
                title: 'Provider Name',
                dataIndex: 'serviceName',
                key: 'serviceName',
                filters: serviceName && serviceName.map(x => ({value: x, text: x})),

                width: 100
            },
            {
                title: 'Fuel Surcharge (%)',
                dataIndex: 'values.fuelSurcharge',
                key: 'values.fuelSurcharge',
                searchTextName: 'Fuel Surcharge',
                width: 100
            },
            {
                title: 'Vat(%)',
                dataIndex: 'values.vat',
                key: 'values.vat',
                // searchTextName: 'vat',
                width: 100,
                render: (item, record) => {
                    return <React.Fragment>
                        <Tooltip title="View Range">

                            <Button style={{marginRight: 6}}
                                    size="small"
                                    onClick={() => {
                                        let amountHeading = ""
                                        if (record && record.serviceName == 'Fedex') {
                                            amountHeading = 'Amount'
                                        } else {
                                            amountHeading = 'Amount(%)'

                                        }

                                        this.setState({
                                            amountTableHeading: amountHeading,
                                            vatArray: record && record.values && record.values.vat
                                        }, () => {
                                            console.log(this.state.amountTableHeading, "0000000000000000000000000")
                                            this.showDrawer()
                                        })


                                    }}>View Range </Button>

                        </Tooltip>
                    </React.Fragment>

                }

            },
            {
                key: 'actions',
                title: 'Action',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Fuel Charge">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('settings.fuelCharge.editFuel', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>

                        <Tooltip title="Delete Fuel Charge">
                            <Popconfirm
                                title="Are you sure delete this Fuel Charge?"
                                onConfirm={() => {
                                    this.deleteFuelCharge({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>

                    </React.Fragment>
                }
            }


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Fuel & Vat Charge: ${this.state.count}` : `All Fuel & Vat Charge: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 50,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title="Range Details"
                    forceRender={true}
                    placement="right"
                    closable={true}
                    onClose={() => {

                        this.setState({
                            visible: false
                        })
                    }}
                    visible={this.state.visible}
                    width={800}>
                    <div className={styles.drawerDiv}>
                        <table className={styles.dataTable}>
                            <thead>
                            <tr>
                                <th>{amountTableHeading}</th>
                                <th>Minimum Weight</th>

                                <th>Maximum Weight</th>


                            </tr>
                            </thead>

                            <tbody>
                            {vatArray && _.map(vatArray, (item, key) => {
                                return <tr>
                                    <td key={key}>{item.amount}</td>
                                    <td key={key + 1}>{item.min}</td>
                                    <td key={key + 2}>{item.max}</td>


                                </tr>

                            })}

                            </tbody>


                        </table>


                    </div>


                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllFuelsCharge)
