import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils-lite'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    DatePicker,
    Popconfirm,
    notification,
    Drawer,
    Form,
    Select,
    Switch,
    Row, Col,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import {connect} from 'react-redux'
import {API_URL} from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'

const {RangePicker} = DatePicker
const {Option} = Select

@Form.create()
class AllQuotes extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false,
            key: Date.now(),
            time: {
                key: 'pickupInformation.pickupDate',
                from: null,
                to: null
            },
            packageType: ['Parcel', 'Envelope', 'Pallet'],
            typeList: ['INBOUND', 'OUTBOUND'],
            serviceProviderName: ['Aramex', 'Fedex', 'DHL', 'UPS Saver']
        }

    }


    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            params.serviceProviderName = ['Aramex', 'Fedex', 'DHL', 'UPS Saver']

            if (this.state.userId) {
                params.userId = this.state.userId
            }
            let data = await Request.getAllQuote({
                ...params,
                dateFilter: this.state.time,


            })
            this.setState({count: data.total})
            resolve(data)
        })
    }
    chooseRangerPicker = (date, dateString) => {
        this.setState(
            {
                time: {
                    key: 'pickupInformation.pickupDate',
                    from: date[0],
                    to: date[1]
                }
            }, () => {
                if (date[0] == undefined) {
                    this.table.current.reload()
                }
            }
        )

    }

    onOk = value => {
        this.table.current.reload()
    }


    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({})
        console.log(allUsers, "--------------------------------")
        this.setState({allUsers})
    }


    render() {
        const {submitting, dispatch, currentUser} = this.props
        const {packageType, typeList, serviceProviderName, allUsers} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }

        const columns = [
            {
                key: 'actions',
                title: 'Action',
                fixed: 'left',
                width: 100,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="View Details">
                            <a href={`/quoteDetails?id=${record._id}`} target={'_blank'}>

                                <Button style={{marginRight: '5px'}} icon={'eye'} shape={'circle'}/>

                            </a>
                        </Tooltip>


                    </React.Fragment>
                }
            },
            {
                title: 'Order Id',
                dataIndex: 'orderId',
                key: 'orderId',
                searchTextName: 'orderId',
                width: 100,
                fixed: 'left',

            },

            {
                title: 'Package Type',
                dataIndex: 'packageType',
                key: 'packageType',
                filters: packageType && packageType.map(x => ({value: x, text: x})),
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                filters: typeList && typeList.map(x => ({value: x, text: x})),

            },
            {
                title: 'Service Provider',
                dataIndex: 'serviceProviderName',
                key: 'serviceProviderName',
                filters: serviceProviderName && serviceProviderName.map(x => ({value: x, text: x})),

                // fixed: 'left',
            },
            {
                title: 'Pickup Location',
                key: 'pickupLocation',
                render: (val, record) => {
                    return (<div>
                        <div>{val && val.countryISO}</div>
                        <div>{val && val.zipCode}</div>
                    </div>)
                }
            },
            {
                title: 'Package Destination',
                key: 'packageDestination',
                render: (val, record) => {
                    return (<div>
                        <div>{val && val.countryISO}</div>
                        <div>{val && val.zipCode}</div>
                    </div>)
                }
            },

            {
                title: 'Pickup Date',
                key: 'pickupInformation.pickupDate',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.pickupInformation && record.pickupInformation.pickupDate ? moment(record.pickupInformation.pickupDate).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },
            {
                title: 'Total Amount',
                key: 'netAmount',
                searchTextName: 'netAmount',
                render: (val, record) => {
                    return <div>
                        <div>{record && record.netAmount} MAD</div>
                        {record && record.totalAmountWithTax &&
                        <small>Amount with tax : {record.totalAmountWithTax} MAD</small>}
                    </div>
                }
            },
            {
                title: 'User Details',
                key: 'userId',
                width: 200,
                dataIndex: 'userId',
                render: (val, record) => {
                 
                    return <div>
                        <div>Name : {val && val.fullName}</div>
                        <div>Email : {val && val.email}</div>

                    </div>
                }
            },
            {
                title: 'Created At',
                key: 'createdAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.createdAt ? moment(record.createdAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },
            {
                title: 'Updated At',
                key: 'updatedAt',
                sorter: true,
                render: (val, record) => {
                    return <div>
                        {record && record.updatedAt ? moment(record.updatedAt).format('DD-MMM-YYYY') : null}
                    </div>
                }
            },


        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Devis: ${this.state.count}` : `Devis: 0`}>

                <Card className={styles.dateCard}>

                    <Row>
                        <Col span={12}>

                            <h3 style={{display: 'inline-block', marginRight: '10px'}}
                                className="search_title">Pickup Date</h3>

                            <RangePicker
                                showTime={{format: 'HH:mm'}}
                                format="YYYY-MM-DD HH:mm"
                                placeholder={['Start Time', 'End Time']}
                                onChange={this.chooseRangerPicker}
                                onOk={this.onOk}
                            />
                        </Col>
                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select User Name</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select User Name"
                                onChange={id => {
                                    this.setState({userId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {allUsers &&
                                allUsers.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && val.fullName} ({val && val.email})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>
                    </Row>

                </Card>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1200}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllQuotes)
