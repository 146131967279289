import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Input, Button, Steps, Card, Descriptions, Timeline } from 'antd'
import Request from '../../request'
import styles from './styles.less'
import _ from 'lodash'
import moment from 'moment'
const { Step } = Steps

class DHLTracking extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            message: ''
        }

    }

    async componentDidMount() {

    }

    handleSubmit = async (valData) => {
        this.setState({ loading: true, message: null })
        let data = await Request.trackPackage(valData)
        this.setState({ message: data.status || 'Invalid tracking number', loading: false })
    }
    render() {
        let { details, data, onBack, buttonFlag } = this.props
      
        let { message, loading } = this.state
        return (<>
            {data && data.arr && <div className={'steps'}>
                <Timeline mode='left'>
                    {_.flatMap(data.arr, (val, index) => {
                        return (
                            <Timeline.Item color='green' 
                            >
                                {<>
                                    <div><b>{val.country} {val.city}</b> </div>
                                    <div>{`${val.msg}`}</div>
                                    <div>{`Date: ${val.date} ${val.time}`}</div>
                                </>}
                            </Timeline.Item>
                        )
                    })}
                </Timeline>
            </div>}
            <div className='description'>
                <Descriptions bordered size='small' layout='vertical' >
                    <Descriptions.Item span={2} label={'TrackingNumber'}>{data.trackingNumber}</Descriptions.Item>
                    <Descriptions.Item span={1} label={'Pieces'}>{data.shipmentData.pieces}</Descriptions.Item>
                    <Descriptions.Item span={3} label={'From'}>{data.shipmentData.shipperAddress.fullAddress}</Descriptions.Item>
                    <Descriptions.Item span={3} label={'To'}>{data.shipmentData.shipToAddress.fullAddress}</Descriptions.Item>
                </Descriptions>
            </div>

        </>)

    }

}

const mapStateToProps = ({ counter, router }) => ({
    count: counter.count,
    isIncrementing: counter.isIncrementing,
    isDecrementing: counter.isDecrementing,
    search: router.location.search,
    btnLoading: counter.btnLoading

})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DHLTracking)
