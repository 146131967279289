import React from 'react'
import {Route, Link, Redirect} from 'react-router-dom'

import styles from './login.less'
import {
    Form, Icon, Input, Button, Checkbox, notification
} from 'antd'

import Request from '../../request'
import {showLoader, hideLoader} from '../../modules/actions'
import {apiUrl} from '../../settings'


import {push} from 'connected-react-router'
import {connect} from 'react-redux'

class NormalLoginForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    handleSubmit = (e) => {
        const {dispatch} = this.props
        e.preventDefault()


        this.props.form.validateFields(async (err, values) => {
            if (!err) {

                // dispatch(userActions(values))

                dispatch(showLoader())

                let x = await Request.login(values)

                dispatch(hideLoader())

                if (!x.error) {

                    localStorage.setItem('token', x.token)
                    localStorage.setItem('user', JSON.stringify(x.user))

                    dispatch({
                        type: 'SET_CURRENT_USER',
                        user: x.user
                    })

                    setTimeout(() => {
                        dispatch(push('/'))
                    }, 300)

                } else {
                    notification.error({
                        message: 'Invalid Login',
                        description: x.message
                    })

                }
            }
        })

    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {loading} = this.props
        return (
            <Form onSubmit={this.handleSubmit} className={styles.main}>
                <Form.Item>
                    {getFieldDecorator('email', {
                        rules: [{required: true, message: 'Please enter your email address!'}]
                    })(
                        <Input style={{height: '45px'}} prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                               placeholder="Email address"/>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{required: true, message: 'Please input your Password!'}]
                    })(
                        <Input style={{height: '45px'}} prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                               type="password"
                               placeholder="Password"/>
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )}
                    <a className={styles.forgot} href="">Forgot password</a>
                    <div className={styles.loginButton}>
                        <Button type="primary" loading={loading} htmlType="submit" className={styles.button}>
                            LOGIN
                        </Button>
                    </div>
                    {/*Or <a href="">register now!</a>*/}
                </Form.Item>
                {/*<div>Or</div>*/}
                {/*<Form.Item>*/}

                {/*<Button type="primary" loading={loading} htmlType="submit" className={styles.button}*/}
                {/*style={{ backgroundColor: 'blue' }}*/}
                {/*onClick={() => {*/}
                {/*window.open(`${apiUrl}/auth/facebook`, '_blank')*/}

                {/*}}>*/}
                {/*Log in With Facebook*/}
                {/*</Button>*/}
                {/*</Form.Item>*/}

            </Form>
        )
    }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm)


const mapStateToProps = ({counter, global}) => ({
    count: counter.count,
    loading: global.buttonLoading
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WrappedNormalLoginForm)
