import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Row,
    Col,
    Table,
    InputNumber,
    Tooltip, Popconfirm,
    Icon
} from 'antd'
import {ChromePicker} from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils-lite'
import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from '../../../routes'
import Styles from '../../fuelSurcharge/all/styles.less'

const FormItem = Form.Item
const {Option} = Select

@Form.create()
class FuelCharge extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            major: '',
            customerList: [],
            totalData: [],
            productArray: [],
            showButton: true
        }
        this.AddData = _.debounce(this.addData, 1000, true)


    }

    handleSubmit = e => {
        const {dispatch, form, handleSubmit} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                let obj = {
                    serviceName: values.serviceName,
                    values: {
                        vat: this.state.totalData,
                        fuelSurcharge: values.fuelSurcharge
                    }
                }

                if (this.state.totalData.length == 0) {
                    return notification.error({
                        message: 'Add Atleast One Range'

                    })
                }

                console.log(obj)

                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    obj._id = this.state.id
                    x = await Request.updateFuelCharge(obj)
                } else {
                    x = await Request.addFuelCharge(obj)
                }

                dispatch(hideLoader())

                if (!x.error) {
                    if (!this.state.id) {
                        this.props.form.resetFields()

                    }


                    notification.success({
                        message: this.state.id
                            ? x.msg
                            : x.msg
                    })

                    if (this.state.id) {
                        dispatch(goBack())

                    }
                    this.props.form.setFieldsValue({})
                    this.setState({totalData: [], showCard: false})

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.msg
                    })
                }

            }
        })
    }
    addData = () => {

        const {
            amount, min, max, totalData
        } = this.state
        let providerName = this.props.form.getFieldValue('serviceName')
        if (amount == null) {
            return notification.error({
                message: 'Enter Amount'
            })
        }
        if (amount < 0) {
            return notification.error({
                message: 'Enter a valid Amount'
            })
        }

        if (!max) {
            return notification.error({
                message: 'Enter Maximum Value'
            })
        }

        if (!min) {
            return notification.error({
                message: 'Enter Minimum Value'
            })
        }


        if (max < min || max == min) {
            return notification.error({
                message: 'Maximum Value in greater than Minimum Value'
            })
        }
        let isDuplicateRange = false
        _.each(totalData, (i) => {
            if (i.min <= min && min <= i.max) {
                isDuplicateRange = true
                return;
            }
            if (i.min <= max && max <= i.max) {
                isDuplicateRange = true
                return;
            }

            if (min <= i.min && i.min <= max) {
                isDuplicateRange = true
                return;
            }

            if (min <= i.max && i.max <= max) {
                isDuplicateRange = true
                return;
            }


        })
        if (isDuplicateRange) {
            return notification.error({
                message: 'Range already exists.'
            })
        }

        if (this.state.min && this.state.max) {

            let tt = this.state.totalData
            tt.push({
                amount: this.state.amount,
                min: this.state.min,
                max: this.state.max,
                serviceName: providerName
            })
            if (totalData.length == 1 && providerName != 'Fedex') {
                this.setState({showButton: false})
            }

            this.props.form.setFieldsValue({amount: null, max: null, min: null})

            this.setState({
                totalData: tt,
                amount: null,
                min: null,
                max: null,

            }, () => {

            })

        }

    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)

        let fuelId = searchParams.get('id')
        if (fuelId) {
            Request.getFuelChargeById({_id: fuelId}).then(({data, error, message}) => {
                console.log(data, "dsddd")
                if (!error) {
                    this.setState({
                        id: fuelId
                    })

                    this.props.form.setFieldsValue({
                        serviceName: data && data.serviceName,
                        fuelSurcharge: data && data.values && data.values.fuelSurcharge,


                    })
                    if (data && data.serviceName !== 'Fedex') {
                        this.setState({showButton: false, amountTableHeading: 'Amount(%)'})
                    } else {
                        this.setState({amountTableHeading: 'Amount'})

                    }
                    this.setState({totalData: data && data.values && data.values.vat, showCard: true})
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }

    }

    render() {

        const {submitting} = this.props
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {amountTableHeading} = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Provider',
                    key: 'serviceName',
                    required: true,
                    type: 'select',
                    customProps: {
                        style: {
                            width: '60%'
                        }
                    },
                    options: ['DHL', 'Fedex', 'UPS Saver', 'Aramex'],
                    onChange: (mode) => {
                        let amountHeading = ""
                        if (mode == 'Fedex') {
                            amountHeading = 'Amount'
                        } else {
                            amountHeading = 'Amount(%)'

                        }


                        this.setState({
                            showCard: true,
                            min: null,
                            max: null,
                            amount: null,
                            totalData: [],
                            showButton: true,
                            amountTableHeading: amountHeading
                        })
                        this.props.form.setFieldsValue({serviceName: mode, fuelSurcharge: ''})
                    }

                },
                {
                    label: 'Fuel Charge',
                    key: 'fuelSurcharge',
                    required: true,
                    rightComp: <b>%</b>,
                    type: 'number'


                },


            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }
        const refColumns = [
            {
                title: amountTableHeading,
                dataIndex: 'amount',
                key: 'amount'
            },

            {
                title: 'Min Value',
                dataIndex: 'min',
                key: 'min'


            },
            {
                title: 'Max Value',
                dataIndex: 'max',
                key: 'max',


            },

            {
                title: 'Action',
                key: 'operation',
                fixed: 'right',
                width: 150,
                render: (item, record) => (
                    <React.Fragment>

                        <Tooltip title="Delete Fuel Charge">
                            <Popconfirm
                                title="Are you sure delete this Fuel Charge?"
                                onConfirm={() => {
                                    let tt = this.state.totalData
                                    tt = _.reject(tt, item)
                                    this.setState({totalData: tt})
                                    console.log(tt)
                                    if (tt.length == 0) {
                                        this.setState({showButton: true})
                                    }
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    // style={{color: 'red'}}
                                    shape="circle"
                                    size="small"
                                    type={'danger'}
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>

                        {/*<a*/}
                        {/*onClick={() => {*/}
                        {/*// let tt = this.state.totalData*/}
                        {/*// tt = _.reject(tt, item)*/}
                        {/*// this.setState({totalData: tt})*/}
                        {/*// console.log(tt)*/}
                        {/*// if (tt.length == 0) {*/}
                        {/*//     this.setState({showButton: true})*/}
                        {/*// }*/}
                        {/*}}>*/}
                        {/*Remove*/}
                        {/*</a>*/}

                    </React.Fragment>
                )
            }
        ]


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Fuel & Vat Charge' : 'Add Fuel & Vat Charge'}
            >
                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Row>
                            <Col>


                                <GetAllFormFields apiurl={API_URL}
                                                  inputSchema={inputTypes} formItemLayout={formItemLayout}
                                                  getFieldDecorator={getFieldDecorator}/>


                                {this.state.showCard && <Card bordered={true} title="Vat Details" size={'small'}>
                                    <div className={Styles.mainDiv}>

                                        {this.state.showButton && <div style={{display: 'flex', marginBottom: '15px'}}>

                                            <div>
                                                <Row>
                                                    <Col span={24}>{amountTableHeading} </Col>
                                                    <Col span={24}>

                                                        <Form.Item {...formItemLayout}>
                                                            {getFieldDecorator('amount', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Invalid Amount',
                                                                    validator: (rule, value, callback) => {
                                                                        if (value < 0) {
                                                                            callback('Invalid Amount')
                                                                        }
                                                                        callback()
                                                                    }
                                                                }]
                                                            })(
                                                                <InputNumber
                                                                    value={this.state.amount}
                                                                    style={{width: 150}}
                                                                    onChange={ei => {
                                                                        this.setState({amount: ei})

                                                                    }}
                                                                />)}
                                                        </Form.Item>


                                                    </Col>
                                                </Row>
                                            </div>

                                            <div>
                                                <Row>
                                                    <Col span={24}> Min Value</Col>
                                                    <Col span={24}>

                                                        <Form.Item {...formItemLayout}>
                                                            {getFieldDecorator('min', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Invalid Value',
                                                                    validator: (rule, value, callback) => {
                                                                        if (value < 0) {
                                                                            callback('Invalid Value')
                                                                        }
                                                                        callback()
                                                                    }
                                                                }]
                                                            })(
                                                                <InputNumber
                                                                    value={this.state.min}
                                                                    style={{width: 150}}
                                                                    onChange={ei => {
                                                                        this.setState({min: ei})

                                                                    }}
                                                                />)}
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col span={24}>Max Value</Col>
                                                    <Col span={24}>

                                                        <Form.Item {...formItemLayout}>
                                                            {getFieldDecorator('max', {
                                                                rules: [{
                                                                    required: true,
                                                                    message: 'Invalid Value',
                                                                    validator: (rule, value, callback) => {
                                                                        if (value < 0) {
                                                                            callback('Invalid Value')
                                                                        }
                                                                        callback()
                                                                    }
                                                                }]
                                                            })(
                                                                <InputNumber
                                                                    value={this.state.max}
                                                                    style={{width: 150}}
                                                                    onChange={ei => {
                                                                        this.setState({max: ei})

                                                                    }}
                                                                />)}
                                                        </Form.Item>

                                                    </Col>
                                                </Row>
                                            </div>


                                            <div>
                                                <Button
                                                    style={{
                                                        marginBottom: '9px',
                                                        marginTop: '25px',
                                                        height: '31px'
                                                    }}
                                                    size={'small'}
                                                    type="primary"
                                                    onClick={() => {
                                                        this.AddData()

                                                    }}>
                                                    ADD

                                                </Button>
                                            </div>

                                        </div>}


                                    </div>


                                    <div>
                                        <Row> <Col span={24}>
                                            {this.state && this.state.totalData.length > 0 && <Table
                                                bordered={true}
                                                rowKey={x => x.amount}
                                                columns={refColumns}
                                                dataSource={this.state.totalData || []}
                                                pagination={false}
                                            />}
                                        </Col>
                                        </Row>
                                    </div>
                                </Card>}


                            </Col></Row>


                        <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                            <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                                    style={{width: '15%'}}>
                                {this.state.id ? `UPDATE` : `SAVE`}
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FuelCharge)
