import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Tooltip,
    Popconfirm,
    Modal,
    Card,
    Alert,
    Descriptions,
    Table,
    Tag,
    Row,
    Col,
    Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils-lite'
import { API_URL } from '../../../request'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'
import { getUrlPushWrapper } from '../../../routes'
import styles from './styles.less'
import ProviderIcon from '../../../components/ProviderIcon'
import ScheduleForm from '../../../components/schedulePickup'
import DhlTrackingDetails from '../../../components/Tracking/dhlTrackingDetails'
import AramexTrackingDetails from '../../../components/Tracking/aramexTrackingDetails'
import UpsTrackingDetails from '../../../components/Tracking/upsTrackingDetails'

@Form.create()
class orderDetails extends React.Component {
    state = {
        quoteDetails: null,
        trackingResp: [],
        start: [],
    }

    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        this.apiRequest()
    }

    handleCancel = (reloadTable) => {

        this.apiRequest()
        this.setState({
            showModal: false,
            btnLoading: false,
        });
    };
    apiRequest = async () => {
        let searchParams = new URLSearchParams(this.props.search)

        let quoteId = searchParams.get('id')

        if (quoteId) {
            let { data } = await Request.getQuoteById({ _id: quoteId })
            console.log(data)
            if (data) {

                this.setState({
                    quoteId: data._id,
                    quoteDetails: data,
                    quoteErrors: data.quoteErrors,
                    senderData: data.senderData,
                    receiverData: data.receiverData,
                    packages: data.packages,
                    pickupInformation: data.pickupInformation,
                    insuranceDetails: data.insurance,
                    shipmentData: data.shipmentObj,
                    customObligation: data.customObligation,
                    serviceProviderName: data.serviceProviderName,
                    shipmentSanitizeResponse: data.shipmentSanitizeResponse,
                    paymentResponse: data.paymentResponse,
                    trackingResp: data.trackingResp,
                    pickUpResp: data.pickUpResp,
                    cinNumber: data.cinNumber,
                    cinFileUrl: data.cinFileUrl,
                    affiliatedUser: data.affiliatedUser,
                    affiliatedUserCommission: data.affiliatedUserCommission,
                    affiliatedUserCommissionPercentage: data.affiliatedUserCommissionPercentage
                })
            }
        }
    }
    handleTrackingModal = async (trackingData) => {
        this.setState({ trackButton: true })

        let data = await Request.trackPackage({
            shipmentType: trackingData.serviceProviderName,
            trackingNumber: trackingData && trackingData.shipmentSanitizeResponse && trackingData.shipmentSanitizeResponse.trackingNumber && trackingData.shipmentSanitizeResponse.trackingNumber[0]
        })
        this.setState({ trackButton: false })

        if (data.success) {
            this.setState({
                packagesDetails: data.activity,
                data: data,
                showTrackingModal: true,
                shipmentType: trackingData.serviceProviderName
            })
        } else {
            notification.error({
                message: data.status
            })
        }
    }
    onTrackingClose = () => {
        this.setState({
            showTrackingModal: false
        })
    }


    handleShowModal = (quote) => {
        this.setState({ showModal: true, quoteId: quote._id, quoteFullDetails: quote })

    }
    voidShipment = async (id) => {
        this.setState({ voidButton: true })
        let shipment = await Request.voidShipment({ _id: id })
        this.setState({ voidButton: false })

        if (shipment.error) {
            notification.error({
                message: shipment.message
            })
        } else {
            notification.success({
                message: shipment.message
            })
        }
        this.apiRequest()
    }

    setLoader = (key, value) => {
        let { start } = this.state
        let newLoading = _.clone(start)
        newLoading[key] = value
        this.setState({ start: newLoading })
    }

    cinFileFunc = () => {
        const { cinFileUrl } = this.state
        if (cinFileUrl && cinFileUrl.length) {
            if (cinFileUrl[cinFileUrl.length - 1].response) {
                window.location.href = cinFileUrl[cinFileUrl.length - 1].response.url
            } else if (cinFileUrl[cinFileUrl.length - 1].url) {
                window.location.href = cinFileUrl[cinFileUrl.length - 1].url
            }
        }
    }

    render() {
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props
        const { dispatch } = this.props

        const columns = [
            {
                title: 'Package No.',
                key: 'packageNo',
                //  fixed: 'left',
                width: 150,
                render: (val, record, key) => {
                    return <div>Package {key + 1}</div>
                }
            },
            {
                title: 'Length',
                dataIndex: 'length',
                key: 'length',
                render: (val, record) => {
                    return `${record && record.length}${record && record.dimentionUnit}`
                }
            },
            {
                title: 'Width',
                dataIndex: 'width',
                key: 'width',
                render: (val, record) => {
                    return `${record && record.width}${record && record.dimentionUnit}`
                }

            },
            {
                title: 'Height',
                key: 'height',
                dataIndex: 'height',
                render: (val, record) => {
                    return `${record && record.height}${record && record.dimentionUnit}`
                }

            },
            {
                title: 'Quantity',
                key: 'quantity',
                dataIndex: 'quantity',
                render: (val, record) => {
                    return `${record && record.quantity}${record && record.dimentionUnit}`
                }

            },
            {
                title: 'Weight',
                key: 'weight',
                render: (val, record) => {
                    return `${record && record.weight}${record && record.unit}`
                }

            },
            {
                title: 'Shipment Label',
                key: 'shipmentLabel',
                width: 180,
                render: (text, record, key) => {
                    let doc = ''
                    let fileName = ''
                    switch (serviceProviderName) {
                        case 'DHL':
                            doc = 'data:application/pdf;base64'
                            fileName = 'label.pdf'
                            break;
                        case 'UPS Saver':
                            doc = 'data:image/png;base64'
                            fileName = 'label.png'
                            break;
                        case 'Fedex':
                            doc = 'data:application/pdf;base64'
                            fileName = 'label.pdf'
                            break;
                        case 'Aramex':
                            doc = 'data:application/pdf;base64'
                            fileName = 'label.pdf'
                            break;
                    }
                    if (serviceProviderName === 'Aramex') {
                        return record && record.shipmentLabel && <Tooltip title={'Download Label'}>
                            {console.log(API_URL)}
                            {
                                <Button onClick={async () => {
                                    this.setLoader(key, true)

                                    let data = await Request.downLoadLabelForAramex({ label: record && record.trackingNumber })
                                    this.setLoader(key, false)

                                    if (data) {
                                        window.open(data.label, "_blank");

                                    }
                                }
                                } className={styles.comparenow}
                                    icon={'download'}>
                                    Download Label </Button>
                            }
                        </Tooltip>
                    }
                    else if (serviceProviderName === 'DHL') {
                        return record && record.shipmentLabel && <Tooltip title={'Download Label'}>
                            {
                                <a href={`${doc},${record.shipmentLabel}`} download={fileName}>
                                    <Button className={styles.comparenow}
                                        icon={'download'}>
                                        Download Label </Button> </a>
                            }
                        </Tooltip>
                    }
                    else if (serviceProviderName === 'Fedex') {
                        return record && record.shipmentLabel && <Tooltip title={'Download Label'}>
                            <Button loading={this.state.start[key]} className={styles.comparenow} onClick={async () => {
                                this.setLoader(key, true)

                                let data = await Request.downLoadZipForFedex({ demo: record && record.shipmentLabel })
                                this.setLoader(key, false)

                                if (data) {
                                    window.open(data.file, "_blank");

                                }

                            }
                            } icon={'download'}>
                                Download Label </Button>
                        </Tooltip>
                    }

                    else {
                        //    console.log("UPS")
                        return <div>{record && record.shipmentLabel && <Tooltip title={'Download Label'}>


                            <Button loading={this.state.start[key]} className={styles.comparenow} onClick={async () => {
                                this.setLoader(key, true)

                                let data = await Request.downLoadZip({ demo: record && record.shipmentLabel })
                                this.setLoader(key, false)

                                if (data) {
                                    window.open(data.file, "_blank");

                                }

                            }
                            } icon={'download'}>
                                Download Label </Button>

                        </Tooltip>}</div>

                    }


                }
            }


        ]

        const trackingColumns = [
            {
                title: 'Status',
                key: 'status',
                //  fixed: 'left',
                width: 150,
                render: (val, rec) => {
                    return <Tag color={rec && rec.status == 'Approved' ? "#87d068" : "#f50"}>{rec && rec.status} </Tag>
                }

            },
            {
                title: 'Tracking Number',
                dataIndex: 'trackingNumber',
                key: 'trackingNumber',
            },
            {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',

            },
            {
                title: 'Delivery Attempts',
                key: 'deliveryAttempts',
                render: (val, rec) => {
                    return <Tag>{rec && rec.deliveryAttempts} </Tag>
                }

            }


        ]
        const pickupColumns = [
            {
                title: 'S. NO.',
                key: 'sno',
                width: 100,
                render: (val, record, key) => key + 1

            },
            {
                title: 'Pick Up Number',
                dataIndex: 'prn',
                key: 'prn',
            },
            {
                title: 'Pick Date',
                dataIndex: 'pickupDate',
                key: 'pickupDate',
                render: (val) => moment(val).format('DD-MMM-YYYY')
            },
            {
                title: 'From Time',
                dataIndex: 'openTime',
                key: 'openTime',

            },
            {
                title: 'To',
                dataIndex: 'closeTime',
                key: 'closeTime',

            },
            {
                title: 'Message',
                dataIndex: 'message',
                key: 'message'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (val, rec) => {
                    return <Tag color={val == 'Created' ? "#87d068" : "#f50"}>{val} </Tag>
                }

            }
        ]

        // const {
        // //     quoteDetails, trackingResp, packages, pickUpResp, receiverData, packagesDetails, showTrackingModal, showModal, shipmentType, data,
        // //     senderData, shipmentSanitizeResponse, paymentResponse, pickupInformation,
        // //     insuranceDetails, serviceProviderName
        // // } = this.state

        if (serviceProviderName && serviceProviderName === 'UPS Saver') {
            let dataItem = {
                title: 'Ins. Value',
                key: 'insuranceDeclaredValue',
                dataIndex: 'insuranceDeclaredValue',
                render: (val, record) => {
                    return `${val} MAD`
                }

            }

            columns.splice(6, 0, dataItem)

        }


        const {
            quoteDetails, trackingResp, packages, pickUpResp, quoteFullDetails, receiverData, packagesDetails, showTrackingModal, showModal, shipmentType, data,
            senderData, shipmentSanitizeResponse, paymentResponse, pickupInformation,
            insuranceDetails, serviceProviderName, quoteErrors, cinNumber, cinFileUrl, affiliatedUserCommissionPercentage, affiliatedUser, affiliatedUserCommission
        } = this.state
        return (<PageHeaderWrapper title={'Order Details'}>
            <Card bordered={false}>
                {quoteDetails && <div>
                    <Card bordered={false}>

                        <Row>
                            <Col span={24}>
                                <Card className={styles.formRow}>
                                    <Row>
                                        <Col span={8}>
                                            {quoteDetails && quoteDetails.serviceProviderName &&
                                                <ProviderIcon type={quoteDetails.serviceProviderName} />}
                                        </Col>
                                        <Col span={16}>
                                            {quoteDetails && quoteDetails.quoteStatus != 'Canceled' && <>
                                                <Button style={{ marginLeft: '5px' }} onClick={() => {
                                                    this.handleShowModal(quoteDetails)
                                                }}>Reschedule Pickup</Button>
                                                <Button loading={this.state.trackButton} style={{ marginLeft: '5px' }}
                                                    onClick={() => {
                                                        // this.setState({})
                                                        this.handleTrackingModal(quoteDetails)
                                                    }}>
                                                    Tracking
                                                </Button>

                                                <Popconfirm
                                                    title="Are you sure Cancel Shipment"
                                                    onConfirm={() => this.voidShipment(quoteDetails._id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button loading={this.state.voidButton} style={{ marginLeft: '5px' }}>Cancel
                                                        Shipment</Button>
                                                </Popconfirm>

                                                {quoteDetails && quoteDetails.shipmentObj && quoteDetails.shipmentObj.shipmentReason !== 'documents' &&

                                                    <Button loading={this.state.downloadCommercial}
                                                        style={{ marginLeft: '5px' }}
                                                        onClick={async () => {
                                                            this.setState({ downloadCommercial: true })
                                                            let data = await Request.downLoadCommercial({ _id: this.state.quoteId })
                                                            this.setState({ downloadCommercial: false })


                                                        }}>Download Commercial
                                                </Button>}
                                            </>}
                                        </Col>
                                    </Row>
                                </Card>
                                {/* <div>
                                    {quoteErrors && <ul className={styles.errorList}>
                                        {_.map(quoteErrors, data => {
                                            return (<li><Alert message={`${data.type} - ${data.msg}`} type="error" showIcon />
                                            </li>)
                                        })}
                                    </ul>}


                                </div> */}
                                <Card className={styles.formRow}>
                                    <Row>
                                        <Col span={6}>
                                            <b>Order Id</b> : {quoteDetails && quoteDetails.orderId}
                                        </Col>
                                        <Col span={6} style={{ textAlign: 'left' }}>
                                            <b>Total Amount</b> : {quoteDetails && quoteDetails.netAmount} MAD
                                        </Col>
                                        <Col span={6}>
                                            <b>Package Type</b> : {quoteDetails && quoteDetails.packageType}

                                        </Col>
                                        <Col span={6}>
                                            <b>Type</b> : {quoteDetails && quoteDetails.type}

                                        </Col>
                                    </Row>
                                </Card>


                                <div className={styles.formRowmew}>

                                    <div className={styles.card}>
                                        <Descriptions title="Sender Details" column={1} size={'small'}>
                                            <Descriptions.Item
                                                label={<b>Full
                                                    Name</b>}>{senderData && senderData.fullName}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Email</b>}>{senderData && senderData.email}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Phone</b>}>{senderData && senderData.phone}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={<b>Phone
                                                    Code</b>}>{senderData && senderData.phoneCode}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Address</b>}> {senderData && senderData.address}</Descriptions.Item>
                                            {senderData && senderData.addressLine && senderData.addressLine.length > 0 &&
                                                <Descriptions.Item label={
                                                    <b>Address Line</b>}>{_.map(senderData.addressLine, (i, key) => {
                                                        if (senderData.addressLine.length - 1 != key) {
                                                            return `${i},`

                                                        } else {
                                                            return i
                                                        }

                                                    })}
                                                </Descriptions.Item>}
                                        </Descriptions>
                                    </div>
                                    <div className={styles.card}>
                                        <Descriptions title="Receiver Details" column={1} size={'small'}>
                                            <Descriptions.Item
                                                label={<b>Full
                                                    Name</b>}>{receiverData && receiverData.fullName}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Email</b>}>{receiverData && receiverData.email}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Phone</b>}>{receiverData && receiverData.phone}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={<b>Phone
                                                    Code</b>}>{receiverData && receiverData.phoneCode}</Descriptions.Item>
                                            <Descriptions.Item
                                                label={
                                                    <b>Address</b>}> {receiverData && receiverData.address}</Descriptions.Item>
                                            {receiverData && receiverData.addressLine && receiverData.addressLine.length > 0 &&
                                                <Descriptions.Item label={
                                                    <b>Address Line</b>}>{_.map(receiverData.addressLine, (i, key) => {
                                                        if (receiverData.addressLine.length - 1 != key) {
                                                            return `${i},`

                                                        } else {
                                                            return i
                                                        }

                                                    })}
                                                </Descriptions.Item>}
                                        </Descriptions>
                                    </div>

                                </div>


                                <div className={styles.formRowmew}>

                                    <div className={styles.card}>
                                        <Descriptions title="Shipment (Response)" column={1} size={'small'}>
                                            {shipmentSanitizeResponse && <Descriptions.Item
                                                label={
                                                    <b>Status</b>}><Tag style={{ marginRight: '10px' }}
                                                        color={shipmentSanitizeResponse && shipmentSanitizeResponse.status && shipmentSanitizeResponse.status.toLowerCase() === 'success' ? "#87d068" : "#FF0000"}>{shipmentSanitizeResponse && shipmentSanitizeResponse.status}</Tag>
                                            </Descriptions.Item>}
                                            {shipmentSanitizeResponse && shipmentSanitizeResponse.status && shipmentSanitizeResponse.status.toLowerCase() === 'success' &&
                                                <Descriptions.Item
                                                    label={<b>Billing
                                                    Weight</b>}>{quoteDetails && quoteDetails.totalWeight} ({quoteDetails && quoteDetails.unitOfWeight})</Descriptions.Item>}

                                            {pickUpResp && pickUpResp[0] && pickUpResp[0].prn && <Descriptions.Item
                                                label={<b>Pickup Ref No.</b>}>{pickUpResp[0].prn}</Descriptions.Item>}
                                        </Descriptions></div>

                                    <div className={styles.card}>
                                        <Descriptions title="Payment (Response)" column={1} size={'small'}>

                                            {paymentResponse && paymentResponse.TransId && <Descriptions.Item
                                                label={<b>Payment Id</b>}>{paymentResponse.TransId}</Descriptions.Item>}

                                            {paymentResponse && paymentResponse.Response && <Descriptions.Item
                                                label={<b>Status</b>}>{paymentResponse.Response}</Descriptions.Item>}

                                            {paymentResponse && paymentResponse.Response && paymentResponse.Response != 'Approved' &&
                                                <Descriptions.Item
                                                    label={<b>ErrMsg</b>}>{paymentResponse.ErrMsg}</Descriptions.Item>}
                                            {paymentResponse && paymentResponse.paymentType &&
                                                <Descriptions.Item
                                                    label={<b>Mode</b>}>{paymentResponse.paymentType}</Descriptions.Item>}


                                            {paymentResponse &&
                                                <Descriptions.Item
                                                    label={
                                                        <b>Amount (Discount)</b>}>{paymentResponse.amount && `${paymentResponse.amount} MAD (${quoteDetails.couponAmount ? quoteDetails.couponAmount : 0})`}</Descriptions.Item>}

                                        </Descriptions>

                                    </div>

                                </div>


                                {serviceProviderName != 'UPS Saver' ?
                                    insuranceDetails ?
                                        insuranceDetails.insureAmount ?
                                            <Card style={{ marginBottom: '10px' }}>
                                                <Descriptions column={3} title={'Insurance Details'} size={'small'}>
                                                    <Descriptions.Item
                                                        label={<b>Declared
                                                Value</b>}>{insuranceDetails && insuranceDetails.declaredValue}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label={
                                                            <b>Currency</b>}>{insuranceDetails && insuranceDetails.currency}</Descriptions.Item>
                                                    <Descriptions.Item
                                                        label={
                                                            <b>Amount</b>}>{insuranceDetails && insuranceDetails.insureAmount}</Descriptions.Item>
                                                </Descriptions>
                                            </Card> : ''
                                        : ''
                                    : ''}

                                <div className={styles.formRowmew}>

                                    <div className={styles.card}>
                                        <Descriptions title="Cin Number" column={1} size={'small'}>
                                            </Descriptions>
                                            <div style={{ display: 'flex' }}>
                                                <div>
                                                    <h4>
                                                        {cinNumber}
                                                    </h4>
                                                </div>
                                                {cinFileUrl && cinFileUrl.length ? <Button style={{ marginLeft: '5px', height: '24px' }} onClick={() => {
                                                    this.cinFileFunc()
                                                }}>View File</Button> : ''}
                                            </div>
                                    </div>

                                    {affiliatedUser && <div className={styles.card}>
                                        <Descriptions title="Affiliated User Commission" column={1} size={'small'}>

                                            {affiliatedUser && affiliatedUser.fullName && <Descriptions.Item
                                                label={<b>Full Name</b>}>{affiliatedUser.fullName}</Descriptions.Item>}

                                            {affiliatedUserCommission && <Descriptions.Item
                                                label={<b>Affiliated User Commission (%)</b>}>{affiliatedUserCommission} MAD ({affiliatedUserCommissionPercentage})%</Descriptions.Item>}

                                        </Descriptions>

                                    </div>}

                                </div>


                                <Card className={styles.packageCard}>
                                    <h3>Package Details</h3>
                                    <Table rowKey={x => x._id}
                                        size={'middle'} bordered className={'table'} dataSource={packages}
                                        columns={columns}
                                        pagination={false}

                                    />
                                </Card>

                                {trackingResp && <Card className={styles.packageCard}>
                                    <h3>Tracking Details</h3>
                                    <Table rowKey={x => x._id}
                                        size={'middle'} bordered className={'table'} dataSource={trackingResp || []}
                                        columns={trackingColumns}
                                        pagination={false}

                                    />
                                </Card>}
                                {pickUpResp && pickUpResp.length > 0 && (
                                    <><h3 style={{ marginTop: '10px' }}>Pickup Details</h3>
                                        <Table
                                            bordered
                                            size='small'
                                            columns={pickupColumns}
                                            dataSource={pickUpResp}
                                            pagination={false}
                                        />
                                    </>)}


                            </Col>
                        </Row>
                    </Card>
                    <ScheduleForm handleCancel={this.handleCancel.bind(this)} showModal={showModal}
                        quoteDetails={quoteFullDetails}
                        quoteId={this.state.quoteId} />
                    <Modal
                        title="Shipment Tracking"
                        width={500}
                        footer={null}
                        onCancel={this.onTrackingClose}
                        visible={showTrackingModal}>
                        {shipmentType === 'UPS Saver' &&
                            <UpsTrackingDetails details={packagesDetails} data={data} buttonFlag={false} />}
                        {shipmentType === 'Aramex' &&
                            <AramexTrackingDetails details={packagesDetails} data={data} buttonFlag={false} />}
                        {shipmentType === 'DHL' &&
                            <DhlTrackingDetails details={packagesDetails} data={data} buttonFlag={false} />}
                    </Modal>
                </div>}

            </Card>
        </PageHeaderWrapper>)
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(orderDetails)
